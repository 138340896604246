<template>
<div>
<v-dialog
      v-model="dialogchangepassword"
      max-width="490"
      persistent
      >
        <v-card>
          <v-card-title class="primary white--text"
            >Rubah Password</v-card-title
          >
          <v-card-text>
            <v-form
              enctype="multipart/form-data"
              ref="form_changepassword"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="form.old_password"
                    :type="form.old_password_show ? 'text' : 'password'"
                    required
                    :rules="form.old_password_rules"
                    label="Password Lama *"
                    placeholder="Password Lama"
                    id="old_password"
                    @keyup.enter="Loading"
                    :append-icon="form.old_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="form.old_password_show = !form.old_password_show"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  :class="$vuetify.breakpoint.xs ? 'mt-n3' : 'mt-n5'"
                >
                  <v-text-field
                    v-model="form.new_password"
                    :type="form.new_password_show ? 'text' : 'password'"
                    required
                    :rules="form.new_password_rules"
                    label="Password Baru *"
                    placeholder="Password Baru"
                    id="new_password"
                    @keyup.enter="Loading"
                    :append-icon="form.new_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="form.new_password_show = !form.new_password_show"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  :class="$vuetify.breakpoint.xs ? 'mt-n3' : 'mt-n5'"
                >
                  <v-text-field
                    v-model="form.confirm_password"
                    :type="form.confirm_password_show ? 'text' : 'password'"
                    required
                    :rules="form.confirm_password_rules"
                    label="Password Baru Konfirmasi *"
                    placeholder="Password Baru Konfirmasi"
                    id="confirm_password"
                    @keyup.enter="Loading"
                    :append-icon="form.confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="form.confirm_password_show = !form.confirm_password_show"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="dialog"
              color="primary"
              :disabled="form.confirm === 'Ok' ? false : true"
              @click="Loading"
              >{{ form.confirm }}</v-btn
            >
            <v-btn @click="dialogchangepassword = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
  </div>
  </template>
  <script>
  export default {
    props: {
    dialog: Boolean,
    usersid: String
  },
  data: () => ({
	form: {
        dialog: false,
        old_password: '',
        new_password: '',
        confirm_password: '',
        old_password_show: false,
        new_password_show: false,
        confirm_password_show: false,
        old_password_rules: [(v) => !!v || "Mohon isi Password Lama"],
        new_password_rules: [(v) => !!v || "Mohon isi Password Baru"],
        confirm_password_rules: [(v) => !!v || "Mohon isi Password Baru Konfirmasi"],
        confirm: 'Ok'
    },
    snackbar: {
        dialog: false,
        color: "primary",
        text: '',
        timeout: 3000,
    },
  }),
  created() {
    setTimeout(function(){
        document.getElementById('old_password').focus()
    }, 500)
  },
  computed: {
    dialogchangepassword: {
        get() {
            return this.dialog
        },
        set(value) {
            if (!value) {
                this.$emit('close')
            }
        }
    }
  },
  methods: {
    Loading() {
      this.form.confirm = "Loading...";
      if (this.$refs.form_changepassword.validate()) {
        this.ChangePassword();
      } else {
        this.form.confirm = "Ok";
      }
    },
    // Change Password User
    ChangePassword() {
      let formdata = {
        url_type: "changepassword",
        old_password: this.form.old_password,
        new_password: this.form.new_password,
        confirm_password: this.form.confirm_password,
        users_id: this.usersid,
        flag: 'mobileapp'
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsers"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.dialogchangepassword = false;
              this.form.confirm = "Ok";
              this.form.old_password = "";
              this.form.new_password = "";
              this.form.confirm_password = "";
              this.SnackBar(true, "primary", "Password berhasil dirubah", 0);
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
              this.dialogchangepassword = true;
              this.form.confirm = "Ok";
            }
          } else {
            this.SnackBar(true, "error", "Password gagal dirubah", 0);
            this.dialogchangepassword = true;
            this.form.confirm = "Ok";
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.dialogchangepassword = true;
          this.form.confirm = "Ok";
        });
    },
    SnackBar(dialog, color, text, timeout) {
        this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout,
        }
        this.form.confirm 		= "Ok"
    },
  }
}
</script>