<template>
    <v-app-bar
    color="primary"
    dark
    flat
    fixed
    >
        <v-icon @click="Back">home</v-icon>
        <v-spacer></v-spacer>
        {{app_title}}
        <v-spacer></v-spacer>
        <v-avatar size="50">
            <img :src="app_avatar" :alt="app_avatar">
        </v-avatar>
    </v-app-bar>
</template>
<script>
    export default {
        props: ['app_title','app_back','app_avatar'],
        methods: {
            GoTo (url) {
                this.$router.push({name: url})
            },
            Back () {
                this.$router.push({name: 'home'})
            }
        }
    }
</script>