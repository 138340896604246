<template>
    <div>

    <v-bottom-navigation
        fixed
        color="primary"
        class="elevation-0"
        >
        <v-btn text :to="row.url" v-for="row in menu" :key="row.icon" class="mt-2">
            <template v-if="row.icon !== 'person'">
                <span>{{row.text}}</span>
                <v-icon>{{row.icon}}</v-icon>
            </template>
            <template v-else>
                <v-avatar
                      size="35"
                      color="grey lighten-4"
                    >
                      <img :src="$functions.UsersAvatar()" :alt="$functions.UsersID()">
                    </v-avatar>
            </template>
        </v-btn>
    </v-bottom-navigation>
    </div>
</template>
<script>
    export default {
        data: () => ({
            countnotification: 0,
            activebtn: 0,
            login: false,
            menu: [
                {icon: 'home', text: 'Home', url: '/'},
                {icon: 'info', text: 'Tentang Kami', url: '/aboutus'},
                {icon: 'work_history', text: 'Aktifitas', url: '/activity'},
                {icon: 'person', text: 'Akun', url: '/account'},
            ],
        }),
        created () {
            this.CheckLogin()
            this.Count()
        },
        methods: {
            CheckLogin () {
                let usersid = this.$functions.UsersID()
                this.login = true
                if (usersid === null) {
                    this.login = false
                }
            },
            Count () {
            	this.countnotification = 2
                // this.loading = true
                // let formData = new FormData()
                // formData.append('choose', 'count')
                // this.$axios.post(this.$functions.SafeURL('apiNotification', 'order_by=notification_cdate&order_type=DESC'),formData, {
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // }
                // })
                // .then(response => {
                //     this.countnotification = response.data[0].countnotif
                //     this.loading = false
                // })
                // .catch(e => {
                //     alert(e)
                //     this.loading = false
                // })
            },
        }
    }
</script>