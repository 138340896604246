<template>
    <div class="dashboard">
        <app-toolbardefault :app_title="$functions.AppTitle()"></app-toolbardefault>
        <!-- Greeting Header-->
        <v-card color="primary" class="mt-10" flat>
            <v-container
                fluid
                dark
            >
                <v-layout row wrap>
                <v-flex xs12>
                    <v-card tile color="primary" class="white--text">
                    <v-card-title primary-title>
                        <div>
                        <div class="title">Hi, {{form.users_name}}</div>
                        </div>
                    </v-card-title>
                    </v-card>
                </v-flex>
                </v-layout>
            </v-container>
        </v-card>
        <template>
        <v-layout class="overflow-visible" style="height: 56px;">
            <v-bottom-navigation
            horizontal
            color="primary"
            class="elevation-0"
            >
            <v-btn text @click="GoToURL(row.url)" v-for="row in menu" :key="row.icon">
                <span v-if="row.pending && notification.count > 0">
                <v-badge
                    color="red"
                    bottom
                    overlap
                >
                    <template v-slot:badge>
                        <span>{{notification.count}}</span>
                    </template>
                    <v-icon>{{row.icon}}</v-icon>
                </v-badge>
                {{ row.text }}
                </span>
                <span v-else>
                    <v-icon>{{row.icon}}</v-icon>
                    {{ row.text }}
                </span>
            </v-btn>
            </v-bottom-navigation>
        </v-layout>
        </template>

        <template v-if="detail.absence.length > 0">
            <v-card flat>
                <v-list two-line>
                    <v-subheader class="font-weight-bold primary white--text"><v-icon color="white">fingerprint</v-icon> Absen Hari Ini</v-subheader>
                    <template v-for="(item, index) in detail.absence">
                        <v-list-item
                        :key="item.absence_usersid"
                        @click="OpenAbsence(item.absence_usersid)"
                        >
                        <v-list-item-avatar>
                            <v-img :src="item.absence_usersavatarurl"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{item.absence_usersname}}</v-list-item-title>
                            <v-list-item-subtitle>IN: {{item.absence_timein}} OUT:{{item.absence_timeout}}</v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider
                        v-if="index + 1 < detail.absence.length"
                        :key="index"
                    ></v-divider>
                </template>
                </v-list>
            </v-card>
        </template>
        <template v-if="detail.todo.length > 0">
            <v-card flat>
                <v-list three-line>
                    <v-subheader class="font-weight-bold secondary white--text"><v-icon color="white">event</v-icon> Todo Hari Ini</v-subheader>
                    <template v-for="(item, index) in detail.todo">
                        <v-list-item
                        :key="item.todo_id"
                        >
                        <v-list-item-avatar>
                            <v-img :src="item.todo_users_avaurl"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{item.todo_users_name}}</v-list-item-title>
                            <v-list-item-subtitle>{{item.todo_desc}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="caption font-weight-bold">
                            {{item.todo_time}}
                        </v-list-item-action>
                        </v-list-item>
                        <v-divider
                        v-if="index + 1 < detail.todo.length"
                        :key="index"
                    ></v-divider>
                </template>
                </v-list>
            </v-card>
        </template>
        <template v-if="detail.leave.length > 0">
            <v-card flat>
                <v-list three-line>
                    <v-subheader class="font-weight-bold primary white--text"><v-icon color="white">work_history</v-icon> Cuti/Ijin Hari Ini</v-subheader>
                    <template v-for="(item, index) in detail.leave">
                        <v-list-item
                        :key="item.leave_id"
                        >
                        <v-list-item-avatar>
                            <v-img :src="item.leave_usersavatarurl"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{item.leave_usersname}}</v-list-item-title>
                            <v-list-item-subtitle>{{$functions.FormatDateIndoWithDayName(item.leave_sdate)}} s/d {{$functions.FormatDateIndoWithDayName(item.leave_edate)}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{item.leave_categorydesc}}</v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider
                        v-if="index + 1 < detail.leave.length"
                        :key="index"
                    ></v-divider>
                </template>
                </v-list>
            </v-card>
        </template>
        <template v-if="detail.birthday.length > 0">
            <v-card flat>
                <v-list three-line>
                    <v-subheader class="font-weight-bold primary white--text"><v-icon color="white">cake</v-icon> Ulang Tahun</v-subheader>
                    <template v-for="(item, index) in detail.birthday">
                        <v-list-item
                        :key="item.users_id"
                        >
                        <v-list-item-avatar>
                            <v-img :src="item.users_avatarurl"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{item.users_name}}</v-list-item-title>
                            <v-list-item-subtitle>{{$functions.FormatDateIndo(item.users_birthdate)}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{item.users_birthdayflag}}</v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider
                        v-if="index + 1 < detail.birthday.length"
                        :key="index"
                    ></v-divider>
                </template>
                </v-list>
            </v-card>
        </template>
        <div class="mt-5">&nbsp;</div>
        <v-dialog v-model="absence.dialog" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text"><v-avatar class="mr-2"><v-img :src="form.absence_usersavatarurl"></v-img></v-avatar> {{form.absence_usersname}}</v-card-title>
                <v-card-text>
                    <div class="text-center">
                        <div class="font-weight-bold green--text">IN: {{form.absence_timein}} <span v-if="form.absence_timelate !== '00:00'">&bull; TELAT: {{$functions.FormatTimeToWords(form.absence_timelate)}}</span></div>
                        <div><img :src="form.absence_timeinphoto" height="210px"></div>
                        <div><iframe :src="form.absence_timein_url" width="100%" height="175" frameborder="0"></iframe></div>
                    </div>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <div v-if="form.absence_timeout !== '00:00'" class="text-center">
                        <div class="font-weight-bold red--text">OUT: {{form.absence_timeout}} <span v-if="form.absence_timeearly !== '00:00'">&bull; LEBIH CEPAT: {{$functions.FormatTimeToWords(form.absence_timeearly)}}</span></div>
                        <div><img :src="form.absence_timeoutphoto" height="210px"></div>
                        <div><iframe :src="form.absence_timeout_url" width="100%" height="175" frameborder="0"></iframe></div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    {{$functions.FormatDateIndoWithDayName(form.absence_date)}}
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="absence.dialog = false">{{confirm.close}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="confirm.dialog" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="WriteTodo('event')">{{confirm.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
        <app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
    </div>
</template>
<script>
  export default {
    data: () => ({
        form: {
            users_id: '',
            users_name: '',
            absence_usersname: '',
            absence_usersavatarurl: '',
            absence_date: '',
            absence_timein: '',
            absence_timeout: '',
            absence_latitude_in: '',
            absence_longitude_in: '',
            absence_geolocation_in: '',
            absence_latitude_out: '',
            absence_longitude_out: '',
            absence_geolocation_out: '',
            absence_timein_url: '',
            absence_timeout_url: '',
            absence_timeinphoto: '',
            absence_timeoutphoto: '',
            absence_timelate: '',
            absence_timeearly: '',
        },
        detail: {
            absence: [],
            todo: [],
            leave: [],
            birthday: [],
        },
        menu: [
            {icon: 'mdi-badge-account-outline', text: 'Kepegawaian', url: 'employee', pending: true},
            {icon: 'mdi-calendar', text: 'Todo', url: 'todo'},
            {icon: 'mdi-fingerprint', text: 'Absen', url: 'account'},
        ],
        confirm: {
            dialog: false,
            title: '',
            text: '',
            ok: 'OK',
            close: 'Tutup',
        },
        absence: {
            dialog: false,
            title: '',
            text: '',
        },
        notification: {
            count: 0
        },
        snackbar: {
            dialog: false,
            color: "primary",
            text: "",
            timeout: 3000,
        },
    }),
    created () {
        this.Initial()
    },
    methods: {
        Initial() {
           this.Refresh()
        },
        GoToURL (flag) {
           this.$router.push({name: flag})
        },
        Absence (id) {
            let formdata = {
                absence_date: this.$functions.Today(),
                absence_leader: id,
                absence_flag: 'leader',
                order_by: 'absence_usersid',
                order_type: 'ASC',
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.detail.absence =  feedback
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        Todo (id) {
            let formdata = {
                todo_date: this.$functions.Today(),
                todo_leader: id,
                todo_flag: 'leader',
                order_by: 'todo_id',
                order_type: 'DESC',
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListTodo'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.detail.todo =  feedback
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        Leave () {
            let formdata = {
                leave_userscompany: this.$functions.UsersCompany(),
                order_by: 'leave_sdate',
                order_type: 'ASC',
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListLeaveUsers'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.detail.leave =  feedback
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        BirthDay () {
            let formdata = {
                users_company: this.$functions.UsersCompany(),
                flag: 'mobileapp'
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListUsersBirthDay'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.detail.birthday =  feedback
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        Pending () {
            let formdata   = {
                flag: 'mobileapp'
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiCountPendingEmployee'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.notification.count = feedback[0].pending_count
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        Refresh () {
			let formdata = {
				users_id: this.$functions.UsersID(),
				flag: 'mobileapp'
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsers'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
						localStorage.removeItem("local_tim_usersdetail")
						localStorage.setItem('local_tim_usersdetail', JSON.stringify(feedback))
						let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
                        this.form.users_id = usersdetail[0].users_id
                        this.form.users_name = usersdetail[0].users_name
                        this.Absence(this.form.users_id)
                        this.Todo(this.form.users_id)
                        this.Pending()
                        this.Leave()
                        this.BirthDay()
					} else {
						this.SnackBar(true,'error',feedback[0].feedback,3000)
					}
				}
			})
			.catch(e => {
				this.SnackBar(true,'error',e,3000)
			})
		},
        OpenAbsence(id) {
            this.absence.dialog = true
            let formdata = {
                absence_date: this.$functions.Today(),
                absence_usersid: id,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.form.absence_usersname = feedback[0].absence_usersname
                       this.form.absence_usersavatarurl = feedback[0].absence_usersavatarurl
                       this.form.absence_date = feedback[0].absence_date
                       this.form.absence_timein = feedback[0].absence_timein
                       this.form.absence_timeout = feedback[0].absence_timeout
                       this.form.absence_latitude_in = feedback[0].absence_latitude_in
                       this.form.absence_longitude_in = feedback[0].absence_longitude_in
                       this.form.absence_geolocation_in = feedback[0].absence_geolocation_in
                       this.form.absence_latitude_out = feedback[0].absence_latitude_out
                       this.form.absence_longitude_out = feedback[0].absence_longitude_out
                       this.form.absence_geolocation_out = feedback[0].absence_geolocation_out
                       this.form.absence_timeinphoto = feedback[0].absence_timeinphoto
                       this.form.absence_timeoutphoto = feedback[0].absence_timeoutphoto
                       this.form.absence_timelate = feedback[0].absence_timelate
                       this.form.absence_timeearly = feedback[0].absence_timeearly
                       this.form.absence_timein_url = 'https://maps.google.com/maps?q='+feedback[0].absence_latitude_in+','+feedback[0].absence_longitude_in+'&t=&z=19&ie=UTF8&iwloc=&output=embed'
                       this.form.absence_timeout_url = 'https://maps.google.com/maps?q='+feedback[0].absence_latitude_out+','+feedback[0].absence_longitude_out+'&t=&z=19&ie=UTF8&iwloc=&output=embed'
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        SnackBar(dialog, color, text, timeout) {
            this.snackbar = {
                dialog: dialog,
                color: color,
                text: text,
                timeout: timeout,
            }
            this.confirm.dialog 	= false
            this.confirm.ok 		="Ok"
        },
    }
  }
</script>