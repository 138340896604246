import * as api from '../configs/config.json'
import * as packages from '../../package.json'
export default {
    // Version
    AppVersion () {
        const appversion = packages.version
        return appversion
    },
    AppTitle () {
        const apptitle = api.app_title
        return apptitle
    },
    //UsersID get local storage users id
    UsersID () {
        let usersid =  localStorage.getItem('local_tim_users')
        return usersid
    },
    UsersType () {
        let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
        let userstype   = usersdetail[0].users_type
        return userstype
    },
    UsersCompany () {
        let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
        let userstype   = usersdetail[0].users_company
        return userstype
    },
    UsersAvatar () {
        let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
        let userstype   = usersdetail[0].users_avatar_url
        return userstype
    },
    //SafeURL curl json api
    SafeURL (url, params) {
        const hosts = api.api_connection
        const keys = api.api_key
        const secret = api.api_secret
        let loginuser = this.UsersID()
        let newurl
        if (params !== '') {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&${params}&login_user=${loginuser}`
        } else {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&login_user=${loginuser}`
        }
            return newurl
    },
    TrueOrFalse (flag) {
        let returns = false
        if (flag === 'Y') {
            returns = true
        } else if (flag === 'F'){
            returns = true
        }
        return returns
    },
    Access (flag) {
        let returns = false
        if (flag === 1) {
            returns = true
        } else if (flag == true){
            returns = true
        }
        return returns
    },
    Method (flag) {
        let returns = false
        if (flag === 'POST') {
            returns = true
        }
        return returns
    },
    Gender(S) {
        let returns = true
        if (S === 'F') {
            returns = false
        }
        return returns
    },
     //FormatDateTime format date MM/DD/YYYY H:i:s
     FormatDateTimeSession (dates) {
        let today = new Date(dates)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        let hh = today.getHours()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        if (hh < 10) {
            hh = '0' + hh
        }
        today = dd + '-' + mm + '-' + yyyy
        return today
    },
    //Format New Date
    FormatNewDate (dates) {
        let today = new Date(dates)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    Level(L) {
        let returns = 'High'
        if (L === 'L') {
            returns === 'Low'
        }
        return returns
    },
    UrlPOST (url) {
        const hosts  = api.api_connection
        let newurl   = `${hosts}/${url}`
        return newurl
    },
    ParamPOST (param) {
        let key         = api.api_key
        let secret      = api.api_secret
        let login_user  = this.UsersID()
        var combine      = JSON.stringify({ key: key, secret: secret, login_user: login_user, "data": param })
        return combine
    },
    //FormatDateFull format date MM/DD/YYYY H:i:s
    FormatDateFull (date) {
        if (!date) return null
        const  dates             = date.split('T')
        const [year, month, day] = dates[0].split('-')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${newmonth} ${day} ${year}`
    },
    //ShortMonthNameEng MonthNameEnglish
    ShortMonthNameEng (months) {
        let monthsindo = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //ShortMonthNameEng MonthNameEnglish
    ShortMonthNameIndo (months) {
        let monthsindo = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //Today get the day now
    Today () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    DayNameIndo (date) {
        var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
        var d = new Date(date)
        var dayname = days[d.getDay()]
        return dayname
    },
    //FormatDateFull format date MM/DD/YYYY
    FormatDateIndoWithDayName (date) {
        if (!date) return null
        const dates              = date
        const [year, month, day] = dates.split('-')
        const newmonth           = this.ShortMonthNameIndo(month)
        var dayname              = this.DayNameIndo(date)
        return `${dayname}, ${newmonth} ${day} ${year}`
    },
    MinuteDiff(time1, time2) {
        let diff     = new Date("01/01/2007 " + time2) - new Date("01/01/2007 " + time1)
        let diffHrs  = Math.floor((diff % 86400000) / 3600000); // hours
        let diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes
        let returns  = diffHrs + ' Jam'
        if (diffMins > 0) {
            returns  += ' ' + diffMins + ' Menit'
        }
        return returns;
    },
    //FormatDateTime format date MM/DD/YYYY H:i:s
    FormatDateTime (date) {
        if (date === '1900-01-01T00:00:00Z') {
            return  null
        } else {
        if (!date) return null
        const [dates, times]     = date.split('T')
        const [year, month, day] = dates.split('-')
        const newtime            = times.split('.')
        const splittime          = newtime[0].split(':')
        const newmonth           = this.ShortMonthNameEng(month) 
            return `${newmonth} ${day} ${year} ${splittime[0]}:${splittime[1]}`
        }
    },
    TodayYYYYMMDD () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    EncodeUrl(value) {
        let returns = value
        if (value === undefined) {
          returns = ''
        }
        let today       = this.TodayYYYYMMDD()
        let login_user  = this.UsersID()
        let encode      = btoa(login_user+today+'&'+returns)
        return encode
      },
    DecodeUrl(value) {
        let returns = value
        if (value === undefined) {
            returns = ''
        }
        let decode  = atob(returns)
        let split   = decode.split('&')
        let id      = split[1]
        return id
    },
    Split (data, delimiter) {
        let split = data.split(delimiter)
        return split
    },
    FormatTimeToWords(timeString) {
        // Memisahkan jam dan menit dari input
        const [hours, minutes] = timeString.split(':');
        
        // Menghapus angka nol di depan jam jika ada, lalu tambahkan kata-kata
        let formattedHours = parseInt(hours, 10) + ' Jam';
        let formattedMinutes = parseInt(minutes, 10) + ' Menit';
        if (parseInt(hours, 10) === 0) {
            formattedHours = ""
        }
        return `${formattedHours} ${formattedMinutes}`;
    },
    PastDate(daysAgo) {
        const today = new Date(); // Tanggal hari ini
        const pastDate = new Date(); // Salinan objek tanggal
        pastDate.setDate(today.getDate() - daysAgo); // Kurangi hari sesuai input
        return pastDate.toISOString().split('T')[0]; // Format ke "YYYY-MM-DD"
    },
    DateDiff(a, b) {
        // Discard the time and time-zone information
        if (a !== undefined && b !== undefined) {
            if (a !== '1900-01-01' && b !== '1900-01-01') {
                const _MS_PER_DAY = 1000 * 60 * 60 * 24
                let date1  = new Date(a)
                let date2  = new Date(b)
                const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
                const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())
                console.log(a,b)
                return Math.floor((utc2 - utc1) / _MS_PER_DAY)
            }
        } else {
            return 0
        }
    },
    //FormatDateFull format date MM/DD/YYYY H:i:s
    FormatDateIndo (date) {
        if (!date) return null
        const  dates             = date.split('T')
        const [year, month, day] = dates[0].split('-')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${day} ${newmonth} ${year}`
    },
    ErrorCode () {
        return 'Tidak ada Data'
    },
    NewFormatNumber (value, fixed) {
        let val = (value / 1).toFixed(fixed).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    Ages(birthdate) {
        const today = new Date();
        const birthdateobj = new Date(birthdate);
    
        let ages = today.getFullYear() - birthdateobj.getFullYear();
        const months = today.getMonth() - birthdateobj.getMonth();
        const days = today.getDate() - birthdateobj.getDate();
    
        // Periksa apakah ulang tahun tahun ini belum lewat
        if (months < 0 || (months === 0 && days < 0)) {
            ages--;
        }
    
        return ages;
    },
    GetMonth (date) {
        const formatdate = new Date(date)
        const months = formatdate.getMonth()
        return months
    },
    CheckImage(filename) {
        // Daftar ekstensi gambar yang valid
        const validExtensions = ['jpg', 'jpeg', 'png'];
      
        // Ambil ekstensi file
        const extension = filename.split('.').pop().toLowerCase();
      
        // Periksa apakah ekstensi termasuk dalam daftar valid
        return validExtensions.includes(extension);
    },
    OpenUrl(url) {
        window.open(url)
    },
    ConvNom(amount) {
        let convert = String(amount)
        if (convert === '') {
            convert = '0'
        }
        return convert
    }
}