<template>
    <div>
    <app-toolbarcustom app_title="Form Transportasi" app_menu="employeetransportation"></app-toolbarcustom>
        <template>
            <v-stepper v-model="steppers" class="mt-15 elevation-0">
              <v-stepper-header>
                <v-stepper-step
                  :complete="steppers > 1"
                  step="1"
                >
                  Form
                </v-stepper-step>
          
                <v-divider></v-divider>
          
                <v-stepper-step
                  :complete="steppers > 2"
                  step="2"
                >
                  Summary
                </v-stepper-step>
                
              </v-stepper-header>
          
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card
                    class="mb-12 elevation-0"
                  >
                  <v-form enctype="multipart/form-data" ref="form_transportation_data" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="mt-2">
                        <app-cb
                        cb_type           ="todo"
                        cb_url            ="apiListTodo"
                        :cb_url_parameter  ="`todo_flag=expense&todo_cuser=${$functions.UsersID()}`"
                        cb_title          ="Todo"
                        cb_id             ="transportation_todoid"
                        cb_desc           ="transportation_tododesc"
                        cb_rules          ="Mohon isi Tipe"
                        cb_desc_readonly  ="readonly"
                        cb_items_id       ="todo_id"
                        cb_items_desc     ="todo_desc"
                        cb_items_additional_1 ="todo_date"
                        :cb_value_id      ="form.transportation_todoid"
                        :cb_value_desc    ="form.transportation_tododesc"
                        cb_modul          ="transportation"
                        @clicked          ="GetTodo"
                      >
                      </app-cb>
                    </v-col>
                    <template v-if="form.transportation_tododesc">
                    <!-- ID -->
                    <v-col cols="12" sm="6" md="12" class="mt-n6">
                        <v-dialog
                        ref                 ="dialog_transportation_sdate"
                        v-model             ="modal.transportation_sdate"
                        :return-value.sync  ="form.transportation_sdate"
                        width               ="290px"
                        persistent
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value              ="transportation_sdate_value"
                            label               ="Periode Awal"
                            prepend-icon  ="event"
                            background-color    ="date"
                            v-on                ="on"
                            v-mask              ="'####-##-##'"
                            placeholder         ="YYYY-MM-DD"
                            id                  ="find_transportation_sdate"
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.transportation_sdate" :min="$functions.PastDate(7)" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.transportation_sdate = false">Cancel</v-btn>
                        <v-btn
                            text
                            color   ="primary"
                            @click  ="GetData('transportation_sdate',form.transportation_sdate)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    </v-col>
                    <!-- ID -->
                    <v-col cols="12" sm="6" md="12" class="mt-n6">
                        <v-dialog
                        ref                 ="dialog_transportation_edate"
                        v-model             ="modal.transportation_edate"
                        :return-value.sync  ="form.transportation_edate"
                        width               ="290px"
                        persistent
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value              ="transportation_edate_value"
                            label               ="Periode Akhir"
                            prepend-icon  ="event"
                            background-color    ="date"
                            v-on                ="on"
                            v-mask              ="'####-##-##'"
                            placeholder         ="YYYY-MM-DD"
                            id                  ="find_transportation_edate"
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.transportation_edate" :min="$functions.PastDate(7)" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.transportation_edate = false">Cancel</v-btn>
                        <v-btn
                            text
                            color   ="primary"
                            @click  ="GetData('transportation_edate',form.transportation_edate)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                        <app-cb
                        cb_type           ="code"
                        cb_url            ="apiListCode"
                        cb_url_parameter  ="code_actived=Y&code_group=TRANSPORTTYPE"
                        cb_title          ="Tipe"
                        cb_id             ="transportation_typeid"
                        cb_desc           ="transportation_typeiddesc"
                        cb_rules          ="Mohon isi Tipe"
                        cb_desc_readonly  ="readonly"
                        cb_items_id       ="code_id"
                        cb_items_desc     ="code_desc"
                        :cb_value_id      ="form.transportation_type"
                        :cb_value_desc    ="form.transportation_typedesc"
                        @clicked          ="GetTransportType"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                        <v-text-field 
                            v-model="form.transportation_departure"
                            :rules="form.transportation_departure_rules"
                            prepend-icon="location_on"
                            placeholder="Dari"
                            label="Dari *"
                            required
                            solo-inverted
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                        <v-text-field 
                            v-model="form.transportation_arrive"
                            :rules="form.transportation_arrive_rules"
                            prepend-icon="location_on"
                            placeholder="Tujuan"
                            label="Tujuan *"
                            required
                            solo-inverted
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                        <v-text-field 
                            v-model="form.transportation_remarks"
                            :rules="form.transportation_remarks_rules"
                            prepend-icon="notes"
                            placeholder="Berikan Catatan"
                            label="Catatan *"
                            required
                            solo-inverted
                            outlined
                        ></v-text-field>
                    </v-col>
                    <template v-if="form.transportation_type === 'P'">
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                        <vuetify-money
                            v-model         ="form.transportation_parking"
                            label           ="Parkir"
                            placeholder     ="Parkir"
                            backgroundColor ="numeric"
                            :options        ="format_amount"
                            :properties     ="properties"
                            required
                            solo-inverted
                            outlined
                            @input         ="GetTotal"
                        >
                        </vuetify-money>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mt-n6">
                            <vuetify-money
                                v-model         ="form.transportation_fuel"
                                label           ="Bensin"
                                placeholder     ="Bensin"
                                backgroundColor ="numeric"
                                :options        ="format_amount"
                                :properties     ="properties"
                                required
                                solo-inverted
                                outlined
                                @input         ="GetTotal"
                            >
                            </vuetify-money>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mt-n6">
                            <vuetify-money
                                v-model         ="form.transportation_toll"
                                label           ="Tol"
                                placeholder     ="Tol"
                                backgroundColor ="numeric"
                                :options        ="format_amount"
                                :properties     ="properties"
                                required
                                solo-inverted
                                outlined
                                @input         ="GetTotal"
                            >
                            </vuetify-money>
                        </v-col>
                    </template>
                        <v-col cols="12" sm="12" md="12" class="mt-n6">
                            <vuetify-money
                                v-model         ="form.transportation_total"
                                label           ="Total"
                                placeholder     ="Total"
                                :backgroundColor ="form.transportation_type === 'P'?'readonly':'numeric'"
                                :options        ="format_amount"
                                :properties     ="properties"
                                :readonly       ="form.transportation_type === 'P'?true:false"
                                required
                                solo-inverted
                                outlined
                            >
                            </vuetify-money>
                        </v-col>
                    </template>
                    </v-row> 
                    </v-form>
                  </v-card>
                  <br>
                  <v-btn
                    color="primary"
                    @click="NextStep(1)"
                    block
                    v-if="form.transportation_tododesc"
                  >
                    Lanjut dan Upload Bukti
                  </v-btn>
          
                </v-stepper-content>
          
                <v-stepper-content step="2">
                  <v-card
                    class="mb-12"
                    outlined
                  >
                  <v-card-title class="body-2 font-weight-bold">Ringkasan Transportasi</v-card-title>

                    <v-card-text class="caption">
                            <v-row align="center">
                                <v-col cols="6">Todo</v-col><v-col cols="6" class="font-weight-bold">{{ form.transportation_tododesc }} ({{$functions.FormatDateIndoWithDayName(form.transportation_tododate)}})</v-col>
                                <v-col cols="6" class="mt-n5">Periode Awal</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.transportation_sdate) }}</v-col>
                                <v-col cols="6" class="mt-n5">Periode Akhir</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.transportation_edate) }}</v-col>
                                <v-col cols="6" class="mt-n5">Tipe</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_typedesc }}</v-col>
                                <v-col cols="6" class="mt-n5">Dari</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_departure }}</v-col>
                                <v-col cols="6" class="mt-n5">Tujuan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_arrive }}</v-col>
                                <v-col cols="6" class="mt-n5">Catatan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_remarks }}</v-col>
                                <v-col cols="6" class="mt-n5" v-if="form.transportation_type === 'P'">Parkir</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold">{{ $functions.NewFormatNumber(form.transportation_parking) }}</v-col>
                                <v-col cols="6" class="mt-n5" v-if="form.transportation_type === 'P'">Bensin</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold">{{ $functions.NewFormatNumber(form.transportation_fuel) }}</v-col>
                                <v-col cols="6" class="mt-n5" v-if="form.transportation_type === 'P'">Tol</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold">{{ $functions.NewFormatNumber(form.transportation_toll) }}</v-col>
                                <v-col cols="6" class="mt-n5 body-1 font-weight-bold">Total</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold green--text body-1">{{ $functions.NewFormatNumber(form.transportation_total) }}</v-col>
                            </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-subheader class="body-2 font-weight-bold">Lampiran <v-spacer></v-spacer><span @click="UploadAttach()"><small><u>Upload Ulang</u></small></span></v-subheader>
                    <div class="primary--text font-weight-black" v-if="form.uploadinfo !== ''">Filename : {{form.uploadinfo}}</div>
                    <v-img :src="upload.src"></v-img>
                 </v-card>
          
                  <v-btn
                    color="primary"
                    @click="Confirm('add')"
                    block
                    class="mb-2"
                  >
                    Proses
                  </v-btn>
          
                  <v-btn color="secondary" @click="steppers = 1" block>
                    Sebelumnya
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </template>
          <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="upload.dialog" max-width="490">
            <v-card>
                <v-card-title class="primary headline white--text">{{upload.title}}</v-card-title>
                <v-card-text>
                    <v-progress-linear
                    indeterminate
                    color="fourth"
                    rounded
                    v-if="loading.upload"
                    ></v-progress-linear>
                    <div class="primary--text font-weight-black" v-if="form.uploadinfo !== ''">Filename : {{form.uploadinfo}}</div>
                    <v-img :src="upload.src"></v-img>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green" outlined :disabled="upload.ok !== 'OK'?true:false" @click="Upload" v-if="form.uploadattach !== ''">{{upload.ok}}</v-btn>
                    <v-btn color="red" outlined @click="DeleteAttach" v-if="form.uploadattach !== ''">{{upload.delete}}</v-btn>
                    <input type="file" ref="attachment" @change="UploadAttachment" style="display:none">
                    <!-- <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "upload.ok !== 'OK' ? true:false" @click="WriteTodo('result')">{{upload.ok}}</v-btn> -->
                </v-card-actions>
        </v-card>
        </v-dialog>
          <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    import imageCompression from 'browser-image-compression'
    export default {
        data: () => ({
            steppers: 1,
            url_type: 'add',
            current_date: '',
            form: {
                transportation_todoid: '',
                transportation_tododesc: '',
                transportation_tododate: '',
                transportation_sdate: new Date().toISOString().substr(0, 10),
                transportation_sdate_rules: [v => !!v || 'Mohon isi Tanggal Awal'],
                transportation_edate: new Date().toISOString().substr(0, 10),
                transportation_edate_rules: [v => !!v || 'Mohon isi Tanggal Akhir'],
                transportation_usersid: '',
                transportation_type: 'P',
                transportation_typedesc: 'Pribadi',
                transportation_departure: '',
                transportation_departure_rules: [v => !!v || 'Mohon masukkan dari'],
                transportation_arrive: '',
                transportation_arrive_rules: [v => !!v || 'Mohon masukkan tiba'],
                transportation_remarks: '',
                transportation_remarks_rules: [v => !!v || 'Mohon masukkan catatan'],
                transportation_parking: 0,
                transportation_fuel: 0,
                transportation_toll: 0,
                transportation_total: 0,
                transportation_attachment: '',
                uploadinfo: '',
                uploadattach: '',
            },
            modal: {
                transportation_sdate: false,
                transportation_edate: false,
            },
            loading: {
                upload: false
            },
            upload: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
                upload: 'Upload',
                delete: 'Hapus',
                src: '',
                target: '',
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            },
            format_amount: {
                locale: "id-ID",
                prefix: "",
                suffix: "",
                length: 15,
                precision: 0
            },
            // Format Properties
            properties: {
                reverse: true,
                'prepend-icon':'money'
            },
        }),
         // Check If Back Button Press, Modal Close
        beforeRouteLeave (to, from, next) {
            if (this.modal.transportation_sdate || this.modal.transportation_edate) {
                this.modal.transportation_sdate = false
                this.modal.transportation_edate = false
                next(false)
            } else {
                next()
            }
        },
        computed: {
            transportation_sdate_value () {
                return this.form.transportation_sdate ? this.$moment(this.form.transportation_sdate).format('LL') : ''
            },
            transportation_edate_value () {
                return this.form.transportation_edate ? this.$moment(this.form.transportation_edate).format('LL') : ''
            }
        },
        created() {
            this.form.transportation_usersid = this.$functions.UsersID()
            if (this.$route.params.id !== undefined) {
                let decode = this.$functions.DecodeUrl(this.$route.params.id)
                // Pecah string berdasarkan "&" untuk memisahkan setiap pasangan kunci-nilai
                const pairs = decode.split('|');

                // Buat objek untuk menyimpan hasilnya
                const result = {};
                    // Loop melalui setiap pasangan kunci-nilai
                    pairs.forEach(pair => {
                    const [key, value] = pair.split('='); // Pecah pasangan menjadi kunci dan nilai
                    result[key] = value; // Simpan ke dalam objek
                });
                this.form.transportation_todoid = result.todo_id
                this.form.transportation_tododesc = result.todo_desc
                this.form.transportation_tododate = result.todo_date
                this.form.transportation_sdate = result.todo_date
                this.form.transportation_edate = result.todo_date
            }
        },
        methods: {
            GoTo (url) {
               this.$router.push(url)
            },
            Confirm (flag) {
                if (flag === 'add') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Proses'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                this.confirm.ok = 'Loading...'
                if (this.url_type === 'add' || this.url_type === 'edit') {
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  transportation_todoid: this.form.transportation_todoid,
                  transportation_tododesc: this.form.transportation_tododesc,
                  transportation_tododate: this.form.transportation_tododate,
                  transportation_type: this.form.transportation_type,
                  transportation_sdate: this.form.transportation_sdate,
                  transportation_edate: this.form.transportation_edate,
                  transportation_departure: this.form.transportation_departure,
                  transportation_arrive: this.form.transportation_arrive,
                  transportation_remarks: this.form.transportation_remarks,
                  transportation_parking: this.$functions.ConvNom(this.form.transportation_parking),
                  transportation_fuel: this.$functions.ConvNom(this.form.transportation_fuel),
                  transportation_toll: this.$functions.ConvNom(this.form.transportation_toll),
                  transportation_total: this.$functions.ConvNom(this.form.transportation_total),
                  transportation_usersid: this.$functions.UsersID(),
                  transportation_attachment  : this.upload.src.replace(/^.+?;base64,/, ''),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteTransportation'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.SnackBar(true, 'success', 'Proses Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                            let encodeurl = this.$functions.EncodeUrl(feedback[0].feedback_id)
                            this.$router.push({name: 'employeetransportationdetail',params:{id:encodeurl}})
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            GetData (flag, value) {
                switch (flag) {
                	case 'transportation_sdate':
                		this.$refs.dialog_transportation_sdate.save(value)
                        this.transportation_sdate = this.$moment(value).format('YYYY-MM-DD')
                    break
                    case 'transportation_edate':
                		this.$refs.dialog_transportation_edate.save(value)
                        this.transportation_edate = this.$moment(value).format('YYYY-MM-DD')
                    break
                }
            },
            NextStep(id) {
                if (id === 1) {
                    let validation = ''
                    let sdate = this.$functions.GetMonth(this.form.transportation_sdate)
                    let edate = this.$functions.GetMonth(this.form.transportation_edate)
                    if (sdate !== edate) {
                        validation = 'Mohon Masukkan Periode dalam Bulan yang sama'
                    } else if (this.form.transportation_type === '') {
                        validation = 'Mohon isi Tipe'
                    } else if (this.form.transportation_departure === '') {
                        validation = 'Mohon isi Dari'
                    } else if (this.form.transportation_arrive === '') {
                        validation = 'Mohon isi Tujuan'
                    } else if (this.form.transportation_total === 0) {
                        validation = 'Mohon isi Biaya'
                    } else if (this.form.transportation_remarks === '') {
                        validation = 'Mohon isi Catatan'
                    }
                    if (validation !== '') {
                        this.SnackBar(true, 'error', validation, 0)
                    } else {
                        if ((this.form.transportation_balance * 1) < (this.form.transportation_total * 1)) {
                            this.SnackBar(true, 'error', 'Total tidak bisa lebih dari '+this.$functions.NewFormatNumber(this.form.transportation_balance), 0)
                        } else {
                            if (this.form.uploadattach === '') {
                                this.UploadAttach()
                            } else {
                                this.steppers = 2
                            }
                        }
                    }
                }
            },
            GetTodo (value) {
                if (value !== undefined) {    
                    let splits  = this.$functions.Split(value, '|')
                    this.form.transportation_todoid = splits[0]
                    this.form.transportation_tododesc = splits[1]
                    this.form.transportation_tododate = splits[3]
                    this.form.transportation_remarks = splits[1]
                }
            },
            GetTransportType (value) {
                if (value !== undefined) {    
                    let splits  = this.$functions.Split(value, '|')
                    this.form.transportation_type = splits[0]
                    this.form.transportation_typedesc = splits[1]
                    this.form.transportation_parking = 0
                    this.form.transportation_fuel = 0
                    this.form.transportation_toll = 0
                    this.form.transportation_total = 0
                }
            },
            GetTotal() {
                let parking = this.form.transportation_parking * 1
                let fuel = this.form.transportation_fuel * 1
                let toll = this.form.transportation_toll * 1
                let total    = parking + fuel + toll
                this.form.transportation_total = total
            },
            Upload () {
                if (this.form.uploadattach === '') {
                    this.SnackBar(true, 'error', 'Mohon Upload Bukti', 3000)
                } else {
                    this.upload.dialog = false
                    this.steppers = 2
                }
            },
            UploadAttach () {
                this.upload.dialog = true
                let data = this
                setTimeout(function(){
                    data.$refs.attachment.click()
                }, 500)
            },
            DeleteAttach () {
                this.upload.src = ''
                this.form.uploadinfo = ''
                this.form.uploadattach = ''
                let data = this
                setTimeout(function(){
                    data.$refs.attachment.click()
                }, 500)
            },
            UploadAttachment () {
                var input = event.target
                if (input.files && input.files[0]) {
                    this.loading.upload = true
                    var filename = input.files[0].name
                    var extension = filename.split('.').pop().toLowerCase()
                    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
                        var imageFile = input.files[0]
                        var options = {
                        maxSizeMB: 0.3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                        }
                        let data = this
                        imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            data.GetImages(compressedFile)
                            data.form.uploadinfo = ''
                            data.loading.upload = false
                        })
                        .catch(function (error) {
                            alert(error.message)
                            this.loading.upload = false
                        })
                    } else {
                        var reader = new FileReader()
                        reader.onload = (e) => {
                            this.upload.src = e.target.result
                            this.form.uploadinfo = filename
                        }
                        this.form.uploadattach = input.files[0]
                        reader.readAsDataURL(input.files[0])
                        this.loading.upload = false
                    }
                }
            },
            async GetImages (data) {
                let blobUrl = URL.createObjectURL(data)
                try {
                    // Fetch the Blob data using the Blob URL
                    const response = await fetch(blobUrl);
                    const blobData = await response.blob();

                    // Convert Blob data to Base64
                    const reader = new FileReader();
                    reader.onload = () => {
                    this.upload.src = reader.result;
                    };
                    reader.readAsDataURL(blobData); // Convert Blob to Base64
                } catch (error) {
                    console.error('Error converting Blob URL to Base64:', error);
                }
                this.form.uploadattach = data
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>