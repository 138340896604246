<template>
    <div>
    <app-toolbaravatar :app_title="title" :app_avatar="form.entertainment_usersavatar"></app-toolbaravatar>
    <v-card
    class="mt-15"
    outlined
    >
    <v-progress-linear
    indeterminate
    color="fourth"
    rounded
    v-if="loading.page"
    ></v-progress-linear>
    <v-card-title class="body-2 font-weight-bold">Ringkasan Reimburse Entertainment</v-card-title>
    <v-card-text class="caption">
        <v-row align="center">
            <v-col cols="6">Todo</v-col><v-col cols="6" class="font-weight-bold">{{ form.entertainment_tododesc }} ({{$functions.FormatDateIndoWithDayName(form.entertainment_tododate)}})</v-col>
            <v-col cols="6" class="mt-n5">Periode Awal</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.entertainment_sdate) }}</v-col>
            <v-col cols="6" class="mt-n5">Periode Akhir</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.entertainment_edate) }}</v-col>
            <v-col cols="6" class="mt-n5">Tipe</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.entertainment_typedesc }}</v-col>
            <v-col cols="6" class="mt-n5">Dari</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.entertainment_clientcompany }}</v-col>
            <v-col cols="6" class="mt-n5">Tujuan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.entertainment_clientname }}</v-col>
            <v-col cols="6" class="mt-n5">Catatan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.entertainment_remarks }}</v-col>
            <v-col cols="6" class="mt-n5 body-1 font-weight-bold">Total</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold green--text body-1">{{ $functions.NewFormatNumber(form.entertainment_total) }}</v-col>
        </v-row>
    <v-divider></v-divider>
    <v-subheader class="body-2 font-weight-bold">Lampiran <v-spacer></v-spacer></v-subheader>
    <div v-if="$functions.CheckImage(form.entertainment_attachmentpathurl)"><v-img :src="form.entertainment_attachmentpathurl"></v-img></div>
    <div class="primary--text font-weight-black mb-4" @click="$functions.OpenUrl(form.entertainment_attachmentpathurl)" v-else>Filename : {{form.entertainment_attachmentpath}}</div>
    <v-btn color="primary" @click="Confirm('approve')" block class="mb-2" v-if="form.entertainment_status === 'R' && form.entertainment_leaderuser === $functions.UsersID()">Setuju</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.entertainment_status === 'R' && form.entertainment_leaderuser === $functions.UsersID()">Tolak</v-btn>
    <v-btn color="primary" @click="Confirm('hr')" block class="mb-2" v-if="form.entertainment_status === 'L' && form.hr_user === $functions.UsersID()">Setuju HR</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.entertainment_status === 'L' && form.hr_user === $functions.UsersID()">Tolak HR</v-btn>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-title class="body-2 font-weight-bold">Timeline</v-card-title>
    <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              color="green"
              small
            >
              <div>
                <div class="caption">
                    created:<strong> {{ form.entertainment_cuser }}</strong> &bull; {{ $functions.FormatDateTime(form.entertainment_cdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              :color="$functions.FormatDateTime(form.entertainment_hrdate)?'green':'yellow'"
              small
              v-if="form.entertainment_hruser"
            >
              <div>
                <div class="caption">
                    hr:<strong> {{ form.entertainment_hruser }}</strong> &bull; {{ $functions.FormatDateTime(form.entertainment_hrdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              color="red"
              small
              v-if="form.entertainment_rejectuser"
            >
              <div>
                <div class="caption">
                    reject:<strong> {{ form.entertainment_rejectuser }}</strong>&bull;{{ $functions.FormatDateTime(form.entertainment_rejectdate) }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
    </v-card>
    <v-alert
    border="left"
    colored-border
    color="secondary"
    elevation="2"
    v-if="form.entertainment_notes"
    >
    <div v-html="form.entertainment_notes"></div>
    </v-alert>
    <v-dialog v-model="confirm.dialog" max-width="490" persistent>
        <v-card>
            <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
            <v-card-text>
                {{confirm.text}}
                <v-text-field 
                    v-model="confirm.notes"
                    prepend-inner-icon="notes"
                    placeholder="Catatan"
                    label="Catatan"
                    required
                    solo-inverted
                    outlined
                    class="mt-3"
                    id="confirmnotes"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <app-snackbar 
        :dialog="snackbar.dialog"
        :color="snackbar.color"
        :text="snackbar.text"
        :timeout="snackbar.timeout"
        @close="snackbar.dialog = false"
    ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            title: '',
            loading: {
                page: false
            },
            form: {
                entertainment_id: '',
                entertainment_todoid: '',
                entertainment_tododesc: '',
                entertainment_tododate: '',
                entertainment_sdate: '',
                entertainment_edate: '',
                entertainment_usersid: '',
                entertainment_usersname: '',
                entertainment_usersavatar: '',
                entertainment_type: '',
                entertainment_typedesc: '',
                entertainment_clientcompany: '',
                entertainment_clientname: '',
                entertainment_clientposition: '',
                entertainment_clientbusiness: '',
                entertainment_place: '',
                entertainment_status: '',
                entertainment_statusdesc: '',
                entertainment_remarks: '',
                entertainment_total: 0,
                entertainment_attachmentpath: '',
                entertainment_attachmentpathurl: '',
                entertainment_notes: '',
                entertainment_hrdate: '',
                entertainment_hruser: '',
                entertainment_rejectdate: '',
                entertainment_rejectuser: '',
                entertainment_leaderuser: '',
                entertainment_cdate: '',
                entertainment_cuser: '',
                hr_user: '',
                uploadinfo: '',
                uploadattach: '',
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
                notes: '',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            },
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                this.loading.page = true
                let decode = this.$functions.DecodeUrl(this.$route.params.id)
                let formdata = {
                  entertainment_id: decode,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListEntertainment'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.title = feedback[0].entertainment_id
                            this.form.entertainment_id = feedback[0].entertainment_id
                            this.form.entertainment_todoid = feedback[0].entertainment_todoid
                            this.form.entertainment_tododesc = feedback[0].entertainment_tododesc
                            this.form.entertainment_tododate = feedback[0].entertainment_tododate
                            this.form.entertainment_sdate = feedback[0].entertainment_sdate
                            this.form.entertainment_edate = feedback[0].entertainment_edate
                            this.form.entertainment_type = feedback[0].entertainment_type
                            this.form.entertainment_typedesc = feedback[0].entertainment_typedesc
                            this.form.entertainment_clientcompany = feedback[0].entertainment_clientcompany
                            this.form.entertainment_clientname = feedback[0].entertainment_clientname
                            this.form.entertainment_clientposition = feedback[0].entertainment_clientposition
                            this.form.entertainment_clientbusiness = feedback[0].entertainment_clientbusiness
                            this.form.entertainment_place = feedback[0].entertainment_place
                            this.form.entertainment_total = feedback[0].entertainment_total
                            this.form.entertainment_remarks = feedback[0].entertainment_remarks
                            this.form.entertainment_usersid = feedback[0].entertainment_usersid
                            this.form.entertainment_usersavatar = feedback[0].entertainment_usersavatarurl
                            this.form.entertainment_status = feedback[0].entertainment_status
                            this.form.entertainment_statusdesc = feedback[0].entertainment_statusdesc
                            this.form.entertainment_rejectuser = feedback[0].entertainment_rejectuser
                            this.form.entertainment_rejectdate = feedback[0].entertainment_rejectdate
                            this.form.entertainment_notes = feedback[0].entertainment_notes
                            this.form.entertainment_attachmentpath = feedback[0].entertainment_attachmentpath
                            this.form.entertainment_attachmentpathurl = feedback[0].entertainment_attachmentpathurl
                            this.form.entertainment_cuser = feedback[0].entertainment_cuser
                            this.form.entertainment_cdate = feedback[0].entertainment_cdate
                            if  (feedback[0].entertainment_status === 'L') {
                                this.CheckHR()
                            }
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            CheckHR () {
                this.loading.page = true
                let formdata = {
                  users_type: 'HR-MGR',
                  flag: 'mobileapp',
                  users_company: this.$functions.UsersCompany(),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.form.hr_user = feedback[0].users_id
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            Confirm (flag) {
                if (flag === 'approve') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Setuju'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                } else if (flag === 'reject') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Tolak'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                if (this.confirm.notes === '') {
                    this.SnackBar(true, 'success', 'Mohon isi catatan', 3000)
                    setTimeout(function(){
                    document.getElementById('confirmnotes').focus()
                    }, 500)
                } else {
                    this.confirm.ok = 'Loading...'
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  entertainment_id: this.form.entertainment_id,
                  entertainment_notes: this.confirm.notes,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteEntertainment'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            let flag = ''
                            if (this.url_type === 'approve') {
                                flag = 'Setuju'
                                this.form.entertainment_status = 'L'
                                this.form.entertainment_statusdesc = 'UnApprove HR'
                            } else if (this.url_type === 'approve') {
                                flag = 'Tolak'
                                this.form.entertainment_status = 'J'
                                this.form.entertainment_statusdesc = 'Reject'
                            } else if (this.url_type === 'hr') {
                                flag = 'Setuju HR'
                                this.form.entertainment_status = 'A'
                                this.form.entertainment_statusdesc = 'Approve'
                            }
                            this.SnackBar(true, 'success', flag+' Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>