<template>
    <div>
    <app-toolbaravatar :app_title="title" :app_avatar="form.leave_usersavatar"></app-toolbaravatar>
    <v-card
    class="mt-15"
    outlined
    >
    <v-progress-linear
    indeterminate
    color="fourth"
    rounded
    v-if="loading.page"
    ></v-progress-linear>
    <v-card-title class="body-2 font-weight-bold">Ringkasan Cuti/Ijin</v-card-title>
    <v-card-text class="caption">
            <v-row align="center" class="mb-2">
                <v-col cols="6">User</v-col><v-col cols="6" class="font-weight-bold">{{ form.leave_usersid }}</v-col>
                <v-col cols="6" class="mt-n5">Kategori</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.leave_categorydesc }}</v-col>
                <v-col cols="6" class="mt-n5">Tanggal Mulai</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.leave_sdate) }}</v-col>
                <v-col cols="6" class="mt-n5">Tanggal Akhir</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.leave_edate) }}</v-col>
                <v-col cols="6" class="mt-n5">Lama Cuti/Ijin</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.leave_duration }} Hari</v-col>
                <v-col cols="6" class="mt-n5">Alasan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.leave_remarks }}</v-col>
                <v-col cols="6" class="mt-n5"></v-col><v-col cols="6" class="mt-n5 font-weight-bold"><v-chip
                    class="ma-2"
                    color="primary"
                    label
                    small
                    outlined
                  >
                  {{ form.leave_statusdesc }}
                  </v-chip></v-col>
            </v-row>
    <v-btn color="primary" @click="Confirm('approve')" block class="mb-2" v-if="form.leave_status === 'R' && form.leave_leaderuser === $functions.UsersID()">Setuju</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.leave_status === 'R' && form.leave_leaderuser === $functions.UsersID()">Tolak</v-btn>
    <v-btn color="primary" @click="Confirm('hr')" block class="mb-2" v-if="form.leave_status === 'L' && form.hr_user === $functions.UsersID()">Setuju HR</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.leave_status === 'L' && form.hr_user === $functions.UsersID()">Tolak HR</v-btn>
    <v-btn color="green" @click="UploadAttach" block v-if="form.leave_category === 'CS' && form.leave_usersid === $functions.UsersID() && form.leave_attachmentf !== 'Y'">Upload Surat Ijin Sakit</v-btn>
    </v-card-text>
    <v-divider v-if="form.leave_category === 'CS'"></v-divider>
    <v-card-title class="body-2 font-weight-bold" v-if="form.leave_category === 'CS'"><v-btn outlined @click="OpenAttachment(form.leave_attachmentpathurl)">Surat Ijin Sakit <v-icon light>description</v-icon></v-btn></v-card-title>
    
    <v-divider></v-divider>
    <v-card-title class="body-2 font-weight-bold">Timeline</v-card-title>
    <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              color="green"
              small
            >
              <div>
                <div class="caption">
                    created:<strong> {{ form.leave_cuser }}</strong> &bull; {{ $functions.FormatDateTime(form.leave_cdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              :color="$functions.FormatDateTime(form.leave_leaderdate)?'green':'yellow'"
              small
              v-if="form.leave_leaderuser"
            >
              <div>
                <div class="caption">
                    leader:<strong> {{ form.leave_leaderuser }}</strong> &bull; {{ $functions.FormatDateTime(form.leave_leaderdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              color="red"
              small
              v-if="form.leave_rejectuser"
            >
              <div>
                <div class="caption">
                    reject:<strong> {{ form.leave_rejectuser }}</strong>&bull;{{ $functions.FormatDateTime(form.leave_rejectdate) }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
    </v-card>
    <v-alert
    border="left"
    colored-border
    color="secondary"
    elevation="2"
    v-if="form.leave_notes"
    >
    <div v-html="form.leave_notes"></div>
    </v-alert>
    <v-dialog v-model="confirm.dialog" max-width="490" persistent>
        <v-card>
            <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
            <v-card-text>
                {{confirm.text}}
                <v-text-field 
                    v-model="confirm.notes"
                    prepend-inner-icon="notes"
                    placeholder="Catatan"
                    label="Catatan"
                    required
                    solo-inverted
                    outlined
                    class="mt-3"
                    id="confirmnotes"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="upload.dialog" max-width="490">
        <v-card>
            <v-card-title class="primary headline white--text">{{upload.title}}</v-card-title>
            <v-card-text>
                <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.upload"
                ></v-progress-linear>
                <div class="primary--text font-weight-black" v-if="form.uploadinfo !== ''">Filename : {{form.uploadinfo}}</div>
                <v-img :src="upload.src"></v-img>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click.stop="upload.dialog = false">{{upload.close}}</v-btn>
                <v-btn color="green" outlined :disabled="upload.ok !== 'OK'?true:false" @click="UploadData" v-if="form.uploadattach !== ''">{{upload.ok}}</v-btn>
                <v-btn color="red" outlined @click="DeleteAttach" v-if="form.uploadattach !== ''">{{upload.delete}}</v-btn>
                <input type="file" ref="attachment" @change="UploadAttachment" style="display:none">
                <!-- <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "upload.ok !== 'OK' ? true:false" @click="WriteTodo('result')">{{upload.ok}}</v-btn> -->
            </v-card-actions>
    </v-card>
    </v-dialog>
    <app-snackbar 
        :dialog="snackbar.dialog"
        :color="snackbar.color"
        :text="snackbar.text"
        :timeout="snackbar.timeout"
        @close="snackbar.dialog = false"
    ></app-snackbar>
    </div>
</template>
<script>
    import imageCompression from 'browser-image-compression'
    export default {
        data: () => ({
            title: '',
            loading: {
                page: false,
                upload: false,
            },
            form: {
                leave_id: '',
                leave_category: '',
                leave_categorydesc: '',
                leave_sdate: '',
                leave_edate: '',
                leave_duration: 0,
                leave_remarks: '',
                leave_usersid: '',
                leave_usersavatar: '',
                leave_status: '',
                leave_statusdesc: '',
                leave_leaderuser: '',
                leave_leaderdate: '',
                leave_rejectuser: '',
                leave_rejectdate: '',
                leave_notes: '',
                leave_cuser: '',
                leave_cdate: '',
                leave_attachment: '',
                leave_attachmentf: '',
                leave_attachmentpath: '',
                leave_attachmentpathurl: '',
                hr_user: '',
                uploadinfo: '',
                uploadattach: '',
            },
            upload: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
                upload: 'Upload',
                delete: 'Hapus',
                src: '',
                target: '',
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
                notes: '',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            },
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                this.loading.page = true
                let decode = this.$functions.DecodeUrl(this.$route.params.id)
                let formdata = {
                  leave_id: decode,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListLeave'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.title = feedback[0].leave_id
                            this.form.leave_id = feedback[0].leave_id
                            this.form.leave_category = feedback[0].leave_category
                            this.form.leave_categorydesc = feedback[0].leave_categorydesc
                            this.form.leave_sdate = feedback[0].leave_sdate
                            this.form.leave_edate = feedback[0].leave_edate
                            this.form.leave_duration = feedback[0].leave_duration
                            this.form.leave_remarks = feedback[0].leave_remarks
                            this.form.leave_usersid = feedback[0].leave_usersid
                            this.form.leave_usersavatar = feedback[0].leave_usersavatarurl
                            this.form.leave_status = feedback[0].leave_status
                            this.form.leave_statusdesc = feedback[0].leave_statusdesc
                            this.form.leave_leaderuser = feedback[0].leave_leaderuser
                            this.form.leave_leaderdate = feedback[0].leave_leaderdate
                            this.form.leave_rejectuser = feedback[0].leave_rejectuser
                            this.form.leave_rejectdate = feedback[0].leave_rejectdate
                            this.form.leave_attachmentf = feedback[0].leave_attachmentf
                            this.form.leave_attachmentpath = feedback[0].leave_attachmentpath
                            this.form.leave_attachmentpathurl = feedback[0].leave_attachmentpathurl
                            this.form.leave_notes = feedback[0].leave_notes
                            this.form.leave_cuser = feedback[0].leave_cuser
                            this.form.leave_cdate = feedback[0].leave_cdate
                            if  (feedback[0].leave_status === 'L') {
                                this.CheckHR()
                            }
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            CheckHR () {
                this.loading.page = true
                let formdata = {
                  users_type: 'HR-MGR',
                  flag: 'mobileapp',
                  users_company: this.$functions.UsersCompany(),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.form.hr_user = feedback[0].users_id
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            Confirm (flag) {
                if (flag === 'approve') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Setuju'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                } else if (flag === 'hr') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Setuju HR'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                } else if (flag === 'reject') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Tolak'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                if (this.confirm.notes === '') {
                    this.SnackBar(true, 'success', 'Mohon isi catatan', 3000)
                    setTimeout(function(){
                    document.getElementById('confirmnotes').focus()
                    }, 500)
                } else {
                    this.confirm.ok = 'Loading...'
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  leave_id: this.form.leave_id,
                  leave_notes: this.confirm.notes,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteLeave'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            let flag = ''
                            if (this.url_type === 'approve') {
                                flag = 'Setuju'
                                this.form.leave_status = 'L'
                                this.form.leave_statusdesc = 'UnApprove HR'
                            } else if (this.url_type === 'approve') {
                                flag = 'Tolak'
                                this.form.leave_status = 'J'
                                this.form.leave_statusdesc = 'Reject'
                            } else if (this.url_type === 'hr') {
                                flag = 'Setuju HR'
                                this.form.leave_status = 'A'
                                this.form.leave_statusdesc = 'Approve'
                            }
                            this.SnackBar(true, 'success', flag+' Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            UploadAttach () {
                this.upload.dialog = true
                let data = this
                setTimeout(function(){
                    data.$refs.attachment.click()
                }, 500)
            },
            DeleteAttach () {
                this.upload.src = ''
                this.form.uploadinfo = ''
                this.form.uploadattach = ''
                let data = this
                setTimeout(function(){
                    data.$refs.attachment.click()
                }, 500)
            },
            UploadAttachment () {
                var input = event.target
                if (input.files && input.files[0]) {
                    this.loading.upload = true
                    var filename = input.files[0].name
                    var extension = filename.split('.').pop().toLowerCase()
                    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
                        var imageFile = input.files[0]
                        var options = {
                        maxSizeMB: 0.3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                        }
                        let data = this
                        imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            data.GetImages(compressedFile)
                            data.form.uploadinfo = ''
                            data.loading.upload = false
                        })
                        .catch(function (error) {
                            alert(error.message)
                            this.loading.upload = false
                        })
                    } else {
                        var reader = new FileReader()
                        reader.onload = (e) => {
                            this.upload.src = e.target.result
                            this.form.uploadinfo = filename
                        }
                        this.form.uploadattach = input.files[0]
                        reader.readAsDataURL(input.files[0])
                        this.loading.upload = false
                    }
                }
            },
            async GetImages (data) {
                let blobUrl = URL.createObjectURL(data)
                try {
                    // Fetch the Blob data using the Blob URL
                    const response = await fetch(blobUrl);
                    const blobData = await response.blob();

                    // Convert Blob data to Base64
                    const reader = new FileReader();
                    reader.onload = () => {
                    this.upload.src = reader.result;
                    };
                    reader.readAsDataURL(blobData); // Convert Blob to Base64
                } catch (error) {
                    console.error('Error converting Blob URL to Base64:', error);
                }
                this.form.uploadattach = data
            },
            UploadData () {
                this.upload.ok = 'Loading...'
                let formdata = {
                  url_type: 'upload',
                  leave_id: this.form.leave_id,
                  leave_attachment  : this.upload.src.replace(/^.+?;base64,/, ''),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteLeave'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.form.leave_attachmentf = 'Y'
                            this.SnackBar(true, 'success', 'Upload Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.upload.ok = 'Upload'
                    this.upload.dialog = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.dialog.upload = 'Upload'
                })
            },
            OpenAttachment (url) {
                window.open(url)
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>