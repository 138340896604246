<template>
    <div>
        <app-toolbarcustom app_title="Todo" app_back="Y"></app-toolbarcustom>
          <v-btn fab dark large color="primary" fixed right bottom @click="EventTodo">
            <v-icon>event</v-icon>
          </v-btn>
          <v-container class="mt-11">
           <v-progress-linear
            indeterminate
            color="secondary"
            v-if="loading.page"
            ></v-progress-linear>
            <v-row>
                <v-col cols="12">
                        <v-subheader class="primary--text">Hari ini</v-subheader>
                        <v-list>
                        <template  v-for="(item, i) in todo.today">
                        <v-list-item
                            :key="i"
                            color="primary"
                            @click="EditTodo(item.todo_id)"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-if="item.todo_status === 'D'"><s>{{item.todo_desc}}</s></v-list-item-title>
                                <v-list-item-title v-else>{{item.todo_desc}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{item.todo_time}}
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="`${i}index`"></v-divider>
                        </template>
                    </v-list>
                        <v-text-field
                            v-model="register.todo_desc"
                            label="Apa yang mau anda kerjakan hari ini?"
                            filled
                            clearable
                            counter="70"
                            maxlength="70"
                            @keyup.enter="WriteTodo('today')"
                        ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-subheader class="primary--text">Kegiatan Lainnya</v-subheader>
                    <v-list two-line>
                        <template  v-for="(item, i) in todo.nottoday">
                        <v-list-item
                            :key="i"
                            color="secondary"
                            @click="EditTodo(item.todo_id)"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-if="item.todo_status === 'D'"><s>{{item.todo_desc}}</s></v-list-item-title>
                                <v-list-item-title v-else>{{item.todo_desc}}</v-list-item-title>
                                <v-list-item-subtitle>{{$functions.FormatDateIndoWithDayName(item.todo_date)}} <v-icon v-if="item.todo_externalf === 'Y'" small color="secondary">output</v-icon><v-icon v-else small color="primary">group</v-icon></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{item.todo_time}}
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="`${i}index`"></v-divider>
                        </template>
                    </v-list>
                </v-col>
            </v-row>
         </v-container>
        <v-bottom-sheet v-model="sheet.event" :persistent="$route.params.id !==undefined?true:false">
            <v-list>
            <v-subheader>Todo</v-subheader>
                <v-container>
                    <v-form enctype="multipart/form-data" ref="form_event" lazy-validation>
                        <v-text-field
                            v-model="register.todo_event"
                            :rules ="register.todo_event_rules"
                            label="Kegiatan"
                            append-icon="notes"
                            filled
                            clearable
                            @keyup.enter="WriteTodo('today')"
                            id="todo_event"
                        ></v-text-field>
                        <v-dialog
                            ref="dialog_date"
                            v-model="dialog.todo_date"
                            :return-value.sync="register.todo_date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                :value        ="todo_date_value"
                                :rules        ="register.todo_date_rules"
                                label         ="Tanggal Kegiatan"
                                append-icon   ="event"
                                placeholder   ="Masukkan Tanggal Kegiatan"
                                filled
                                readonly
                                v-on          ="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            ref           ="todo_date_picker"
                            label         ="Tanggal Kegiatan"
                            append-icon   ="calendar_today"
                            v-model       ="register.todo_date"
                            :min           ="$functions.PastDate(30)"
                            @change       ="SaveDate"
                            ></v-date-picker>
                        </v-dialog>
                        <v-dialog
                            ref="dialog_time"
                            v-model="dialog.todo_time"
                            :return-value.sync="register.todo_time"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                :value        ="todo_time_value"
                                :rules        ="register.todo_time_rules"
                                label         ="Jam Kegiatan"
                                append-icon   ="event"
                                placeholder   ="Masukkan Jam Kegiatan"
                                filled
                                readonly
                                v-on          ="on"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            ref           ="todo_time_picker"
                            label         ="Jam Kegiatan"
                            append-icon   ="calendar_today"
                            v-model       ="register.todo_time"
                            format="24hr"
                            >
                            <v-btn outlined @click ="dialog.todo_time = false">Batal</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn outlined @click ="$refs.dialog_time.save(register.todo_time)">Ok</v-btn>
                            </v-time-picker>
                        </v-dialog>
                        <v-radio-group
                            v-model="register.todo_externalf"
                            row
                            class="mt-n3"
                            >
                            <v-radio
                                label="Internal"
                                value="N"
                            ></v-radio>
                            <v-radio
                                label="Eksternal"
                                value="Y"
                            ></v-radio>
                        </v-radio-group>
                        <v-btn block outlined @click="Loading" class="secondary" v-if="register.todo_status !== 'D'">Simpan</v-btn>
                        <v-btn block outlined @click="Result" class="mt-2 primary" v-if="register.todo_status !== 'D'">Hasil</v-btn>
                    </v-form>
                </v-container>
            </v-list>
        </v-bottom-sheet>
        <v-dialog v-model="confirm.dialog" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="WriteTodo('event')">{{confirm.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="result.dialog" max-width="490">
            <v-card>
                <v-card-title class="primary headline white--text">{{result.title}}</v-card-title>
                <v-card-text>
                     <v-text-field
                        label="Catatan"
                        filled
                        v-model="register.todo_resultremarks"
                    ></v-text-field>
                    <div class="primary--text font-weight-black" v-if="todo.uploadinfo !== ''">Filename : {{todo.uploadinfo}}</div>
                    <v-img :src="result.src"></v-img>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="result.dialog = false">{{result.close}}</v-btn>
                    <v-btn color="primary" outlined :disabled="result.ok !== 'OK'?true:false" @click="ResultTodo" v-if="todo.uploadattach !== ''">{{result.ok}}</v-btn>
                    <v-btn color="secondary" outlined @click="DeleteAttach" v-if="todo.uploadattach !== ''">{{result.delete}}</v-btn>
                    <v-btn color="primary" outlined @click="UploadAttach" v-else>{{result.upload}}</v-btn>
                    <input type="file" ref="attachment" @change="UploadAttachment" style="display:none">
                    <!-- <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "result.ok !== 'OK' ? true:false" @click="WriteTodo('result')">{{result.ok}}</v-btn> -->
                </v-card-actions>
        </v-card>
        </v-dialog>
        <app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
    </div>
</template>
<script>
  import imageCompression from 'browser-image-compression'
  export default {
    data: () => ({
      url_type: 'add',
      register: {
        todo_id: '',
        todo_desc: '',
        todo_event: '',
        todo_event_rules: [v => !!v || 'Mohon isi kegiatan'],
        todo_date: '',
        todo_date_date: '',
        todo_date_rules: [v => !!v || 'Mohon isi tanggal'],
        todo_time: '',
        todo_time_value: '12:00',
        todo_time_rules: [v => !!v || 'Mohon isi jam'],
        todo_externalf: 'N',
        todo_attachment: '',
        todo_resultremarks: '',
        todo_status: '',
      },
      loading: {
        page: false
      },
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
      todo: {
        today: {},
        tomorrow: {},
        nottoday: {},
        uploadinfo: '',
        uploadattach: '',
      },
      sheet: {
        event: false
      },
      dialog: {
        todo_date: false,
        todo_time: false,
      },
      confirm: {
        dialog: false,
        title: '',
        text: '',
        ok: 'OK',
        close: 'Tutup',
      },
      result: {
        dialog: false,
        title: '',
        text: '',
        ok: 'OK',
        close: 'Tutup',
        upload: 'Upload',
        delete: 'Delete',
        src: '',
        target: '',
      },
      snackbar: {
        dialog: false,
        color: "primary",
        text: "",
        timeout: 3000,
      },
    }),
    created() {
        this.Initial()
    },
    computed: {
        todo_date_value () {
            return this.register.todo_date ? this.$moment(this.register.todo_date).format('LL') : ''
        },
        todo_time_value () {
            return this.register.todo_time ? this.register.todo_time : ''
        },
    },
    methods: {
        Initial (){
            this.ListTodo('today')
            this.ListTodo('nottoday')
            if (this.$route.params.id !== undefined) {
                this.sheet.event = true
                setTimeout(function(){
                    document.getElementById('todo_event').focus()
                }, 500)
            }
        },
        Loading () {
            if (this.$refs.form_event.validate()) {
                this.confirm.dialog = true
                this.confirm.title = 'Simpan?'
            }
        },
        UploadAttach () {
            this.$refs.attachment.click()
        },
        DeleteAttach () {
            this.result.src = ''
            this.todo.uploadinfo = ''
            this.todo.uploadattach = ''
        },
        EventTodo () {
            this.sheet.event = true
            this.url_type    = 'add'
        },
        WriteTodo (type) {
            console.log(type)
            this.loading.page = true
            let todo_date = this.$functions.Today()
            let todo_time = '00:00:00'
            let todo_desc = this.register.todo_desc
            let todo_id   = ''
            let todo_externalf = this.register.todo_externalf
            if (type !== 'today') {
                this.confirm.ok = 'Loading...'
                todo_id = this.register.todo_id
                todo_date = this.register.todo_date
                todo_time = this.register.todo_time
                todo_desc = this.register.todo_event
            } else {
                this.url_type  = 'add'
            }
            let formdata = {
                url_type: this.url_type,
                todo_id: todo_id,
                todo_desc: todo_desc,
                todo_date: todo_date,
                todo_time: todo_time,
                todo_externalf: todo_externalf,
                todo_actived: 'Y',
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiWriteTodo'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                        this.register.todo_desc = ''
                        this.register.todo_event = ''
                        this.confirm.ok = 'OK'
                        this.sheet.event = false
                        this.ListTodo('today')
                        this.ListTodo('nottoday')
                        this.SnackBar(true,'success','Todo sukses',3000)
                        if (this.$route.params.id !== undefined) {
                            let decode = this.$functions.DecodeUrl(this.$route.params.id)
                            let form = 'employee'+decode+'formtodo'
                            let encodeurl = this.$functions.EncodeUrl('todo_id='+feedback[0].feedback_id+'|todo_desc='+todo_desc+'|todo_date='+todo_desc)
                            this.$router.push({name: form,params:{id:encodeurl}})
                        }
                    } else {
                        this.confirm.ok = 'OK'
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                    this.loading.page = false
                }
            })
            .catch(e => {
                this.confirm.ok = 'OK'
                this.SnackBar(true,'error',e,3000)
                this.loading.page = false
            })
        },
        EditTodo (id) {
            this.loading.page = true
            let formdata = {
                todo_id: id,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListTodo'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                        this.loading.page        = false
                        this.sheet.event         = true
                        this.url_type            = 'edit'
                        this.register.todo_id    = feedback[0].todo_id
                        this.register.todo_event = feedback[0].todo_desc
                        this.register.todo_date  = feedback[0].todo_date
                        this.register.todo_time  = feedback[0].todo_time
                        this.register.todo_externalf  = feedback[0].todo_externalf
                        this.register.todo_status  = feedback[0].todo_status
                    } else {
                        this.loading.page        = false
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.loading.page        = false
                this.SnackBar(true,'error',e,3000)
            })
        },
        ListTodo (flag) {
            this.loading.page = true
            let todo_date = ''
            let todo_nottoday = ''
            if (flag === 'today') {
                todo_date = this.$functions.Today()
                todo_nottoday = ''
            } else {
                todo_date = ''
                todo_nottoday = 'Y'
            }
            let formdata = {
                todo_date: todo_date,
                todo_nottoday: todo_nottoday,
                todo_cuser: this.$functions.UsersID(),
                order_by: 'todo_date,todo_time',
                order_type: 'ASC',
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListTodo'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                        if (flag === 'today') {
                            this.todo.today = feedback
                        } else {
                            this.todo.nottoday = feedback
                        }
                        this.loading.page = false
                    } else {
                        this.loading.page = false
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                } else {
                    this.loading.page = false
                }
            })
            .catch(e => {
                this.loading.page = false
                this.SnackBar(true,'error',e,3000)
            })
        },
        ResultTodo () {
            this.result.ok = 'Loading...'
            let formdata = {
                url_type: 'result',
                todo_id: this.register.todo_id,
                todo_resultremarks: this.register.todo_resultremarks,
                todo_attachment  : this.result.src.replace(/^.+?;base64,/, ''),
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiWriteTodo'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                        this.result.dialog = false
                        this.sheet.event = false
                        this.register.todo_resultremarks = ''
                        this.DeleteAttach()
                        this.ListTodo('today')
                        this.ListTodo('nottoday')
                        this.SnackBar(true,'success','Todo sukses',3000)
                    } else {
                        this.confirm.ok = 'OK'
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
                this.result.ok = 'OK'
            })
            .catch(e => {
                this.result.ok = 'OK'
                this.SnackBar(true,'error',e,3000)
            })
        },
        Result () {
            this.result.dialog = true
            this.result.title  = 'Lampirkan Hasil Todo Anda'
        },
        UploadAttachment () {
            var input = event.target
            if (input.files && input.files[0]) {
                var filename = input.files[0].name
                var extension = filename.split('.').pop().toLowerCase()
                if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
                    var imageFile = input.files[0]
                    var options = {
                    maxSizeMB: 0.3,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                    }
                    let data = this
                    imageCompression(imageFile, options)
                    .then(function (compressedFile) {
                        data.GetImages(compressedFile)
                        data.todo.uploadinfo = ''
                    })
                    .catch(function (error) {
                        alert(error.message)
                    })
                } else {
                    var reader = new FileReader()
                    reader.onload = (e) => {
                        this.result.src = e.target.result
                        this.todo.uploadinfo = filename
                    }
                    this.todo.uploadattach = input.files[0]
                    reader.readAsDataURL(input.files[0])
                }
            }
        },
        async GetImages (data) {
            let blobUrl = URL.createObjectURL(data)
            try {
                // Fetch the Blob data using the Blob URL
                const response = await fetch(blobUrl);
                const blobData = await response.blob();

                // Convert Blob data to Base64
                const reader = new FileReader();
                reader.onload = () => {
                this.result.src = reader.result;
                };
                reader.readAsDataURL(blobData); // Convert Blob to Base64
            } catch (error) {
                console.error('Error converting Blob URL to Base64:', error);
            }
            this.todo.uploadattach = data
        },
        SaveDate (date) {
            this.$refs.dialog_date.save(date)
            this.register.todo_date_date = this.$moment(date).format('YYYY-MM-DD')
        },
        SnackBar(dialog, color, text, timeout) {
            this.snackbar = {
                dialog: dialog,
                color: color,
                text: text,
                timeout: timeout,
            }
            this.confirm.dialog 	= false
            this.confirm.ok 		="Ok"
        },
    },
  }
</script>