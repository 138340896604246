<template>
    <div>
    <app-toolbarcustom app_title="Data Karyawan" app_back="Y"></app-toolbarcustom>
        <div class="mb-15"></div>
        <div class="mx-2">
            <v-text-field
            v-model="search.text"
            prepend-inner-icon="search"
            placeholder="Cari Data Karyawan"
            clearable
            required
            solo-inverted
            outlined
        ></v-text-field>
        </div>
        <v-progress-linear
        indeterminate
        color="fourth"
        rounded
        v-if="loading.page"
        ></v-progress-linear>
        <v-card
            class="mx-auto mb-2"
            max-width="344"
            outlined
            v-for="row in Filter"
            :key="row.users_id"
            @click="Open(row.users_id, row.users_avatar_url)"
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                {{row.users_title}}
                </div>
                <v-list-item-title><v-icon v-if="row.users_locked ==='Y'">lock</v-icon><v-icon v-else>person</v-icon> {{row.users_name}}</v-list-item-title>
                <v-list-item-subtitle><v-icon>location_on</v-icon> {{ row.users_branch_desc }}</v-list-item-subtitle>
                <v-list-item-subtitle><v-icon>group</v-icon> {{ row.users_departmentdesc }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                size="80"
                color="grey"
            >
                <img :src="row.users_avatar_url">
            </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
            <v-chip
                class="ma-2"
                color="primary"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-email
                </v-icon>
                {{ row.users_email }}
              </v-chip>
            </v-card-actions>
        </v-card>
        <infinite-loading @infinite="ListInfinite" v-if="posts.length >= pages.limit"></infinite-loading>
        <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                    <v-text-field
                        v-model="form.users_password"
                        prepend-inner-icon="lock_reset"
                        placeholder="Masukkan Password"
                        clearable
                        required
                        solo-inverted
                        outlined
                        class="mt-2"
                        id="users_password"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <v-bottom-sheet v-model="dialog.users">
            <v-list>
              <v-subheader class="title">{{form.users_id}}<v-spacer></v-spacer><v-avatar>
                <img
                  :src="form.users_avatar_url"
                  :alt="form.users_id"
                >
              </v-avatar></v-subheader>
              <v-list-item
                v-for="row in detail.users"
                :key="row.id"
                @click="Process(row.id)"
              >
                <v-list-item-avatar>
                    <v-btn
                    fab
                    dark
                    small
                    color="primary"
                  >
                    <v-icon dark>
                      {{ row.icon }}
                    </v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-title>{{ row.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-bottom-sheet>
         <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            url_type: '',
            posts:[],
            loading: {
                page: false
            },
            detail: {
                users: [
                        {'id':'resetpass','icon':'lock_reset','name':'Reset Password'},
                        {'id':'notexpired','icon':'history','name':'Not Expired'},
                        {'id':'unlock','icon':'lock_open','name':'Buka Kunci'},
                        {'id':'lock','icon':'lock','name':'Kunci'},
                        {'id':'absenceoffice','icon':'location_city','name':'Absen di Kantor'},
                        {'id':'absenceanywhere','icon':'location_on','name':'Absen di Mana Saja'},
                    ]
            },
            filter: {
                users_managementflag: 'N'
            },
            form: {
                users_id: '',
                users_avatar_url: '',
                users_password: '',
            },
            pages: {
                limit:10,
                offset:10,
                fetch:10,
            },
            search: {
                text: ''
            },
            dialog: {
                users: false
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            this.FirstLoad()
        },
        computed: {
            Filter () {
                const search = this.search.text.toLowerCase().trim()
                if (!search) {
                    return this.posts
                } else {
                    return this.posts.filter(b => b.users_name.toLowerCase().indexOf(search) > -1)
                }
            }
        },
        methods: {
            ListInfinite($state) {
                let formdata = {
                  users_company: this.$functions.UsersCompany(),
                  users_managementflag: this.filter.users_managementflag,
                  users_actived: 'Y',
                  order_by: 'users_grade',
                  order_type: 'DESC',
                  flag: 'mobileapp',
                  offset: this.$functions.ConvNom(this.pages.offset),
                  fetch: this.$functions.ConvNom(this.pages.fetch),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(({data}) => {
                    if (data.length) {
                        this.pages.offset = this.pages.offset + this.pages.fetch
                        this.posts.push(...data)
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
            },
            FirstLoad () {
                this.loading.page = true
                if (this.$functions.UsersType() === 'SUPERUSER' || this.$functions.UsersType() === 'HR-MGR') {
                    this.filter.users_managementflag = ''
                }
                let formdata = {
                  users_company: this.$functions.UsersCompany(),
                  users_managementflag: this.filter.users_managementflag,
                  users_actived: 'Y',
                  order_by: 'users_grade',
                  order_type: 'DESC',
                  flag: 'mobileapp',
                  limit: this.$functions.ConvNom(this.pages.limit),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            Process (id) {
                if (id === 'resetpass') {
                    this.url_type = id
                    this.confirm.dialog = true
                    this.confirm.title = 'Reset Password'
                    setTimeout(function(){
                    document.getElementById('users_password').focus()
                    }, 500)
                } else {
                    this.url_type = id
                    this.Confirm()
                }
            },
            Loading () {
                this.confirm.ok = 'Loading...'
                this.Confirm()
            },
            Confirm () {
                this.loading.page = true
                let formdata = {
                  url_type: this.url_type,
                  users_id: this.form.users_id,
                  confirm_password: this.form.users_password
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteUsers'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.SnackBar(true, 'success', `Berhasil`, 3000)
                        this.confirm.dialog = false
                        this.dialog.users = false
                        this.pages.limit = 100
                        this.pages.offset = 100
                        this.pages.fetch = 100
                        this.FirstLoad()
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                    this.confirm.ok = 'OK'
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                    this.confirm.ok = 'OK'
                })
            },
            Open (id,avatar) {
                let userstype = this.$functions.UsersType()
                if (userstype === 'SUPERUSER' || userstype === 'HR-MGR') {
                    this.form.users_id = id
                    this.form.users_avatar_url = avatar
                    this.dialog.users = true
                }
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>