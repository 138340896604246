<template>
    <div>
    <app-toolbarcustom app_title="Data Absensi" app_menu="employeeabsence"></app-toolbarcustom>
        <div class="mb-15"></div>
        <v-progress-linear
        indeterminate
        color="fourth"
        rounded
        v-if="loading.page"
        ></v-progress-linear>
        <v-card
            class="mx-auto mb-2"
            max-width="344"
            outlined
            v-for="row in posts"
            :key="row.absence_date"
            @click="OpenAbsence(row.absence_date,row.absence_usersid)"
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                {{ $functions.FormatDateIndoWithDayName(row.absence_date) }}
                </div>
                <div>
                    <div class="mb-2">Telat: <span v-if="row.absence_timelate !== '00:00'">{{ $functions.FormatTimeToWords(row.absence_timelate) }}</span><span v-else>-</span></div>
                    <div>Pulang Cepat: <span v-if="row.absence_timeearly !== '00:00'">{{ $functions.FormatTimeToWords(row.absence_timeearly) }}</span><span v-else>-</span></div>
                </div>
                <v-list-item-subtitle>{{row.absence_remarks}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                size="80"
                color="grey"
            >
                <img :src="row.absence_usersavatarurl">
            </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
            <small class="ml-2"><v-icon>timer</v-icon> {{ row.absence_timein }} - {{ row.absence_timeout }}</small><v-spacer></v-spacer><small>
                <v-chip
                class="ma-2"
                color="green"
                label
                text-color="white"
                v-if="row.absence_category === 'P'"
              >
                <v-icon left>
                    done_all
                </v-icon>
                Hadir
              </v-chip>
              <v-chip
                class="ma-2"
                color="red"
                label
                text-color="white"
                v-else
              >
                <v-icon left>
                    check
                </v-icon>
                Tidak Hadir
              </v-chip>
            </small>
            </v-card-actions>
        </v-card>
        <infinite-loading @infinite="ListInfinite" v-if="posts.length >= pages.limit"></infinite-loading>
        <v-dialog v-model="dialog.absence" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text"><v-avatar class="mr-2"><v-img :src="form.absence_usersavatarurl"></v-img></v-avatar> {{form.absence_usersname}}</v-card-title>
                <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.absence"
                ></v-progress-linear>
                <v-card-text>
                    <div class="text-center">
                        <div class="font-weight-bold green--text">IN: {{form.absence_timein}} <span v-if="form.absence_timelate !== '00:00'">&bull; TELAT: {{$functions.FormatTimeToWords(form.absence_timelate)}}</span></div>
                        <div><img :src="form.absence_timeinphoto" height="210px"></div>
                        <div><iframe :src="form.absence_timein_url" width="100%" height="175" frameborder="0"></iframe></div>
                    </div>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <div v-if="form.absence_timeout !== '00:00'" class="text-center">
                        <div class="font-weight-bold red--text">OUT: {{form.absence_timeout}} <span v-if="form.absence_timeearly !== '00:00'">&bull; LEBIH CEPAT: {{$functions.FormatTimeToWords(form.absence_timeearly)}}</span></div>
                        <div><img :src="form.absence_timeoutphoto" height="210px"></div>
                        <div><iframe :src="form.absence_timeout_url" width="100%" height="175" frameborder="0"></iframe></div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    {{$functions.FormatDateIndoWithDayName(form.absence_date)}}
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="dialog.absence = false">{{confirm.close}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            posts:[],
            form: {
                absence_usersname: '',
                absence_usersavatarurl: '',
                absence_date: '',
                absence_timein: '',
                absence_timeout: '',
                absence_latitude_in: '',
                absence_longitude_in: '',
                absence_geolocation_in: '',
                absence_latitude_out: '',
                absence_longitude_out: '',
                absence_geolocation_out: '',
                absence_timein_url: '',
                absence_timeout_url: '',
                absence_timeinphoto: '',
                absence_timeoutphoto: '',
                absence_timelate: '',
                absence_timeearly: '',
            },
            pages: {
                limit:10,
                offset:10,
                fetch:10,
            },
            loading: {
                page: false,
                loading: false,
            },
            dialog: {
                absence: false
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            this.FirstLoad()
        },
        beforeRouteLeave (to, from, next) {
            if (this.dialog.absence) {
                this.dialog.absence = false
                next(false)
            } else {
                next()
            }
        },
        methods: {
            ListInfinite($state) {
                let formdata = {
                  absence_usersid: this.$functions.UsersID(),
                  order_by: 'absence_date',
                  order_type: 'DESC',
                  offset: this.$functions.ConvNom(this.pages.offset),
                  fetch: this.$functions.ConvNom(this.pages.fetch),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(({data}) => {
                    if (data.length) {
                        this.pages.offset = this.pages.offset + this.pages.fetch
                        this.posts.push(...data)
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
            },
            FirstLoad () {
                this.loading.page = true
                let formdata = {
                  absence_usersid: this.$functions.UsersID(),
                  order_by: 'absence_date',
                  order_type: 'DESC',
                  limit: this.$functions.ConvNom(this.pages.limit)
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            OpenAbsence(date,id) {
                this.dialog.absence = true
                this.loading.absence = true
                let formdata = {
                    absence_date: date,
                    absence_usersid: id,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
                headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
                }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                        this.form.absence_usersname = feedback[0].absence_usersname
                        this.form.absence_usersavatarurl = feedback[0].absence_usersavatarurl
                        this.form.absence_date = feedback[0].absence_date
                        this.form.absence_timein = feedback[0].absence_timein
                        this.form.absence_timeout = feedback[0].absence_timeout
                        this.form.absence_latitude_in = feedback[0].absence_latitude_in
                        this.form.absence_longitude_in = feedback[0].absence_longitude_in
                        this.form.absence_geolocation_in = feedback[0].absence_geolocation_in
                        this.form.absence_latitude_out = feedback[0].absence_latitude_out
                        this.form.absence_longitude_out = feedback[0].absence_longitude_out
                        this.form.absence_geolocation_out = feedback[0].absence_geolocation_out
                        this.form.absence_timeinphoto = feedback[0].absence_timeinphoto
                        this.form.absence_timeoutphoto = feedback[0].absence_timeoutphoto
                        this.form.absence_timelate = feedback[0].absence_timelate
                        this.form.absence_timeearly = feedback[0].absence_timeearly
                        this.form.absence_timein_url = 'https://maps.google.com/maps?q='+feedback[0].absence_latitude_in+','+feedback[0].absence_longitude_in+'&t=&z=19&ie=UTF8&iwloc=&output=embed'
                        this.form.absence_timeout_url = 'https://maps.google.com/maps?q='+feedback[0].absence_latitude_out+','+feedback[0].absence_longitude_out+'&t=&z=19&ie=UTF8&iwloc=&output=embed'
                        } else {
                            this.SnackBar(true,'error',feedback[0].feedback,3000)
                        }
                    }
                    this.loading.absence = false
                })
                .catch(e => {
                    this.SnackBar(true,'error',e,3000)
                    this.loading.absence = false
                })
            },
            Loading () {
                this.confirm.ok = 'Loading...'
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>