<template>
    <div>
    <app-toolbarcustom app_title="Data Entertainment" app_menu="employeeentertainment"></app-toolbarcustom>
        <div class="mb-15"></div>
        <v-progress-linear
        indeterminate
        color="fourth"
        rounded
        v-if="loading.page"
        ></v-progress-linear>
        <v-card
            class="mx-auto mb-2"
            max-width="344"
            outlined
            v-for="row in posts"
            :key="row.entertainment_id"
            @click="GoTo(row.entertainment_id)"
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                {{ $functions.FormatDateIndoWithDayName(row.entertainment_sdate) }}
                <span v-if="row.entertainment_sdate !== row.entertainment_edate"><br>{{ $functions.FormatDateIndoWithDayName(row.entertainment_edate) }}</span>
                </div>
                <v-list-item-title>{{row.entertainment_typedesc}}</v-list-item-title>
                <v-list-item-subtitle>{{row.entertainment_remarks}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                size="80"
                color="grey"
            >
                <img :src="row.entertainment_usersavatarurl">
            </v-list-item-avatar>
            </v-list-item>
            <div class="ml-4">
                dari {{ row.entertainment_departure }} ke {{ row.entertainment_arrive }}
            </div>
            <v-card-actions>
            <small class="ml-2"><v-icon>money</v-icon> IDR {{ $functions.NewFormatNumber(row.entertainment_total) }}</small><v-spacer></v-spacer><small><v-chip
                class="ma-2"
                color="primary"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                {{ row.entertainment_statusdesc }}
              </v-chip></small>
            </v-card-actions>
        </v-card>
        <infinite-loading @infinite="ListInfinite" v-if="posts.length >= pages.limit"></infinite-loading>
        <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            posts:[],
            loading: {
                page: false
            },
            pages: {
                limit:10,
                offset:10,
                fetch:10,
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            ListInfinite($state) {
                let pending = ''
                if (this.$route.query.pending !== undefined) {
                    pending = this.$route.query.pending
                }
                let formdata = {
                  entertainment_usersid: this.$functions.UsersID(),
                  entertainment_pending: pending,
                  order_by: 'entertainment_cdate',
                  order_type: 'DESC',
                  offset: this.$functions.ConvNom(this.pages.offset),
                  fetch: this.$functions.ConvNom(this.pages.fetch),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListEntertainment'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(({data}) => {
                    if (data.length) {
                        this.pages.offset = this.pages.offset + this.pages.fetch
                        this.posts.push(...data)
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
            },
            FirstLoad () {
                this.loading.page = true
                let pending = ''
                if (this.$route.query.pending !== undefined) {
                    pending = this.$route.query.pending
                }
                let formdata = {
                  entertainment_usersid: this.$functions.UsersID(),
                  entertainment_pending: pending,
                  order_by: 'entertainment_cdate',
                  order_type: 'DESC',
                  limit: this.$functions.ConvNom(this.pages.limit),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListEntertainment'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            GoTo (id) {
                this.$router.push({name: 'employeeentertainmentdetail',params:{id:this.$functions.EncodeUrl(id)}})
            },
            Loading () {
                this.confirm.ok = 'Loading...'
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>