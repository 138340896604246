<template>
    <div>
    <app-toolbarcustom app_title="Form Lembur" app_menu="employeeovertime"></app-toolbarcustom>
        <template>
            <v-stepper v-model="steppers" class="mt-15 elevation-0">
              <v-stepper-header>
                <v-stepper-step
                  :complete="steppers > 1"
                  step="1"
                >
                  Form
                </v-stepper-step>
          
                <v-divider></v-divider>
          
                <v-stepper-step
                  :complete="steppers > 2"
                  step="2"
                >
                  Summary
                </v-stepper-step>
                
              </v-stepper-header>
          
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card
                    class="mb-12 elevation-0"
                    height="200px"
                  >
                  <v-form enctype="multipart/form-data" ref="form_overtime_data" lazy-validation>
                  <v-row>
                    <!-- ID -->
                    <v-col cols="12" sm="6" md="12" class="mt-2">
                        <v-dialog
                        ref                 ="dialog_overtime_date"
                        v-model             ="modal.overtime_date"
                        :return-value.sync  ="form.overtime_date"
                        width               ="290px"
                        persistent
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value              ="overtime_date_value"
                            label               ="Tanggal Pengajuan"
                            prepend-icon  ="event"
                            background-color    ="date"
                            v-on                ="on"
                            v-mask              ="'####-##-##'"
                            placeholder         ="YYYY-MM-DD"
                            id                  ="find_overtime_date"
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.overtime_date" :min="$functions.Today()" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.overtime_date = false">Cancel</v-btn>
                        <v-btn
                            text
                            color   ="primary"
                            @click  ="GetData('overtime_date',form.overtime_date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" class="mt-n6">
                    <v-dialog
                        ref="dialog_overtime_timein"
                        v-model="modal.overtime_timein"
                        :return-value.sync="form.overtime_timein"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="form.overtime_timein"
                            :rules="form.overtime_timein_rules"
                            label="IN *"
                            prepend-icon="access_time"
                            background-color="time"
                            readonly
                            v-on="on"
                            required 
                            outlined
                        ></v-text-field>
                        </template>
                        <v-time-picker
                        v-if="modal.overtime_timein"
                        v-model="form.overtime_timein"
                        format="24hr"
                        >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.overtime_timein = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog_overtime_timein.save(form.overtime_timein)">OK</v-btn>
                        </v-time-picker>
                    </v-dialog>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" class="mt-n6">
                        <v-dialog
                            ref="dialog_overtime_timeout"
                            v-model="modal.overtime_timeout"
                            :return-value.sync="form.overtime_timeout"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="form.overtime_timeout"
                                :rules="form.overtime_timeout_rules"
                                label="OUT *"
                                prepend-icon="access_time"
                                background-color="time"
                                readonly
                                v-on="on"
                                required 
                                outlined
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            v-if="modal.overtime_timeout"
                            v-model="form.overtime_timeout"
                            format="24hr"
                            >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal.overtime_timeout = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.dialog_overtime_timeout.save(form.overtime_timeout)">OK</v-btn>
                            </v-time-picker>
                        </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mt-n6">
                            <v-text-field 
                                v-model="form.overtime_remarks"
                                :rules="form.overtime_remarks_rules"
                                prepend-icon="notes"
                                placeholder="Berikan Alasan Lembur"
                                label="Alasan Lembur *"
                                required
                                solo-inverted
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>  
                    </v-form>
                  </v-card>
                  <br>
                  <v-btn
                    color="primary"
                    @click="NextStep(1)"
                    block
                  >
                    Lanjut
                  </v-btn>
          
                </v-stepper-content>
          
                <v-stepper-content step="2">
                  <v-card
                    class="mb-12"
                    outlined
                  >
                  <v-card-title class="body-2 font-weight-bold">Ringkasan Lembur</v-card-title>

                    <v-card-text class="caption">
                            <v-row align="center">
                                <v-col cols="6">Tanggal Pengajuan</v-col><v-col cols="6" class="font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.overtime_date) }}</v-col>
                                <v-col cols="6" class="mt-n5">Jam Mulai</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.overtime_timein }}</v-col>
                                <v-col cols="6" class="mt-n5">Jam Selesai</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.overtime_timeout }}</v-col>
                                <v-col cols="6" class="mt-n5">Durasi</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.MinuteDiff(form.overtime_timein,form.overtime_timeout) }}</v-col>
                                <v-col cols="6" class="mt-n5">Alasan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.overtime_remarks }}</v-col>
                            </v-row>
                    </v-card-text>
                 </v-card>
          
                  <v-btn
                    color="primary"
                    @click="Confirm('add')"
                    block
                    class="mb-2"
                  >
                    Proses
                  </v-btn>
          
                  <v-btn color="secondary" @click="steppers = 1" block>
                    Sebelumnya
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </template>
          <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            steppers: 1,
            url_type: 'add',
            current_date: '',
            form: {
                overtime_date: new Date().toISOString().substr(0, 10),
                overtime_date_rules: [v => !!v || 'Mohon isi Tanggal Lembur'],
                overtime_timein: '',
                overtime_timein_rules: [v => !!v || 'Mohon isi Jam Mulai'],
                overtime_timeout: '',
                overtime_timeout_rules: [v => !!v || 'Mohon isi Jam Selesai'],
                overtime_remarks: '',
                overtime_remarks_rules: [v => !!v || 'Mohon masukkan alasan lembur'],
                overtime_vehicle: 'P'
            },
            modal: {
                overtime_date: false,
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
         // Check If Back Button Press, Modal Close
         beforeRouteLeave (to, from, next) {
            if (this.modal.overtime_date || this.modal.overtime_timein || this.modal.overtime_timeout) {
                this.modal.overtime_date = false
                this.modal.overtime_timein = false
                this.modal.overtime_timeout = false
                next(false)
            } else {
                next()
            }
        },
        computed: {
            overtime_date_value () {
                return this.form.overtime_date ? this.$moment(this.form.overtime_date).format('LL') : ''
            }
        },
        methods: {
            GoTo (url) {
               this.$router.push(url)
            },
            Confirm (flag) {
                if (flag === 'add') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Proses'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                this.confirm.ok = 'Loading...'
                if (this.url_type === 'add' || this.url_type === 'edit') {
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  overtime_date: this.form.overtime_date,
                  overtime_timein: this.form.overtime_timein,
                  overtime_timeout: this.form.overtime_timeout,
                  overtime_remarks: this.form.overtime_remarks,
                  overtime_vehicle: this.form.overtime_vehicle,
                  overtime_usersid: this.$functions.UsersID(),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteOvertime'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.SnackBar(true, 'success', 'Proses Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                            let encodeurl = this.$functions.EncodeUrl(feedback[0].feedback_id)
                            this.$router.push({name: 'employeeovertimedetail',params:{id:encodeurl}})
                        } else {
                            if (feedback[0].feedback === 'Please check absence Today') {
                                this.SnackBar(true, 'error', 'Mohon cek absen hari ini, pengajuan lembur tidak bisa jika sudah absen pulang atau belum absen datang', 0)
                            } else {
                                this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            }
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            GetData (flag, value) {
                switch (flag) {
                	case 'overtime_date':
                		this.$refs.dialog_overtime_date.save(value)
                        this.overtime_date = this.$moment(value).format('YYYY-MM-DD')
                    break
                }
            },
            NextStep(id) {
                if (id === 1) {
                    if (this.$refs.form_overtime_data.validate()) {
                    	if (this.form.overtime_timeout < this.form.overtime_timein) {
                    		this.snackbar.dialog = true
                            this.snackbar.text = 'Jam selesai tidak bisa kurang dari Jam mulai'
                    	} else {
                        	this.steppers = 2
                    	}
                    }
                }
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>