<template>
    <div>
    <app-toolbarcustom :app_title="title" app_menu="employeefamilylist"></app-toolbarcustom>
    <v-card
    class="mt-15"
    outlined
    >
    <v-progress-linear
    indeterminate
    color="fourth"
    rounded
    v-if="loading.page"
    ></v-progress-linear>
    <v-card-title class="body-2 font-weight-bold">Data Keluarga</v-card-title>
    <v-card-text class="caption">
            <v-row align="center" class="mb-2">
                <v-col cols="6">Nama</v-col><v-col cols="6" class="font-weight-bold">{{ form.usersfamily_name }}</v-col>
                <v-col cols="6" class="mt-n5">Tempat, Tanggal Lahir</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.usersfamily_birthplace }}, {{$functions.FormatDateIndo(form.usersfamily_birthdate)}}</v-col>
                <v-col cols="6" class="mt-n5">Usia</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.Ages(form.usersfamily_birthdate) }} Tahun</v-col>
                <v-col cols="6" class="mt-n5">Jenis Kelamin</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.usersfamily_gender }}</v-col>
                <v-col cols="6" class="mt-n5">Gol. Darah</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.usersfamily_bloodtype }}</v-col>
                <v-col cols="6" class="mt-n5"></v-col><v-col cols="6" class="mt-n5 font-weight-bold"><v-chip
                    class="ma-2"
                    color="primary"
                    label
                    small
                    outlined
                  >
                  {{ form.usersfamily_statusdesc }}
                  </v-chip></v-col>
            </v-row>
    <v-btn color="primary" @click="Confirm('approve')" block class="mb-2" v-if="form.usersfamily_status === 'R'">Setuju</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.usersfamily_status === 'R'">Tolak</v-btn>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-title class="body-2 font-weight-bold">Timeline</v-card-title>
    <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              color="green"
              small
            >
              <div>
                <div class="caption">
                    created:<strong> {{ form.usersfamily_cuser }}</strong> &bull; {{ $functions.FormatDateTime(form.usersfamily_cdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              :color="$functions.FormatDateTime(form.usersfamily_hrdate)?'green':'yellow'"
              small
              v-if="form.usersfamily_hruser"
            >
              <div>
                <div class="caption">
                    hr:<strong> {{ form.usersfamily_hruser }}</strong> &bull; {{ $functions.FormatDateTime(form.usersfamily_hrdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              color="red"
              small
              v-if="form.usersfamily_rejectuser"
            >
              <div>
                <div class="caption">
                    reject:<strong> {{ form.usersfamily_rejectuser }}</strong>&bull;{{ $functions.FormatDateTime(form.usersfamily_rejectdate) }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
    </v-card>
    <v-alert
    border="left"
    colored-border
    color="secondary"
    elevation="2"
    v-if="form.usersfamily_notes"
    >
    <div v-html="form.usersfamily_notes"></div>
    </v-alert>
    <v-dialog v-model="confirm.dialog" max-width="490" persistent>
        <v-card>
            <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
            <v-card-text>
                {{confirm.text}}
                <v-text-field 
                    v-model="confirm.notes"
                    prepend-inner-icon="notes"
                    placeholder="Catatan"
                    label="Catatan"
                    required
                    solo-inverted
                    outlined
                    class="mt-3"
                    id="confirmnotes"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <app-snackbar 
        :dialog="snackbar.dialog"
        :color="snackbar.color"
        :text="snackbar.text"
        :timeout="snackbar.timeout"
        @close="snackbar.dialog = false"
    ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            title: '',
            loading: {
                page: false
            },
            form: {
                usersfamily_type: '',
                usersfamily_name: '',
                usersfamily_birthplace: '',
                usersfamily_birthdate: '',
                usersfamily_gender: '',
                usersfamily_bloodtype: '',
                usersfamily_status: '',
                usersfamily_statusdesc: '',
                usersfamily_hrdate: '',
                usersfamily_hruser: '',
                usersfamily_rejectuser: '',
                usersfamily_rejectdate: '',
                usersfamily_notes: '',
                usersfamily_cuser: '',
                usersfamily_cdate: '',
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
                notes: '',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            },
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                this.loading.page = true
                let decode = this.$functions.DecodeUrl(this.$route.params.id)
                let formdata = {
                  usersfamily_type: decode,
                  usersfamily_usersid: this.$functions.UsersID(),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersFamily'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.title = feedback[0].usersfamily_category
                            this.form.usersfamily_type = feedback[0].usersfamily_type
                            this.form.usersfamily_name = feedback[0].usersfamily_name
                            this.form.usersfamily_birthplace = feedback[0].usersfamily_birthplace
                            this.form.usersfamily_birthdate = feedback[0].usersfamily_birthdate
                            this.form.usersfamily_gender = feedback[0].usersfamily_gender
                            this.form.usersfamily_bloodtype = feedback[0].usersfamily_bloodtype
                            this.form.usersfamily_status = feedback[0].usersfamily_status
                            this.form.usersfamily_statusdesc = feedback[0].usersfamily_statusdesc
                            this.form.usersfamily_cuser = feedback[0].usersfamily_cuser
                            this.form.usersfamily_cdate = feedback[0].usersfamily_cdate
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            Confirm (flag) {
                if (flag === 'approve') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Setuju'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                } else if (flag === 'reject') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Tolak'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                if (this.confirm.notes === '') {
                    this.SnackBar(true, 'success', 'Mohon isi catatan', 3000)
                    setTimeout(function(){
                    document.getElementById('confirmnotes').focus()
                    }, 500)
                } else {
                    this.confirm.ok = 'Loading...'
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  usersfamily_type: this.form.usersfamily_type,
                  usersfamily_notes: this.confirm.notes,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteOvertime'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            let flag = ''
                            if (this.url_type === 'approve') {
                                flag = 'Setuju'
                                this.form.usersfamily_status = 'L'
                                this.form.usersfamily_statusdesc = 'UnApprove HR'
                            } else if (this.url_type === 'approve') {
                                flag = 'Tolak'
                                this.form.usersfamily_status = 'J'
                                this.form.usersfamily_statusdesc = 'Reject'
                            }
                            this.SnackBar(true, 'success', flag+' Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>