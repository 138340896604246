<template>
    <div>
    <app-toolbarcustom app_title="Data Keluarga" app_menu="employeefamily"></app-toolbarcustom>
        <div class="mb-15"></div>
        <v-progress-linear
        indeterminate
        color="fourth"
        rounded
        v-if="loading.page"
        ></v-progress-linear>
        <v-card
            class="mx-auto mb-2"
            max-width="344"
            outlined
            v-for="row in posts"
            :key="row.usersfamily_type"
            @click="GoTo(row.usersfamily_type)"
        >
            <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                    <v-icon>mdi-account</v-icon>
                    <span class="ml-2">{{row.usersfamily_name}}</span>
                  </v-list-item-title>
                <v-list-item-subtitle class="d-flex align-center">
                    <v-icon>cake</v-icon>
                    <span class="ml-2">{{row.usersfamily_birthplace}}, {{$functions.FormatDateIndo(row.usersfamily_birthdate)}}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="d-flex align-center">
                    <v-icon>family_restroom</v-icon>
                    <span class="ml-2">{{row.usersfamily_category}}</span>
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                size="80"
                :color="row.usersfamily_gender === 'MALE'?'blue':'pink'"
            >
            <v-icon v-if="row.usersfamily_gender === 'MALE'" x-large color="white">man</v-icon><v-icon v-else x-large color="white">woman</v-icon>
            </v-list-item-avatar>
            </v-list-item>

            <v-card-actions class="d-flex align-center">
            <v-icon>bloodtype</v-icon> {{ row.usersfamily_bloodtype }}, Usia: {{$functions.Ages(row.usersfamily_birthdate)}} Tahun<v-spacer></v-spacer><small><v-chip
                class="ma-2"
                color="primary"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                <span>{{ row.usersfamily_statusdesc }}</span>
              </v-chip></small>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            posts:[],
            loading: {
                page: false
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                this.loading.page = true
                let formdata = {
                  usersfamily_usersid: this.$functions.UsersID(),
                  order_by: 'usersfamily_birthdate',
                  order_type: 'ASC'
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersFamily'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            GoTo (id) {
                this.$router.push({name: 'employeefamilydetail',params:{id:this.$functions.EncodeUrl(id)}})
            },
            Loading () {
                this.confirm.ok = 'Loading...'
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>