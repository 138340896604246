<template>
  <v-container :class="$vuetify.breakpoint.xs?'bg-mobile':'bg'">
      <v-row align="center" justify="center">
          <v-col cols="12" sm="10">
            <v-card :class="$vuetify.breakpoint.xs?'elevation-0 mt-10':'elevation-0 mt-10 transparent'">
             <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-col cols="12" md="12" class="mb-n10" v-if="$vuetify.breakpoint.xs">
                      <div style="text-align: center;" class="mt-5"><img src="../../public/logo.jpg" width="37%"></div>
                    </v-col>
                    <v-col cols="12" md="6" v-if="$vuetify.breakpoint.xs === false">
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card-text :class="$vuetify.breakpoint.xs?'':'mt-15 white'">
                        <div style="text-align: center;" class="mt-5" v-if="$vuetify.breakpoint.xs === false"><img src="../../public/logo.jpg" width="37%"></div>
                        <h4
                          class="text-center mb-n10"
                        >{{app_title}}</h4>
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8">
                           
                          <v-text-field
                            v-model="form.usersid"
                            label="ID"
                            outlined
                            dense
                            color="primary"
                            autocomplete="false"
                            class="mt-16"
                            id="form_usersid"
                          />
                          <v-text-field
                            v-model="form.userspassword"
                            label="Password"
                            outlined
                            dense
                            color="primary"
                            autocomplete="false"
                            :type="form.userspassword_hint?'text':'password'"
                            :append-icon="form.userspassword_hint?'visibility_off':'visibility'"
                            @click:append="form.userspassword_hint =!form.userspassword_hint"
                            @keyup.enter="ProcessLogin"
                          />
                            <!-- <v-row>
                              <v-col cols="12" sm="5">
                                    <span class="caption primary--text">Lupa Password</span>
                              </v-col>
                            </v-row> -->
                          <v-btn color="primary" rounded block @click="ProcessLogin" :disabled="form.login !== 'Login'?true:false">{{form.login}}</v-btn>
                          </v-col>
                        </v-row>  
                        <!-- <div class="text-center">made by love <v-icon color="red" small>favorite</v-icon><br><img src="../../public/logo.jpg" height="30"></div> -->
                        <div class="mt-2 text-center caption">Copyright &copy; PT. Focus</div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
      </v-row>
      <app-changepassword :dialog="dialog.password" :usersid="form.usersid" @close="dialog.password = false"></app-changepassword>
      <app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
  </v-container>
</template>

<script>
  export default {
   data: () => ({
    app_title: 'Login in ke Akun Anda',
    step: 1,
    form: {
        usersid: '',
        userspassword: '',
        userspassword_hint: false,
        login: 'Login'
    },
    dialog: {
        password: false,
        text: 'Ok'
    },
    snackbar: {
        dialog: false,
        color: "primary",
        text: '',
        timeout: 3000,
    },
  }),
  created() {
    setTimeout(function(){
        document.getElementById('form_usersid').focus()
    }, 1000)
  },
  methods: {
    ProcessLogin() {
        this.form.login = 'Loading...'
        this.app_title = 'Loading...'
        let formdata = {
            url_type: 'login',
            users_id: this.form.usersid,
            users_password: this.form.userspassword,
            flag: 'mobileapp'
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWriteUsers'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === 'Y') {
                    this.UsersDetail(this.form.usersid,feedback[0].session_time)
                } else {
                    if (feedback[0].feedback === 'Expiry Password') {
                      this.SnackBar(true,'error','Password Expired, silahkan dirubah',3000)
                      this.dialog.password = true
                      setTimeout(function(){
                        document.getElementById('old_password').focus()
                    }, 1000)
                    } else {
                      this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            }
            this.form.login = 'Login'
            this.app_title = 'Login in ke Akun Anda'
        })
        .catch(e => {
            this.SnackBar(true,'error',e,3000)
            this.form.login = 'Login'
        })
    },
    UsersDetail (id,time) {
        localStorage.setItem('local_tim_users', id)
        let formdata = {
            users_id: id,
            flag: 'mobileapp'
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListUsers'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === 'Y') {
                    localStorage.setItem('local_tim_session_timeout', time)
                    localStorage.setItem('local_tim_login', 'Y')
                    localStorage.setItem('local_tim_usersdetail', JSON.stringify(feedback))
                    this.SnackBar(true, 'success', 'Login is Successfully', 0)
                    setTimeout(function(){
                      window.location.href = '/'
                    }, 1000)
                } else {
                    this.SnackBar(true,'error',feedback[0].feedback,3000)
                }
            }
            this.app_title = 'Login in ke Akun Anda'
        })
        .catch(e => {
            this.SnackBar(true,'error',e,3000)
        })
    },
    SnackBar(dialog, color, text, timeout) {
        this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout,
        }
        this.dialog.password 	= false
        this.dialog.text 		= "Ok"
    },
  },
  props: {
    source: String
  }     
  }
</script>
<style>
.bg {
  /* The image used */
  background-image: url("../../public/img/background-login.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-mobile {
  /* The image used */
  background-image: url("../../public/img/wallpaper-hp.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>