<template>
    <div>
    <app-toolbarcustom app_title="Bantuan" app_back="Y"></app-toolbarcustom>
    <v-card>
        <v-list three-line>
            <v-subheader></v-subheader>
            <v-list-item
              v-for="item in posts"
              :key="item.icon"
              @click="GoTo(item.internal, item.url)"
            >
              <v-list-item-avatar>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{item.text}}</v-list-item-title>
                <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            posts: [
                {icon: 'help', text: 'FAQ', description: 'Pertanyaan yang sering diajukan', internal:'Y', url:'/help/faq'},
                {icon: 'call', text: 'Telepon', description: 'Hubungi kami melalui HP anda', internal:'N', url:'tel:628111971189'},
                {icon: 'chat_bubble', text: 'Chat', description: 'Hubungi kami melalui chat', internal:'N', url:'https://api.whatsapp.com/send?phone=628111971189&text=Assalamualaikum%20wr%20%20wb%0ANama%20%3A'},
            ]
        }),
        methods: {
            GoTo (internal, url) {
                if (internal === 'Y') {
                    this.$router.push(url)
                } else {
                    window.location.href = url
                }
            }
        }
    }
</script>