import Vue from 'vue'
import Router from 'vue-router'
import BottomNavigation from '@/components/com_bottom_default.vue'
import ModDashboard from '../views/modul/mod_dashboard/dashboard'
import ModAboutUs from '../views/modul/mod_aboutus/aboutus'
import ModAccount from '../views/modul/mod_account/account'
//Modul Help
import ModHelp from '../views/modul/mod_help/help.vue'
//Modul Help
import ModHelpFAQ from '../views/modul/mod_help/help_faq.vue'
//Modul Policy
import ModPolicy from '../views/modul/mod_policy/policy.vue'
//Modul PolicyPA
import ModPolicyPA from '../views/modul/mod_policy/policy_pa.vue'
//Modul Sharia
import ModSharia from '../views/modul/mod_sharia/sharia.vue'
//Modul Sharia Doa
import ModShariaDoa from '../views/modul/mod_sharia/sharia_doa.vue'
//Modul Sharia Dzikir Pagi
import ModShariaDzikirPagi from '../views/modul/mod_sharia/sharia_dzikirpagi.vue'
//Modul Sharia Dzikir Sore
import ModShariaDzikirSore from '../views/modul/mod_sharia/sharia_dzikirsore.vue'
//Modul Sharia Asmaul Husna
import ModShariaAsmaulHusna from '../views/modul/mod_sharia/sharia_asmaulhusna.vue'
//Modul Sharia Jadwal Sholat
import ModShariaJadwalSholat from '../views/modul/mod_sharia/sharia_jadwalsholat.vue'
//Modul Sharia Quran
import ModShariaQuran from '../views/modul/mod_sharia/sharia_quran.vue'
//Modul Todo
import ModTodo from '../views/modul/mod_todo/todo.vue'
//Modul Employee
import ModEmployee from '../views/modul/mod_employee/employee.vue'

import ModOvertime from '../views/modul/mod_overtime/overtime.vue'
import ModOvertimeForm from '../views/modul/mod_overtime/overtime_form.vue'
import ModOvertimeList from '../views/modul/mod_overtime/overtime_list.vue'
import ModOvertimeDetail from '../views/modul/mod_overtime/overtime_detail.vue'

import ModLeave from '../views/modul/mod_leave/leave.vue'
import ModLeaveForm from '../views/modul/mod_leave/leave_form.vue'
import ModLeaveList from '../views/modul/mod_leave/leave_list.vue'
import ModLeaveDetail from '../views/modul/mod_leave/leave_detail.vue'

import ModMedical from '../views/modul/mod_medical/medical.vue'
import ModMedicalForm from '../views/modul/mod_medical/medical_form.vue'
import ModMedicalList from '../views/modul/mod_medical/medical_list.vue'
import ModMedicalDetail from '../views/modul/mod_medical/medical_detail.vue'

import ModFamily from '../views/modul/mod_family/family.vue'
import ModFamilyForm from '../views/modul/mod_family/family_form.vue'
import ModFamilyList from '../views/modul/mod_family/family_list.vue'
import ModFamilyDetail from '../views/modul/mod_family/family_detail.vue'

import ModPending from '../views/modul/mod_pending/pending.vue'
import ModActivity from '../views/modul/mod_activity/activity.vue'
import ModOrganization from '../views/modul/mod_organization/organization.vue'
import ModEmployeeList from '../views/modul/mod_employee/employee_list.vue'
import ModAbsence from '../views/modul/mod_absence/absence.vue'
import ModAbsenceList from '../views/modul/mod_absence/absence_list.vue'

import ModTransportation from '../views/modul/mod_transportation/transportation.vue'
import ModTransportationForm from '../views/modul/mod_transportation/transportation_form.vue'
import ModTransportationList from '../views/modul/mod_transportation/transportation_list.vue'
import ModTransportationDetail from '../views/modul/mod_transportation/transportation_detail.vue'

import ModEntertainment from '../views/modul/mod_entertainment/entertainment.vue'
import ModEntertainmentForm from '../views/modul/mod_entertainment/entertainment_form.vue'
import ModEntertainmentList from '../views/modul/mod_entertainment/entertainment_list.vue'
import ModEntertainmentDetail from '../views/modul/mod_entertainment/entertainment_detail.vue'
import ModReport from '../views/modul/mod_report/report.vue'
import ModReportEmployee from '../views/modul/mod_report/report_employee.vue'

Vue.use(Router)

export default new Router({
  // hashbang: false,
  // history: true,
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    //Modul Dashboard
    {
      path: '/',
      name: 'home',
      components: {
        default: ModDashboard,
        bottomnavigation: BottomNavigation
      }
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      components: {
        default: ModAboutUs,
        bottomnavigation: BottomNavigation
      }
    },
    {
      path: '/account',
      name: 'account',
      components: {
        default: ModAccount,
        bottomnavigation: BottomNavigation
      }
    },
     //Modul Help
    {
      path: '/help',
      name: 'help',
      components: {
        default: ModHelp,
        bottomnavigation: false
      }
    },
    //Modul Help FAQ
    {
      path: '/help/faq',
      name: 'helpfaq',
      components: {
        default: ModHelpFAQ,
        bottomnavigation: false
      }
    },
    //Modul Policy
    {
      path: '/policy',
      name: 'policy',
      components: {
        default: ModPolicy,
        bottomnavigation: false
      }
    },
    //Modul Policy PA
    {
      path: '/policy/pa',
      name: 'policypa',
      components: {
        default: ModPolicyPA,
        bottomnavigation: false
      }
    },
    //Modul Sharia
    {
      path: '/sharia',
      name: 'sharia',
      components: {
        default: ModSharia,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Doa
    {
      path: '/sharia/doa',
      name: 'shariadoa',
      components: {
        default: ModShariaDoa,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Dzikir Pagi
    {
      path: '/sharia/dzikirpagi',
      name: 'dzikirpagi',
      components: {
        default: ModShariaDzikirPagi,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Dzikir Sore
    {
      path: '/sharia/dzikirsore',
      name: 'dzikirsore',
      components: {
        default: ModShariaDzikirSore,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Asmaul Husna
    {
      path: '/sharia/asmaulhusna',
      name: 'asmaulhusna',
      components: {
        default: ModShariaAsmaulHusna,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Jadwal Sholat
    {
      path: '/sharia/jadwalsholat',
      name: 'jadwalsholat',
      components: {
        default: ModShariaJadwalSholat,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Quran
    {
      path: '/sharia/quran',
      name: 'quran',
      components: {
        default: ModShariaQuran,
        bottomnavigation: false
      }
    },
    //Modul Todo
    {
      path: '/todo',
      name: 'todo',
      components: {
        default: ModTodo,
        bottomnavigation: false
      }
    },
    //Modul Employee
    {
      path: '/employee',
      name: 'employee',
      components: {
        default: ModEmployee,
        bottomnavigation: false
      }
    },
    //Modul Employee Overtime
    {
      path: '/employee/overtime',
      name: 'employeeovertime',
      components: {
        default: ModOvertime,
        bottomnavigation: false
      }
    },
    //Modul Employee Overtime Form
    {
      path: '/employee/overtime/form',
      name: 'employeeovertimeform',
      components: {
        default: ModOvertimeForm,
        bottomnavigation: false
      }
    },
    //Modul Employee Overtime Form
    {
      path: '/employee/overtime/list',
      name: 'employeeovertimelist',
      components: {
        default: ModOvertimeList,
        bottomnavigation: false
      }
    },
    //Modul Employee Overtime Form
    {
      path: '/employee/overtime/detail/:id',
      name: 'employeeovertimedetail',
      components: {
        default: ModOvertimeDetail,
        bottomnavigation: false
      }
    },
    //Modul Employee Leave
    {
      path: '/employee/leave',
      name: 'employeeleave',
      components: {
        default: ModLeave,
        bottomnavigation: false
      }
    },
    //Modul Employee Leave Form
    {
      path: '/employee/leave/form',
      name: 'employeeleaveform',
      components: {
        default: ModLeaveForm,
        bottomnavigation: false
      }
    },
    //Modul Employee Overtime Form
    {
      path: '/employee/leave/list',
      name: 'employeeleavelist',
      components: {
        default: ModLeaveList,
        bottomnavigation: false
      }
    },
    //Modul Employee Leave Form
    {
      path: '/employee/leave/detail/:id',
      name: 'employeeleavedetail',
      components: {
        default: ModLeaveDetail,
        bottomnavigation: false
      }
    },
    //Modul Pending
    {
      path: '/employee/pending',
      name: 'employeepending',
      components: {
        default: ModPending,
        bottomnavigation: false
      }
    },
    //Modul Pending
    {
      path: '/activity',
      name: 'activity',
      components: {
        default: ModActivity,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Pending
    {
      path: '/employee/organization',
      name: 'employeeorganization',
      components: {
        default: ModOrganization,
        bottomnavigation: false
      }
    },
    //Modul Pending
    {
      path: '/employee/list',
      name: 'employeelist',
      components: {
        default: ModEmployeeList,
        bottomnavigation: false
      }
    },
     //Modul Employee Absence
     {
      path: '/employee/absence',
      name: 'employeeabsence',
      components: {
        default: ModAbsence,
        bottomnavigation: false
      }
    },
    //Modul Employee Absence List
    {
      path: '/employee/absence/list',
      name: 'employeeabsencelist',
      components: {
        default: ModAbsenceList,
        bottomnavigation: false
      }
    },
    //Modul Employee Medical
    {
      path: '/employee/medical',
      name: 'employeemedical',
      components: {
        default: ModMedical,
        bottomnavigation: false
      }
    },
    //Modul Employee Medical Form
    {
      path: '/employee/medical/form',
      name: 'employeemedicalform',
      components: {
        default: ModMedicalForm,
        bottomnavigation: false
      }
    },
    //Modul Employee Overtime Form
    {
      path: '/employee/medical/list',
      name: 'employeemedicallist',
      components: {
        default: ModMedicalList,
        bottomnavigation: false
      }
    },
    //Modul Employee Medical Form
    {
      path: '/employee/medical/detail/:id',
      name: 'employeemedicaldetail',
      components: {
        default: ModMedicalDetail,
        bottomnavigation: false
      }
    },
    //Modul Employee family
    {
      path: '/employee/family',
      name: 'employeefamily',
      components: {
        default: ModFamily,
        bottomnavigation: false
      }
    },
    //Modul Employee family Form
    {
      path: '/employee/family/form',
      name: 'employeefamilyform',
      components: {
        default: ModFamilyForm,
        bottomnavigation: false
      }
    },
    //Modul Employee Overtime Form
    {
      path: '/employee/family/list',
      name: 'employeefamilylist',
      components: {
        default: ModFamilyList,
        bottomnavigation: false
      }
    },
    //Modul Employee family Form
    {
      path: '/employee/family/detail/:id',
      name: 'employeefamilydetail',
      components: {
        default: ModFamilyDetail,
        bottomnavigation: false
      }
    },
  //Modul Employee Transportation
  {
    path: '/employee/transportation',
    name: 'employeetransportation',
    components: {
      default: ModTransportation,
      bottomnavigation: false
    }
  },
  //Modul Employee Transportation Form
  {
    path: '/employee/transportation/form',
    name: 'employeetransportationform',
    components: {
      default: ModTransportationForm,
      bottomnavigation: false
    }
  },
  //Modul Employee Overtime Form
  {
    path: '/employee/transportation/list',
    name: 'employeetransportationlist',
    components: {
      default: ModTransportationList,
      bottomnavigation: false
    }
  },
  //Modul Employee Transportation Form
  {
    path: '/employee/transportation/detail/:id',
    name: 'employeetransportationdetail',
    components: {
      default: ModTransportationDetail,
      bottomnavigation: false
    }
  },
   //Modul Todo
  {
    path: '/todoform/:id',
    name: 'todoform',
    components: {
      default: ModTodo,
      bottomnavigation: false
    }
  },
  {
    path: '/employee/transportation/formtodo/:id',
    name: 'employeetransportationformtodo',
    components: {
      default: ModTransportationForm,
      bottomnavigation: false
    }
  },
  //Modul Employee entertainment
  {
    path: '/employee/entertainment',
    name: 'employeeentertainment',
    components: {
      default: ModEntertainment,
      bottomnavigation: false
    }
  },
  //Modul Employee entertainment Form
  {
    path: '/employee/entertainment/form',
    name: 'employeeentertainmentform',
    components: {
      default: ModEntertainmentForm,
      bottomnavigation: false
    }
  },
  //Modul Employee Overtime Form
  {
    path: '/employee/entertainment/list',
    name: 'employeeentertainmentlist',
    components: {
      default: ModEntertainmentList,
      bottomnavigation: false
    }
  },
  //Modul Employee entertainment Form
  {
    path: '/employee/entertainment/detail/:id',
    name: 'employeeentertainmentdetail',
    components: {
      default: ModEntertainmentDetail,
      bottomnavigation: false
    }
  },
  //Modul Employee Report Form
  {
    path: '/report',
    name: 'report',
    components: {
      default: ModReport,
      bottomnavigation: false
    }
  },
  {
    path: '/report/employee',
    name: 'reportemployee',
    components: {
      default: ModReportEmployee,
      bottomnavigation: false
    }
  },
  ]
})
