<template>
	<div id="account">
		<app-toolbarcustom app_title="Akun" app_menu="home"></app-toolbarcustom>
		<v-card flat>
            <v-list three-line>
            	<v-subheader></v-subheader>
		        <v-list-item
		        >
		          <v-list-item-content>
		            <v-list-item-title>{{data.users_name}}</v-list-item-title>
		            <v-list-item-subtitle>{{data.users_email}}</v-list-item-subtitle>
		            <v-list-item-subtitle>{{data.users_title}}</v-list-item-subtitle>
		            <v-list-item-subtitle><v-icon>assignment_ind</v-icon><small class="font-weight-bold primary--text">{{data.users_employeeid}} </small><v-icon>smartphone</v-icon><small class="font-weight-bold primary--text">{{data.users_mobile_1}}</small></v-list-item-subtitle>
		          </v-list-item-content>
		          <v-list-item-action>
		             <v-avatar
                      size="75"
                      color="grey lighten-4"
                    >
                      <img :src="data.users_avatar_url" :alt="data.users_name">
                    </v-avatar>
                    <small class="fourth--text">Join: {{$functions.FormatDateFull(data.users_entrydate)}}</small>
		          </v-list-item-action>
		        </v-list-item>
				<v-subheader>
					<v-btn fab color="primary" class="elevation-0" small @click="OpenIDCard" title="Kartu Nama Digital"><v-icon>qr_code</v-icon></v-btn>
					<v-btn fab color="secondary" class="elevation-0 mx-2" small @click="OpenPassword" title="Rubah Password"><v-icon>lock</v-icon></v-btn>
					<v-btn fab color="fourth" class="elevation-0 white--text" small @click="OpenURL('report')" title="Rubah Password" v-if="access.read === 1"><v-icon>analytics</v-icon></v-btn>
				</v-subheader>
		    </v-list>
		</v-card>
		<v-divider></v-divider>
		<v-card class="mt-3 mb-10" flat>
            <v-list two-line>
				<template v-for="(row, index) in category">
					<v-list-item
					:key="row.id"
					>
					<v-list-item-avatar v-if="row.id === 'version'">
							<v-btn @click="Refresh()" fab color="primary" small><v-icon>{{icon.refresh}}</v-icon></v-btn>
					</v-list-item-avatar>
					<v-list-item-avatar v-else-if="row.id === 'logout'">
						<v-btn @click="LogOut()" fab color="primary" small><v-icon>{{row.icon}}</v-icon></v-btn>
				    </v-list-item-avatar>
					<v-list-item-avatar v-else>
						<v-icon>{{row.icon}}</v-icon>
					</v-list-item-avatar>
					<v-list-item-content v-if="row.id === 'absence'">
						<v-list-item-title v-if="absence.timein !== '00:00' && absence.timeout ==='00:00'" @click="TimeOutCheck('timeout')">IN:({{absence.timein}}) - Absen Pulang</v-list-item-title>
						<v-list-item-title v-else-if="absence.timein === '' && absence.timeout === ''" @click="OpenAbsence('timein')">Absen Datang</v-list-item-title>
						<v-list-item-title v-else>IN: {{absence.timein}} OUT: {{absence.timeout}}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-content v-else-if="row.id === 'version'">
						<v-list-item-title>Versi {{$functions.AppVersion()}}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-content v-else-if="row.id === 'logout'" @click="LogOut()">
						<v-list-item-title>{{text.logout}}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-content v-else @click="OpenURL('report')">
						<v-list-item-title>{{row.title}}</v-list-item-title>
					</v-list-item-content>
					</v-list-item>
					 <v-divider
						v-if="index < category.length - 1"
						:key="`${index}-divider`"
					></v-divider>
				</template>
		    </v-list>
		</v-card>
		<v-dialog v-model="confirm.dialog" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="WriteTodo('event')">{{confirm.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
		<v-dialog v-model="dialog.absence.modal" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text">{{dialog.absence.title}}</v-card-title>
                <v-card-text class="mt-3">
                    {{dialog.absence.location}}
					<video ref="video" autoplay playsinline width="100%" height="auto" v-if="setting.cameraselfie === 'Y'"></video>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="CloseAbsence">{{dialog.absence.no}}</v-btn>
                    <v-btn id="dialog_absence_true" color="primary" outlined :disabled = "dialog.absence.ok !== 'Absen' ? true:false" @click="EnterAbsence">{{dialog.absence.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
		<v-dialog v-model="dialog.timeout.modal" max-width="490">
            <v-card>
                <v-card-title class="red headline white--text">Konfirmasi<br>Absen Pulang?</v-card-title>
                <v-card-text class="mt-3">
					
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="dialog.timeout.modal = false">{{dialog.timeout.no}}</v-btn>
                    <v-btn id="dialog_timeout_true" color="primary" outlined :disabled = "dialog.timeout.ok !== 'Ok' ? true:false" @click="OpenAbsence('timeout')">{{dialog.timeout.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
		<v-dialog v-model="dialog.idcard" max-width="380">
			<v-card>
			<v-card-text>
				<div :class="$vuetify.breakpoint.smAndUp?'mx-2 text-center':'text-center'">
				<img :src="require('../../../assets/logo.png')" height="65">
					<vuecard 
					:orgPost="qrcode.zipcode" 
					:orgStreet="qrcode.address" 
					:orgRegion="qrcode.area" 
					orgCity="" 
					:orgCountry="qrcode.country" 
					:orgName="qrcode.company"
					:firstName="qrcode.name" 
					lastName="" 
					:workPhone="qrcode.phone"  
					:homePhone="qrcode.phone" 
					:size="qrcode.size"
					:workEmail ="qrcode.email"
					:orgTitle="qrcode.comptitle"
					showQR
					:style="$vuetify.breakpoint.smAndUp?'':'margin-left:-2px'"
					/>
				</div>
			</v-card-text>
			<v-card-actions><v-btn block text small @click="dialog.idcard=false" class="primary--text">Kartu Nama Digital</v-btn></v-card-actions>
		</v-card>
		</v-dialog>
		<app-changepassword :dialog="dialog.password" :usersid="$functions.UsersID()" @close="dialog.password = false"></app-changepassword>
		<app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
	</div>
</template>
<script>
import vuecard from 'vue-vcard'
export default {
    data: () => ({
    	data: {
			users_id: '',
			users_name: '',
			users_email: '',
			users_title: '',
			users_avatar_url: '',
			users_employeeid: '',
			users_mobile_1: '',
			users_entrydate: '',
			users_stream: null,
			users_photo: null,
			users_company: ''
		},
		access: {
			read: 0
		},
		absence: {
			url: '',
			timein: '',
			timeout: '',
		},
		text: {
			logout: 'Logout'
		},
    	category: [],
		dialog: {
			idcard: false,
			password: false,
			absence: {
				modal: false,
				title: '',
				text: '',
				ok: 'Absen',
				no: 'Batal',
				location: '',
			},
			timeout: {
				modal: false,
				title: '',
				text: '',
				ok: 'Ok',
				no: 'Batal',
				location: '',
			},
			
		},
		icon: {
		  refresh: 'refresh',
		},
		qrcode: {
            size: 300,
            comptitle: '',
            phone: '',
            company: '',
            branch: '',
            email: '',
            country: '',
            address: '',
			area: '',
			zipcode: ''
        },
		setting: {
			geolocation: '',
			cameraselfie: '',
		},
		confirm: {
			dialog: false,
			title: '',
			text: '',
			ok: 'OK',
			close: 'Tutup',
		},
		snackbar: {
			dialog: false,
			color: "primary",
			text: "",
			timeout: 3000,
		},
    }),
    created () {
    	this.Initial()
    },
    methods: {
		Access () {
			let modul = 'myreport'
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				flag: 'mobileapp',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let priv = response.data
				if (priv.length > 0) {
					if (priv[0].usersmenu_read === 1) {
						this.access.read = priv[0].usersmenu_read
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
			})
		},
    	Initial () {
			let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
			this.data.users_name = usersdetail[0].users_name
			this.data.users_email = usersdetail[0].users_email
			this.data.users_title = usersdetail[0].users_title
			this.data.users_avatar_url = usersdetail[0].users_avatar_url
			this.data.users_employeeid = usersdetail[0].users_employeeid
			this.data.users_mobile_1 = usersdetail[0].users_mobile_1
			this.data.users_entrydate = usersdetail[0].users_entrydate
			this.data.users_company = usersdetail[0].users_company
			this.qrcode.company = this.$vars.V('txt_footer')
			this.qrcode.name = usersdetail[0].users_name
            this.qrcode.phone = usersdetail[0].users_mobile_1
            this.qrcode.branch = usersdetail[0].users_branch_name
            this.qrcode.email = usersdetail[0].users_email
            this.qrcode.comptitle = usersdetail[0].users_title.replaceAll('&', 'AND')
            this.qrcode.country = this.$vars.V('txt_country')
            this.qrcode.address = this.$vars.V('txt_address')
            this.qrcode.area = this.$vars.V('txt_area')
            this.qrcode.zipcode = this.$vars.V('txt_zipcode')
			this.Access()
			this.Category()
			this.Absence()
    	},
    	Category () {
    		this.category = [
    			{
    				id: 'absence',
    				title: 'Absen',
    				icon: 'watch',
    			},
    			{
    				id: 'peraturan',
    				title: 'Peraturan Perusahaan',
    				icon: 'info',
    			},
				{
    				id: 'version',
    				title: 'Versi',
    				icon: 'verified',
    			},
				{
    				id: 'logout',
    				title: 'Logout',
    				icon: 'logout',
    			},
    		]
    	},
		CheckAddress (position) {
			this.dialog.absence.location = 'Loading...'
			let formdata = {
				latitudes: String(position.coords.latitude),
				longitudes: String(position.coords.longitude),
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiGetAddress"), param, {
				headers: {
					"Content-Type": "text/plain; charset=ISO-8859-1",
				},
				})
				.then((response) => {
				let feedback = response.data;
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.dialog.absence.location = feedback[0].address
					} else {
						this.dialog.absence.location = ''
						this.SnackBar(true, "error", feedback[0].feedback, 0)
					}
				} else {
					this.dialog.absence.location = ''
				}
				})
				.catch((e) => {
					this.dialog.absence.location = ''
					this.SnackBar(true, "error", e, 0)
				})
		},
		Absence () {
			let formdata = {
				absence_usersid: this.$functions.UsersID(),
				absence_date: this.$functions.Today(),
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListAbsence"), param, {
				headers: {
					"Content-Type": "text/plain; charset=ISO-8859-1",
				},
				})
				.then((response) => {
				let feedback = response.data;
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.absence.timein = feedback[0].absence_timein
						this.absence.timeout = feedback[0].absence_timeout
					} else {
						this.SnackBar(true, "error", feedback[0].feedback, 0)
					}
				}
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 0)
				})
		},
		CheckAbsenceSetting (flag) {
			if (flag === 'timeout') {
				this.dialog.timeout.ok = 'Loading...'
			}
			let formdata = {
				flag: 'mobileapp'
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiAbsenceSetting"), param, {
				headers: {
					"Content-Type": "text/plain; charset=ISO-8859-1",
				},
				})
				.then((response) => {
				let feedback = response.data;
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						if (feedback[0].setting_geolocation === 'Y') {
							const location = this.CheckLocation()
							if (location === false) {
								this.SnackBar(true, "error", 'Akses Lokasi belum diberikan', 2000)
							} else {
								if (feedback[0].setting_cameraselfie === 'Y') {
									const camera = this.CheckCamera()
									if (camera === false) {
										this.SnackBar(true, "error", 'Akses Kamera belum diberikan', 2000)
									} else {
										this.StartLocation(flag, feedback[0].setting_cameraselfie)
									}
								} else {
									this.StartLocation(flag, feedback[0].setting_cameraselfie)
								}
							}
						}
						this.setting.geolocation = feedback[0].setting_geolocation
						this.setting.cameraselfie = feedback[0].setting_cameraselfie
					} else {
						this.SnackBar(true, "error", feedback[0].feedback, 0)
					}
				}
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 0)
				})
		},
		// Fungsi untuk memeriksa izin akses lokasi
		async CheckLocation() {
			try {
				const status = await navigator.permissions.query({ name: "geolocation" })
				if (status.state === "prompt") {
					this.SnackBar(true, "error", 'Izin akses Lokasi: Silahkan di Allow',2500)
					return false;
				} else if (status.state === "denied") {
					this.SnackBar(true, "error", 'Izin akses Lokasi: Ditolak',2500)
					return false;
				}
			} catch (error) {
				console.error("Permissions API tidak mendukung lokasi:", error)
				return false;
			}
		},
		// Fungsi untuk memeriksa izin akses kamera
		async CheckCamera() {
			try {
				const status = await navigator.permissions.query({ name: "camera" })
				if (status.state === "prompt") {
					this.SnackBar(true, "error", 'Izin akses Kamera: Silahkan di Allow',2500)
					return false;
				} else if (status.state === "denied") {
					this.SnackBar(true, "error", 'Izin akses Kamera: Ditolak',2500)
					return false;
				}
			} catch (error) {
				this.SnackBar(true, "error", "Permissions API tidak mendukung kamera: "+error,2500)
				return false;
			}
		},
		StartLocation(flag, camera) {
			this.absence.url = flag
			this.dialog.absence.modal = true
			this.dialog.absence.ok = 'Loading...'
			if (flag === 'timein') {
				this.dialog.absence.title = 'Absen Datang'
			} else {
				this.dialog.absence.title = 'Absen Pulang'
				this.dialog.timeout.ok = 'Ok'
				this.dialog.timeout.modal = false
			}
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(this.CheckAddress)
			} else { 
				alert('Akses Location tidak disetujui oleh anda')
			}
			if (camera === 'Y') {
				this.StartCamera()
			} else {
				this.dialog.absence.ok = 'Absen'
			}
		},
		LogOut () {
			this.text.logout = 'Loading...'
			let formdata = {
				url_type: "logout",
				users_id: this.$functions.UsersID(),
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteUsers"), param, {
			headers: {
				"Content-Type": "text/plain; charset=ISO-8859-1",
			},
			})
			.then((response) => {
			let feedback = response.data;
			if (feedback.length > 0) {
				if (feedback[0].feedback === "Y") {
					setTimeout(function () {
						localStorage.removeItem("local_tim_users")
						localStorage.removeItem("local_tim_usersdetail")
						localStorage.removeItem("local_tim_login")
						window.location.href = "/"
					},2500)
				} else {
					this.SnackBar(true, "error", feedback[0].feedback, 0)
				}
			} else {
				this.SnackBar(true, "error", "Logout is Failed", 0)
			}
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
			})
		},
		TimeOutCheck(flag) {
			let currenttime = new Date()
			let hours = currenttime.getHours()
			if (flag === 'timeout' && hours < 18) {
				this.dialog.timeout.modal = true
			} else {
				this.OpenAbsence('timeout')
			}
		},
		async OpenAbsence (flag) {
			this.CheckAbsenceSetting(flag)
        },
		EnterAbsence () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.SaveAbsence)
            } else { 
                alert('Akses Location tidak disetujui oleh anda')
                this.dialog.absence.ok = 'Absen'
            }
        },
        SaveAbsence (position) {
			this.TakePhoto()
            let latitude = position.coords.latitude
            let longitude = position.coords.longitude
            if (latitude === '' && longitude === '') {
				alert('Mohon pastikan GPS anda hidup')
				this.dialog.absence.ok = 'Absen'
            } else if (this.data.users_photo === null) {
				alert('Mohon pastikan Kamera anda hidup')
			} else {
				this.dialog.absence.ok = 'Loading...'
				this.ProcessAbsence(latitude,longitude)
            }
        },
		ProcessAbsence (latitude, longitude) {
			this.dialog.absence.ok = 'Loading...'
			let photo = ''
			if (this.setting.cameraselfie === 'Y') {
				photo = this.data.users_photo.replace(/^.+?;base64,/, '')
			}
			let formdata = {
				url_type: this.absence.url,
				absence_usersid: this.$functions.UsersID(),
				absence_date: this.$functions.Today(),
				absence_latitude_in: String(latitude),
				absence_longitude_in: String(longitude),
				absence_geolocation_in: this.dialog.absence.location,
				absence_latitude_out: String(latitude),
				absence_longitude_out: String(longitude),
				absence_geolocation_out: this.dialog.absence.location,
				absence_photo: photo,
				absence_actived: 'Y'
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteAbsence"), param, {
			headers: {
				"Content-Type": "text/plain; charset=ISO-8859-1",
			},
			})
			.then((response) => {
			let feedback = response.data;
			if (feedback.length > 0) {
				if (feedback[0].feedback === "Y") {
					this.dialog.absence.modal = false
					this.dialog.absence.ok = 'Absen'
					this.Absence()
				} else {
					this.dialog.absence.ok = 'Absen'
					this.SnackBar(true, "error", feedback[0].feedback, 0)
				}
			} else {
				this.dialog.absence.ok = 'Absen'
				this.SnackBar(true, "error", "Logout is Failed", 0)
			}
			this.StopCamera()
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.StopCamera()
			})
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
		},
		async StartCamera() {
			try {
				// Mengakses kamera dengan WebRTC
				this.data.users_stream = await navigator.mediaDevices.getUserMedia({ video: true })
				this.$refs.video.srcObject = this.data.users_stream;
				this.dialog.absence.ok = 'Absen'
			} catch (error) {
				this.SnackBar(true, "error", "Kamera tidak dapat diakses:"+ error, 0)
			}
		},
		StopCamera() {
			this.dialog.absence.modal = false
			// Hentikan stream kamera
			if (this.data.users_stream) {
				this.data.users_stream.getTracks().forEach(track => track.stop())
				this.data.users_stream = null;
			}
		},
		TakePhoto() {
			// Ambil gambar dari video
			const video = this.$refs.video;
			const canvas = document.createElement("canvas")
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			const context = canvas.getContext("2d")
			context.drawImage(video, 0, 0, canvas.width, canvas.height)

			// Simpan gambar dalam format base64
			this.data.users_photo = canvas.toDataURL("image/png")
		},
		CloseAbsence() {
			this.dialog.absence.modal = false
			this.StopCamera()
		},
		OpenIDCard() {
			this.dialog.idcard = true
		},
		OpenPassword() {
			this.dialog.password = true
		},
		Refresh () {
			this.icon.refresh = 'hourglass_bottom'
			let formdata = {
				users_id: this.$functions.UsersID(),
				flag: 'mobileapp'
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsers'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
						localStorage.removeItem("local_tim_usersdetail")
						localStorage.setItem('local_tim_usersdetail', JSON.stringify(feedback))
						setTimeout(function(){
							location.reload()
						}, 3000)
					} else {
						this.SnackBar(true,'error',feedback[0].feedback,3000)
					}
				}
			})
			.catch(e => {
				this.SnackBar(true,'error',e,3000)
			})
		},
		OpenURL (url) {
			this.$router.push(url)
		}
	},
	beforeDestroy() {
		this.StopCamera()
	},
	components: {
        vuecard
    }
}
</script>
<style>
video {
	transform: scale(-1,1)
  }
  video::-webkit-media-controls-panel {
   transform: scale(-1,1)
	}
</style>