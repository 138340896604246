<template>
    <div>
    <app-toolbaravatar :app_title="title" :app_avatar="form.transportation_usersavatar"></app-toolbaravatar>
    <v-card
    class="mt-15"
    outlined
    >
    <v-progress-linear
    indeterminate
    color="fourth"
    rounded
    v-if="loading.page"
    ></v-progress-linear>
    <v-card-title class="body-2 font-weight-bold">Ringkasan Reimburse Transportasi</v-card-title>
    <v-card-text class="caption">
        <v-row align="center">
            <v-col cols="6">Todo</v-col><v-col cols="6" class="font-weight-bold">{{ form.transportation_tododesc }} ({{$functions.FormatDateIndoWithDayName(form.transportation_tododate)}})</v-col>
            <v-col cols="6" class="mt-n5">Periode Awal</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.transportation_sdate) }}</v-col>
            <v-col cols="6" class="mt-n5">Periode Akhir</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.transportation_edate) }}</v-col>
            <v-col cols="6" class="mt-n5">Tipe</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_typedesc }}</v-col>
            <v-col cols="6" class="mt-n5">Dari</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_departure }}</v-col>
            <v-col cols="6" class="mt-n5">Tujuan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_arrive }}</v-col>
            <v-col cols="6" class="mt-n5">Catatan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.transportation_remarks }}</v-col>
            <v-col cols="6" class="mt-n5" v-if="form.transportation_type === 'P'">Parkir</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold">{{ $functions.NewFormatNumber(form.transportation_parking) }}</v-col>
            <v-col cols="6" class="mt-n5" v-if="form.transportation_type === 'P'">Bensin</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold">{{ $functions.NewFormatNumber(form.transportation_fuel) }}</v-col>
            <v-col cols="6" class="mt-n5" v-if="form.transportation_type === 'P'">Tol</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold">{{ $functions.NewFormatNumber(form.transportation_toll) }}</v-col>
            <v-col cols="6" class="mt-n5 body-1 font-weight-bold">Total</v-col><v-col cols="6" class="mt-n5 text-right font-weight-bold green--text body-1">{{ $functions.NewFormatNumber(form.transportation_total) }}</v-col>
        </v-row>
    <v-divider></v-divider>
    <v-subheader class="body-2 font-weight-bold">Lampiran <v-spacer></v-spacer></v-subheader>
    <div v-if="$functions.CheckImage(form.transportation_attachmentpathurl)"><v-img :src="form.transportation_attachmentpathurl"></v-img></div>
    <div class="primary--text font-weight-black mb-4" @click="$functions.OpenUrl(form.transportation_attachmentpathurl)" v-else>Filename : {{form.transportation_attachmentpath}}</div>
    <v-btn color="primary" @click="Confirm('approve')" block class="mb-2" v-if="form.transportation_status === 'R' && form.transportation_leaderuser === $functions.UsersID()">Setuju</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.transportation_status === 'R' && form.transportation_leaderuser === $functions.UsersID()">Tolak</v-btn>
    <v-btn color="primary" @click="Confirm('hr')" block class="mb-2" v-if="form.transportation_status === 'L' && form.hr_user === $functions.UsersID()">Setuju HR</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.transportation_status === 'L' && form.hr_user === $functions.UsersID()">Tolak HR</v-btn>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-title class="body-2 font-weight-bold">Timeline</v-card-title>
    <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              color="green"
              small
            >
              <div>
                <div class="caption">
                    created:<strong> {{ form.transportation_cuser }}</strong> &bull; {{ $functions.FormatDateTime(form.transportation_cdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              :color="$functions.FormatDateTime(form.transportation_hrdate)?'green':'yellow'"
              small
              v-if="form.transportation_hruser"
            >
              <div>
                <div class="caption">
                    hr:<strong> {{ form.transportation_hruser }}</strong> &bull; {{ $functions.FormatDateTime(form.transportation_hrdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              color="red"
              small
              v-if="form.transportation_rejectuser"
            >
              <div>
                <div class="caption">
                    reject:<strong> {{ form.transportation_rejectuser }}</strong>&bull;{{ $functions.FormatDateTime(form.transportation_rejectdate) }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
    </v-card>
    <v-alert
    border="left"
    colored-border
    color="secondary"
    elevation="2"
    v-if="form.transportation_notes"
    >
    <div v-html="form.transportation_notes"></div>
    </v-alert>
    <v-dialog v-model="confirm.dialog" max-width="490" persistent>
        <v-card>
            <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
            <v-card-text>
                {{confirm.text}}
                <v-text-field 
                    v-model="confirm.notes"
                    prepend-inner-icon="notes"
                    placeholder="Catatan"
                    label="Catatan"
                    required
                    solo-inverted
                    outlined
                    class="mt-3"
                    id="confirmnotes"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <app-snackbar 
        :dialog="snackbar.dialog"
        :color="snackbar.color"
        :text="snackbar.text"
        :timeout="snackbar.timeout"
        @close="snackbar.dialog = false"
    ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            title: '',
            loading: {
                page: false
            },
            form: {
                transportation_id: '',
                transportation_todoid: '',
                transportation_tododesc: '',
                transportation_tododate: '',
                transportation_sdate: '',
                transportation_edate: '',
                transportation_usersid: '',
                transportation_usersname: '',
                transportation_usersavatar: '',
                transportation_type: '',
                transportation_typedesc: '',
                transportation_departure: '',
                transportation_arrive: '',
                transportation_status: '',
                transportation_statusdesc: '',
                transportation_remarks: '',
                transportation_parking: 0,
                transportation_fuel: 0,
                transportation_toll: 0,
                transportation_total: 0,
                transportation_attachmentpath: '',
                transportation_attachmentpathurl: '',
                transportation_notes: '',
                transportation_leaderuser: '',
                transportation_hrdate: '',
                transportation_hruser: '',
                transportation_rejectdate: '',
                transportation_rejectuser: '',
                transportation_cdate: '',
                transportation_cuser: '',
                hr_user: '',
                uploadinfo: '',
                uploadattach: '',
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
                notes: '',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            },
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                this.loading.page = true
                let decode = this.$functions.DecodeUrl(this.$route.params.id)
                let formdata = {
                  transportation_id: decode,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListTransportation'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.title = feedback[0].transportation_id
                            this.form.transportation_id = feedback[0].transportation_id
                            this.form.transportation_todoid = feedback[0].transportation_todoid
                            this.form.transportation_tododesc = feedback[0].transportation_tododesc
                            this.form.transportation_tododate = feedback[0].transportation_tododate
                            this.form.transportation_sdate = feedback[0].transportation_sdate
                            this.form.transportation_edate = feedback[0].transportation_edate
                            this.form.transportation_type = feedback[0].transportation_type
                            this.form.transportation_typedesc = feedback[0].transportation_typedesc
                            this.form.transportation_departure = feedback[0].transportation_departure
                            this.form.transportation_arrive = feedback[0].transportation_arrive
                            this.form.transportation_parking = feedback[0].transportation_parking
                            this.form.transportation_fuel = feedback[0].transportation_fuel
                            this.form.transportation_toll = feedback[0].transportation_toll
                            this.form.transportation_total = feedback[0].transportation_total
                            this.form.transportation_remarks = feedback[0].transportation_remarks
                            this.form.transportation_usersid = feedback[0].transportation_usersid
                            this.form.transportation_usersavatar = feedback[0].transportation_usersavatarurl
                            this.form.transportation_status = feedback[0].transportation_status
                            this.form.transportation_statusdesc = feedback[0].transportation_statusdesc
                            this.form.transportation_rejectuser = feedback[0].transportation_rejectuser
                            this.form.transportation_rejectdate = feedback[0].transportation_rejectdate
                            this.form.transportation_notes = feedback[0].transportation_notes
                            this.form.transportation_attachmentpath = feedback[0].transportation_attachmentpath
                            this.form.transportation_attachmentpathurl = feedback[0].transportation_attachmentpathurl
                            this.form.transportation_cuser = feedback[0].transportation_cuser
                            this.form.transportation_cdate = feedback[0].transportation_cdate
                            if  (feedback[0].transportation_status === 'L') {
                                this.CheckHR()
                            }
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            CheckHR () {
                this.loading.page = true
                let formdata = {
                  users_type: 'HR-MGR',
                  flag: 'mobileapp',
                  users_company: this.$functions.UsersCompany(),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.form.hr_user = feedback[0].users_id
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            Confirm (flag) {
                if (flag === 'approve') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Setuju'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                } else if (flag === 'reject') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Tolak'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                if (this.confirm.notes === '') {
                    this.SnackBar(true, 'success', 'Mohon isi catatan', 3000)
                    setTimeout(function(){
                    document.getElementById('confirmnotes').focus()
                    }, 500)
                } else {
                    this.confirm.ok = 'Loading...'
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  transportation_id: this.form.transportation_id,
                  transportation_notes: this.confirm.notes,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteTransportation'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            let flag = ''
                            if (this.url_type === 'approve') {
                                flag = 'Setuju'
                                this.form.transportation_status = 'L'
                                this.form.transportation_statusdesc = 'UnApprove HR'
                            } else if (this.url_type === 'approve') {
                                flag = 'Tolak'
                                this.form.transportation_status = 'J'
                                this.form.transportation_statusdesc = 'Reject'
                            } else if (this.url_type === 'hr') {
                                flag = 'Setuju HR'
                                this.form.transportation_status = 'A'
                                this.form.transportation_statusdesc = 'Approve'
                            }
                            this.SnackBar(true, 'success', flag+' Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>