<template>
    <div>
    <app-toolbarcustom app_title="Aktifitas" app_back="Y"></app-toolbarcustom>
        <div class="mb-15"></div>
        <v-progress-linear
        indeterminate
        color="fourth"
        rounded
        v-if="loading.page"
        ></v-progress-linear>
        <v-card
            class="mx-auto mb-2"
            max-width="344"
            outlined
            v-for="row in posts"
            :key="row.logs_id"
        >
                <div class="text-right text-overline mr-2 green--text font-weight-black">
                {{ $functions.FormatDateTime(row.logs_cdate) }}
                </div>
                <div> 
                    <small class="ml-2"><v-chip
                        class="ma-2"
                        color="primary"
                        label
                        text-color="white"
                      >
                        <v-icon left>
                          mdi-label
                        </v-icon>
                        {{row.logs_action}} {{ row.logs_table }}
                      </v-chip></small></div>
        </v-card>
    </div>
</template>
<script>
    export default {
        data: () => ({
            posts:[],
            loading: {
                page: false
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                this.loading.page = true
                let formdata = {
                  logs_cuser: this.$functions.UsersID(),
                  order_by  : 'logs_cdate',
                  order_type  : 'DESC'
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListLogs'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>