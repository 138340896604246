<template>
    <div>
    <app-toolbarcustom app_title="Data Pending" app_back="Y"></app-toolbarcustom>
        <div class="mb-15"></div>
        <template
        v-for="row in posts"
        >
        <v-card
            class="mx-auto mb-2"
            max-width="344"
            outlined
            :key="row.pending_modul"
            v-if="row.pending_count > 0"
            @click="GoTo(row.pending_modul)"
        >
            <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{row.pending_modul}}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
                size="50"
                color="red"
                class="text-h5"
            >
            {{ row.pending_count }}
            </v-list-item-avatar>
            </v-list-item>
        </v-card>
    </template>
    </div>
</template>
<script>
    export default {
        data: () => ({
            posts:[],
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                let formdata = {
                  flag: 'mobileapp',
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListPendingEmployee'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            GoTo (url) {
                url = 'employee'+url+'list'
                this.$router.push({name: url,query:{pending:'Y'}})
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>