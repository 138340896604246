<template>
    <div>
    <app-toolbarcustom app_title="Laporan" app_menu="account"></app-toolbarcustom>
    <v-card flat>
        <v-list two-line>
            <v-subheader></v-subheader>
            <template v-for="(item, index) in posts">
                <v-list-item
                  :key="item.icon"
                  @click="GoTo(item.url)"
                >
                  <v-list-item-avatar>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{item.text}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                v-if="index + 1 < posts.length"
                :key="index"
              ></v-divider>
           </template>
        </v-list>
    </v-card>
    <app-snackbar 
    :dialog="snackbar.dialog"
    :color="snackbar.color"
    :text="snackbar.text"
    :timeout="snackbar.timeout"
    @close="snackbar.dialog = false"
  ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            posts: [
                {icon: 'assignment', text: 'Kepegawaian', description: 'Kepegawaian', url:'/report/employee'},
            ],
            access: {
                read: 0
            },
            snackbar: {
                dialog: false,
                color: "primary",
                text: "",
                timeout: 3000,
            },
        }),
        created() {
            this.Access()
        },
        methods: {
            GoTo (url) {
               this.$router.push(url)
            },
            Access () {
                let modul = 'myreport'
                let formdata = {
                    menu_id: modul,
                    users_id: this.$functions.UsersID(),
                    order_by: 'menu_id',
                    order_type: 'ASC',
                    flag: 'mobileapp',
                    limit: this.limit,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let priv = response.data
                    if (priv.length > 0) {
                        if (priv[0].feedback !== 'Y') {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        } else {
                        	if (priv[0].usersmenu_read === 0) {
                                let url = 'account'
                                this.$router.push({name: url})
                            } else {
                                this.access.read  	= priv[0].usersmenu_read
                            }
                        }
                    } else {
                        this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 0)
                })
            },
            SnackBar(dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout,
                }
            },
        }
    }
</script>