<template>
    <div v-if="access.read === 1">
        <app-toolbarcustom app_title="Data Laporan" app_menu="account"></app-toolbarcustom>
        <div class="text-left ml-2" style="margin-top:70px">
            <v-progress-linear
            indeterminate
            color="fourth"
            rounded
            class="mb-2"
            v-if="loading.page"
            ></v-progress-linear>
            <v-btn
            rounded
            :color="filter.company === ''?'fourth white--text':'grey'"
            @click="List('')"
            v-if="$functions.UsersCompany() === ''"
            >
            All
            </v-btn>
        </div>
        <v-subheader class="secondary--text">Data Karyawan Per Hari Ini: </v-subheader>
        <section class="card-scroll-header cursor" v-if="posts.employees.length > 0">
            <v-card
                class="white--text card-scroll-item"
                max-width="344"
                outlined
                v-for="(row, index) in posts.employees"
                :key="row.employee_flag"
                :color="index % 2==0 ? 'primary' : 'secondary'"
                @click="GetEmployee(row.employee_flag)"
            >
            <v-row>
                <v-col cols="12" sm="12" md="12" class="mt-2">
                <v-card-text primary-title>
                    <div>
                    <div class="heading">{{row.employee_flag}} <v-icon v-if="row.employee_icon" color="white">{{row.employee_icon}}</v-icon></div>
                    <div class="title text-xs-right mt-2">{{row.employee_flag_count}}</div>
                    </div>
                </v-card-text>
                </v-col>
            </v-row>
            </v-card>
        </section>
        <v-subheader class="secondary--text">Data Karyawan Join dan Resign </v-subheader>
        <section class="card-scroll-header cursor" v-if="posts.joinresign.length > 0">
            <v-card
                class="white--text card-scroll-item"
                max-width="344"
                outlined
                v-for="(row, index) in posts.joinresign"
                :key="row.employee_flag"
                :color="index % 2==0 ? 'primary' : 'secondary'"
                @click="GetEmployee(row.employee_flag)"
            >
            <v-row>
                <v-col cols="12" sm="12" md="12" class="mt-2">
                <v-card-text primary-title>
                    <div>
                    <div class="heading">{{row.employee_flag}} <v-icon v-if="row.employee_icon" color="white">{{row.employee_icon}}</v-icon></div>
                    <div class="title text-xs-right mt-2">{{row.employee_flag_count}}</div>
                    </div>
                </v-card-text>
                </v-col>
            </v-row>
            </v-card>
        </section>
        <v-subheader class="secondary--text font-weight-bold" @click="filter.dialog = true"><v-spacer></v-spacer>Periode: {{ this.$functions.FormatDateIndoWithDayName(filter.sdate) }} <span v-if="filter.sdate !== filter.edate"> - {{ this.$functions.FormatDateIndoWithDayName(filter.edate) }}</span> </v-subheader>
        <section class="card-scroll-header cursor">
            <v-card
                class="white--text card-scroll-item"
                max-width="344"
                outlined
                v-for="(row, index) in posts.absencetodo"
                :key="row.employee_flag"
                :color="index % 2==0 ? 'primary' : 'secondary'"
                @click="GetAbsenceTodo(row.employee_flag)"
            >
            <v-row>
                <v-col cols="12" sm="12" md="12" class="mt-2">
                <v-card-text primary-title>
                    <div>
                    <div class="heading">{{row.employee_flag}} <v-icon v-if="row.employee_icon" color="white">{{row.employee_icon}}</v-icon></div>
                    <div class="title text-xs-right mt-2">{{row.employee_flag_count}}</div>
                    </div>
                </v-card-text>
                </v-col>
            </v-row>
            </v-card>
        </section>
        <v-dialog v-model="filter.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{filter.title}}</v-card-title>
                <v-card-text>
                    <v-row>
                        <!-- ID -->
                        <v-col cols="12" sm="6" md="12" class="mt-2">
                            <v-dialog
                            ref                 ="dialog_sdate"
                            v-model             ="modal.sdate"
                            :return-value.sync  ="form.sdate"
                            width               ="290px"
                            persistent
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                :value              ="sdate_value"
                                label               ="Periode Awal"
                                prepend-icon  ="event"
                                background-color    ="date"
                                v-on                ="on"
                                v-mask              ="'####-##-##'"
                                placeholder         ="YYYY-MM-DD"
                                id                  ="find_sdate"
                                outlined
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.sdate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal.sdate = false">Cancel</v-btn>
                            <v-btn
                                text
                                color   ="primary"
                                @click  ="GetData('sdate',form.sdate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        </v-col>
                        <!-- ID -->
                        <v-col cols="12" sm="6" md="12" class="mt-n6">
                            <v-dialog
                            ref                 ="dialog_edate"
                            v-model             ="modal.edate"
                            :return-value.sync  ="form.edate"
                            width               ="290px"
                            persistent
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                :value              ="edate_value"
                                label               ="Periode Akhir"
                                prepend-icon  ="event"
                                background-color    ="date"
                                v-on                ="on"
                                v-mask              ="'####-##-##'"
                                placeholder         ="YYYY-MM-DD"
                                id                  ="find_edate"
                                outlined
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.edate" :min="form.sdate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal.edate = false">Cancel</v-btn>
                            <v-btn
                                text
                                color   ="primary"
                                @click  ="GetData('edate',form.edate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="FilterDate">{{filter.close}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog.employee" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{dialog.title}} <v-spacer></v-spacer>
                    <v-btn color="white" outlined @click.stop="dialog.employee = false">TUTUP</v-btn></v-card-title>
                    <v-subheader class="secondary--text font-weight-bold" v-if="dialog.title === 'BELUM TODO'"><v-spacer></v-spacer>{{ this.$functions.FormatDateIndoWithDayName(filter.sdate) }} <span v-if="filter.sdate !== filter.edate"> - {{ this.$functions.FormatDateIndoWithDayName(filter.edate) }}</span></v-subheader>
                <v-card-text class="card-text-scrollable">
                    <v-progress-linear
                    indeterminate
                    color="fourth"
                    rounded
                    class="mb-2"
                    v-if="loading.employee"
                    ></v-progress-linear>
                    <v-list-item three-line v-for="row in posts.users" :key="row.users_id">
                        <v-list-item-content>
                            <v-list-item-title>{{row.users_name}}</v-list-item-title>
                            <v-list-item-subtitle>{{row.users_title}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{row.users_employeeid}}</v-list-item-subtitle>
                        </v-list-item-content>
            
                        <v-list-item-avatar
                            size="80"
                            color="grey"
                        >
                            <img :src="row.users_avatar_url">
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog.absence" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{dialog.title}} <v-spacer></v-spacer>
                    <v-btn color="white" outlined @click.stop="dialog.absence = false">TUTUP</v-btn></v-card-title>
                    <v-subheader class="secondary--text font-weight-bold"><v-spacer></v-spacer>{{ this.$functions.FormatDateIndoWithDayName(filter.sdate) }} <span v-if="filter.sdate !== filter.edate"> - {{ this.$functions.FormatDateIndoWithDayName(filter.edate) }}</span></v-subheader>
                <v-card-text class="card-text-scrollable">
                    <v-progress-linear
                    indeterminate
                    color="fourth"
                    rounded
                    class="mb-2"
                    v-if="loading.absence"
                    ></v-progress-linear>
                    <v-list-item three-line v-for="row in posts.absence" :key="`${row.absence_date}${row.absence_usersid}`">
                        <v-list-item-content>
                            <v-list-item-title>{{$functions.FormatDateIndoWithDayName(row.absence_date)}}</v-list-item-title>
                            <v-list-item-subtitle>{{row.absence_usersemployeeid}} - {{row.absence_usersname}}</v-list-item-subtitle>
                            <v-list-item-subtitle>IN: {{row.absence_timein}} - OUT: {{row.absence_timeout}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{row.absence_remarks}}</v-list-item-subtitle>
                        </v-list-item-content>
            
                        <v-list-item-avatar
                            size="80"
                            color="grey"
                        >
                            <img :src="row.absence_usersavatarurl">
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog.todo" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{dialog.title}} <v-spacer></v-spacer>
                    <v-btn color="white" outlined @click.stop="dialog.todo = false">TUTUP</v-btn></v-card-title>
                <v-card-text class="card-text-scrollable">
                    <v-progress-linear
                    indeterminate
                    color="fourth"
                    rounded
                    class="mb-2"
                    v-if="loading.todo"
                    ></v-progress-linear>
                    <v-list-item three-line v-for="row in posts.todo" :key="row.todo_id">
                        <v-list-item-content>
                            <v-list-item-title>{{$functions.FormatDateIndoWithDayName(row.todo_date)}}</v-list-item-title>
                            <v-list-item-subtitle>{{row.todo_users_employeeid}} - {{row.todo_users_name}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{row.todo_desc}}</v-list-item-subtitle>
                        </v-list-item-content>
            
                        <v-list-item-avatar
                            size="80"
                            color="grey"
                        >
                            <img :src="row.todo_users_avaurl">
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-dialog>
        <app-snackbar 
        :dialog="snackbar.dialog"
        :color="snackbar.color"
        :text="snackbar.text"
        :timeout="snackbar.timeout"
        @close="snackbar.dialog = false"
      ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            posts:{
                employees: [],
                joinresign: [],
                absencetodo: [],
                users: [],
                todo: [],
            },
            access: {
                read: 0
            },
            loading: {
                page: false,
                absence: false,
                employee: false,
                todo: false,
            },
            pages: {
                limit:10,
                offset:10,
                fetch:10,
            },
            current_date: '',
            form: {
                sdate: new Date().toISOString().substr(0, 10),
                edate: new Date().toISOString().substr(0, 10),
            },
            dialog: {
                employee: false,
                absence: false,
                todo: false,
            },
            modal: {
                sdate: false,
                edate: false,
            },
            filter: {
                dialog: false,
                title: 'Filter Periode',
                close: 'Tutup',
                company: '',
                sdate: new Date().toISOString().substr(0, 10),
                edate: new Date().toISOString().substr(0, 10),
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            if (this.$functions.UsersCompany() !== '') {
                this.filter.company = this.$functions.UsersCompany()
            }
            this.Access()
            this.Employee()
            this.AbsenceTodo()
        },
        // Check If Back Button Press, Modal Close
        beforeRouteLeave (to, from, next) {
            if (this.modal.sdate || this.modal.edate) {
                this.modal.sdate = false
                this.modal.edate = false
                next(false)
            } else {
                next()
            }
        },
        computed: {
            sdate_value () {
                return this.form.sdate ? this.$moment(this.form.sdate).format('LL') : ''
            },
            edate_value () {
                return this.form.edate ? this.$moment(this.form.edate).format('LL') : ''
            }
        },
        methods:{
            Access () {
                let modul = 'myreport'
                let formdata = {
                    menu_id: modul,
                    users_id: this.$functions.UsersID(),
                    order_by: 'menu_id',
                    order_type: 'ASC',
                    flag: 'mobileapp',
                    limit: this.limit,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let priv = response.data
                    if (priv.length > 0) {
                        if (priv[0].feedback !== 'Y') {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        } else {
                        	if (priv[0].usersmenu_read === 0) {
                                let url = 'account'
                                this.$router.push({name: url})
                            } else {
                                this.access.read  	= priv[0].usersmenu_read
                            }
                        }
                    } else {
                        this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 0)
                })
            },
            FilterDate () {
                this.filter.dialog = false
                this.AbsenceTodo()
            },
            List (flag) {
                this.filter.company = flag
                this.Employee()
                this.AbsenceTodo()
            },
            Employee () {
                this.loading.page = true
                let formdata = {
                  users_company: this.filter.company,
                  flag: 'mobileapp',
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersReportEmployee'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts.employees = [
                            {employee_flag: 'CABANG',employee_icon: 'apartment',employee_flag_count: feedback[0].report_branch_count},
                            {employee_flag: 'KARYAWAN',employee_icon: 'badge',employee_flag_count: feedback[0].report_gender_female + feedback[0].report_gender_male},
                            {employee_flag: 'MANAGEMEN',employee_icon: 'supervisor_account',employee_flag_count: feedback[0].report_flag_management},
                            {employee_flag: 'PERMANENT',employee_icon: '',employee_flag_count: feedback[0].report_status_permanent},
                            {employee_flag: 'PKWT',employee_icon: '',employee_flag_count: feedback[0].report_status_pkwt},
                            {employee_flag: 'PROBATION',employee_icon: '',employee_flag_count: feedback[0].report_status_probation},
                            {employee_flag: 'OUTSOURCE',employee_icon: '',employee_flag_count: feedback[0].report_status_outsource},
                            {employee_flag: 'APPRENT',employee_icon: '',employee_flag_count: feedback[0].report_status_apprent},
                            {employee_flag: 'WANITA',employee_icon: 'woman',employee_flag_count: feedback[0].report_gender_female},
                            {employee_flag: 'PRIA',employee_icon: 'man',employee_flag_count: feedback[0].report_gender_male},
                        ]
                        this.posts.joinresign = [
                            {employee_flag: 'JOIN BULAN INI',employee_icon: 'calendar_month',employee_flag_count: feedback[0].report_join_month},
                            {employee_flag: 'JOIN TAHUN INI',employee_icon: 'calendar_today',employee_flag_count: feedback[0].report_join_year},
                            {employee_flag: 'RESIGN BULAN INI',employee_icon: 'calendar_month',employee_flag_count: feedback[0].report_resign_month},
                        ]
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            AbsenceTodo () {
                this.loading.page = true
                let formdata = {
                  users_company: this.filter.company,
                  users_sdate: this.filter.sdate,
                  users_edate: this.filter.edate,
                  flag: 'mobileapp',
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersReportAbsenceTodo'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts.absencetodo = [
                            {employee_flag: 'ABSEN',employee_icon: 'today',employee_flag_count: feedback[0].report_absence_presence},
                            {employee_flag: 'TIDAK ABSEN',employee_icon: 'logout',employee_flag_count: feedback[0].report_absence_absence},
                            {employee_flag: 'CUTI ATAU SAKIT',employee_icon: 'event_busy',employee_flag_count: feedback[0].report_absence_leave},
                            {employee_flag: 'SUDAH TODO',employee_icon: 'task_alt',employee_flag_count: feedback[0].report_todo_yes},
                            {employee_flag: 'BELUM TODO',employee_icon: 'do_not_disturb_on',employee_flag_count: feedback[0].report_todo_no},
                        ]
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.page = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            GetEmployee (filter) {
                this.dialog.employee = true
                this.dialog.title = filter
                this.loading.employee = true
                let formdata = {
                  users_company: this.filter.company,
                  users_sdate: this.filter.sdate,
                  users_edate: this.filter.edate,
                  users_filter: filter,
                  flag: 'mobileapp',
                  order_by: 'users_id',
                  order_type: 'ASC',
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts.users = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.employee = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.employee = false
                })
            },
            GetAbsenceTodo (filter) {
                if (filter === 'ABSEN' || filter === 'TIDAK ABSEN'|| filter === 'CUTI ATAU SAKIT') {
                    this.GetAbsence(filter)
                } else if (filter === 'BELUM TODO') {
                    this.GetEmployee(filter)
                } else if (filter === 'SUDAH TODO') {
                    this.GetTodo(filter)
                }
            },
            GetAbsence (filter) {
                this.dialog.absence = true
                this.dialog.title = filter
                this.loading.absence = true
                let formdata = {
                  absence_company: this.filter.company,
                  absence_sdate: this.filter.sdate,
                  absence_edate: this.filter.edate,
                  absence_filter: filter,
                  flag: 'mobileapp',
                  order_by: 'absence_date',
                  order_type: 'ASC',
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts.absence = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.absence = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.absence = false
                })
            },
            GetTodo (filter) {
                this.dialog.todo = true
                this.dialog.title = filter
                this.loading.todo = true
                let formdata = {
                  todo_company: this.filter.company,
                  todo_sdate: this.filter.sdate,
                  todo_edate: this.filter.edate,
                  todo_filter: filter,
                  flag: 'mobileapp',
                  order_by: 'todo_date,todo_cuser',
                  order_type: 'ASC',
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListTodo'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        this.posts.todo = feedback
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                    this.loading.todo = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.todo = false
                })
            },
            GetData (flag, value) {
                switch (flag) {
                	case 'sdate':
                		this.$refs.dialog_sdate.save(value)
                        this.form.sdate = this.$moment(value).format('YYYY-MM-DD')
                        this.filter.sdate = this.$moment(value).format('YYYY-MM-DD')
                    break
                    case 'edate':
                		this.$refs.dialog_edate.save(value)
                        this.form.edate = this.$moment(value).format('YYYY-MM-DD')
                        this.filter.edate = this.$moment(value).format('YYYY-MM-DD')
                    break
                }
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.filter.dialog 	 = false
                    this.filter.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>