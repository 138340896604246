<template>
    <div>
    <app-toolbarcustom app_title="Struktur Organisasi" app_back="Y"></app-toolbarcustom>
    <v-card flat class="mt-15">
        <v-card-text>
            <v-row align="center" class="text-center">
                <v-col :cols="detail.management.length > 4?'4':'6'" v-for="data in detail.management" :key="data.users_id">
                    <v-avatar
                      size="100"
                      color="grey lighten-4"
                      @click="EmployeeData(0,data.users_id)"
                      :class="position.bod === data.users_id?'':'inactive'"
                    >
                      <img :src="data.users_avatar_url" :alt="data.users_name">
                    </v-avatar>
                    <br>
                    <div class="title primary--text">
                    {{data.users_name}}
                    </div>
                    <small class="body-2 font-weight-bold">{{data.users_title}}</small>
                </v-col>
            </v-row>
                <div class="mt-2">
                    <v-progress-linear
                    indeterminate
                    color="fourth"
                    rounded
                    v-if="loading.page"
                    ></v-progress-linear>
                    <v-subheader v-if="position.bod"><v-icon>keyboard_arrow_left</v-icon>
                    <span @click="EmployeeData(0,position.bod)">{{position.bod}}</span> <span v-if="position.layer1">/</span> {{ position.layer1 }} <span v-if="position.layer2">/</span> {{ position.layer2 }}
                    </v-subheader>
                    <v-list three-line>
                        <v-list-item
                        v-for="data in detail.employee"
                        :key="data.users_id"
                        @click="EmployeeData(1,data.users_id)"
                        >
                        <v-list-item-action>
                            <v-avatar
                             size="75"
                             color="grey lighten-4"
                           >
                             <img :src="data.users_avatar_url" :alt="data.users_name">
                           </v-avatar>
                         </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{data.users_name}}</v-list-item-title>
                            <v-list-item-subtitle>{{data.users_email}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{data.users_title}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
        </v-card-text>
    </v-card>
    <v-dialog v-model="confirm.dialog" max-width="490" persistent>
        <v-card>
            <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
            <v-card-text>
                {{confirm.text}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: {
                page: false
            },
            detail: {
                management: [],
                employee: [],
            },
            position: {
                bod: '',
                layer1: '',
                layer2: '',
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            GoTo (url) {
               this.$router.push(url)
            },
            FirstLoad () {
                this.ManagementData()
            },
            Loading () {
                this.confirm.ok = 'Loading...'
            },
            ManagementData () {
              let formdata   = {
                  flag: 'mobileapp',
                  users_managementflag: 'Y',
                  users_company: this.$functions.UsersCompany(),
                  order_by: 'users_sortno',
                  order_type: 'ASC',
              }
              let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
              headers: {
                  'Content-Type': 'text/plain; charset=ISO-8859-1'
              }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback === 'Y') {
                        this.detail.management = feedback
                      } else {
                          this.SnackBar(true,'error',feedback[0].feedback,3000)
                      }
                  }
              })
              .catch(e => {
                  this.SnackBar(true,'error',e,3000)
              })
          },
          EmployeeData (flag, id) {
            this.loading.page = true
            let formdata   = {
                  flag: 'mobileapp',
                  users_managementflag: 'N',
                  users_actived: 'Y',
                  users_leader: id,
                  users_company: this.$functions.UsersCompany(),
                  order_by: 'users_name',
                  order_type: 'ASC',
              }
              let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
              headers: {
                  'Content-Type': 'text/plain; charset=ISO-8859-1'
              }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback === 'Y') {
                        if (flag === 0) {
                            this.position.bod = id
                            this.position.layer1 = ''
                            this.position.layer2 = ''
                        } else if (flag === 1) {
                            this.detail.employee = []
                            this.position.layer1 = id
                            this.position.layer2 = ''
                        }
                        this.detail.employee = feedback
                      } else {
                          this.SnackBar(true,'error',feedback[0].feedback,3000)
                      }
                  } else {
                    this.SnackBar(true,'error','User '+id+ ' belum memiliki tim',3000)
                  }
                  this.loading.page = false
              })
              .catch(e => {
                  this.SnackBar(true,'error',e,3000)
                  this.loading.page = false
              })
          },
          SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>
<style>
.inactive{ 
   filter: grayscale(100%) ; 
 }
</style>