<template>
    <div>
    <app-toolbaravatar :app_title="title" :app_avatar="form.overtime_usersavatar"></app-toolbaravatar>
    <v-card
    class="mt-15"
    outlined
    >
    <v-progress-linear
    indeterminate
    color="fourth"
    rounded
    v-if="loading.page"
    ></v-progress-linear>
    <v-card-title class="body-2 font-weight-bold">Ringkasan Lembur</v-card-title>
    <v-card-text class="caption">
            <v-row align="center" class="mb-2">
                <v-col cols="6">Tanggal Pengajuan</v-col><v-col cols="6" class="font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.overtime_date) }}</v-col>
                <v-col cols="6" class="mt-n5">User</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.overtime_usersid }}</v-col>
                <v-col cols="6" class="mt-n5">Jam Mulai</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.overtime_timein }}</v-col>
                <v-col cols="6" class="mt-n5">Jam Selesai</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.overtime_timeout }}</v-col>
                <v-col cols="6" class="mt-n5">Durasi</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.MinuteDiff(form.overtime_timein,form.overtime_timeout) }}</v-col>
                <v-col cols="6" class="mt-n5">Alasan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.overtime_remarks }}</v-col>
                <v-col cols="6" class="mt-n5"></v-col><v-col cols="6" class="mt-n5 font-weight-bold"><v-chip
                    class="ma-2"
                    color="primary"
                    label
                    small
                    outlined
                  >
                  {{ form.overtime_statusdesc }}
                  </v-chip></v-col>
            </v-row>
    <v-btn color="primary" @click="Confirm('approve')" block class="mb-2" v-if="form.overtime_status === 'R' && form.overtime_leaderuser === $functions.UsersID()">Setuju</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.overtime_status === 'R' && form.overtime_leaderuser === $functions.UsersID()">Tolak</v-btn>
    <v-btn color="primary" @click="Confirm('hr')" block class="mb-2" v-if="form.overtime_status === 'L' && form.hr_user === $functions.UsersID()">Setuju HR</v-btn>
    <v-btn color="red" @click="Confirm('reject')" block v-if="form.overtime_status === 'L' && form.hr_user === $functions.UsersID()">Tolak HR</v-btn>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-title class="body-2 font-weight-bold">Timeline</v-card-title>
    <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              color="green"
              small
            >
              <div>
                <div class="caption">
                    created:<strong> {{ form.overtime_cuser }}</strong> &bull; {{ $functions.FormatDateTime(form.overtime_cdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              :color="$functions.FormatDateTime(form.overtime_leaderdate)?'green':'yellow'"
              small
              v-if="form.overtime_leaderuser"
            >
              <div>
                <div class="caption">
                    leader:<strong> {{ form.overtime_leaderuser }}</strong> &bull; {{ $functions.FormatDateTime(form.overtime_leaderdate) }}
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              color="red"
              small
              v-if="form.overtime_rejectuser"
            >
              <div>
                <div class="caption">
                    reject:<strong> {{ form.overtime_rejectuser }}</strong>&bull;{{ $functions.FormatDateTime(form.overtime_rejectdate) }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
    </v-card>
    <v-alert
    border="left"
    colored-border
    color="secondary"
    elevation="2"
    v-if="form.overtime_notes"
    >
    <div v-html="form.overtime_notes"></div>
    </v-alert>
    <v-dialog v-model="confirm.dialog" max-width="490" persistent>
        <v-card>
            <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
            <v-card-text>
                {{confirm.text}}
                <v-text-field 
                    v-model="confirm.notes"
                    prepend-inner-icon="notes"
                    placeholder="Catatan"
                    label="Catatan"
                    required
                    solo-inverted
                    outlined
                    class="mt-3"
                    id="confirmnotes"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <app-snackbar 
        :dialog="snackbar.dialog"
        :color="snackbar.color"
        :text="snackbar.text"
        :timeout="snackbar.timeout"
        @close="snackbar.dialog = false"
    ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            title: '',
            loading: {
                page: false
            },
            form: {
                overtime_id: '',
                overtime_date: '',
                overtime_timein: '',
                overtime_timeout: '',
                overtime_remarks: '',
                overtime_usersid: '',
                overtime_usersavatar: '',
                overtime_status: '',
                overtime_statusdesc: '',
                overtime_leaderuser: '',
                overtime_leaderdate: '',
                overtime_rejectuser: '',
                overtime_rejectdate: '',
                overtime_hruser: '',
                overtime_notes: '',
                overtime_cuser: '',
                overtime_cdate: '',
                hr_user: '',
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
                notes: '',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            },
        }),
        created() {
            this.FirstLoad()
        },
        methods: {
            FirstLoad () {
                this.loading.page = true
                let decode = this.$functions.DecodeUrl(this.$route.params.id)
                let formdata = {
                  overtime_id: decode,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListOvertime'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.title = feedback[0].overtime_id
                            this.form.overtime_id = feedback[0].overtime_id
                            this.form.overtime_date = feedback[0].overtime_date
                            this.form.overtime_timein = feedback[0].overtime_timein
                            this.form.overtime_timeout = feedback[0].overtime_timeout
                            this.form.overtime_remarks = feedback[0].overtime_remarks
                            this.form.overtime_usersid = feedback[0].overtime_usersid
                            this.form.overtime_usersavatar = feedback[0].overtime_usersavatarurl
                            this.form.overtime_status = feedback[0].overtime_status
                            this.form.overtime_statusdesc = feedback[0].overtime_statusdesc
                            this.form.overtime_leaderuser = feedback[0].overtime_leaderuser
                            this.form.overtime_leaderdate = feedback[0].overtime_leaderdate
                            this.form.overtime_rejectuser = feedback[0].overtime_rejectuser
                            this.form.overtime_rejectdate = feedback[0].overtime_rejectdate
                            this.form.overtime_notes = feedback[0].overtime_notes
                            this.form.overtime_cuser = feedback[0].overtime_cuser
                            this.form.overtime_cdate = feedback[0].overtime_cdate
                            if  (feedback[0].overtime_status === 'L') {
                                this.CheckHR()
                            }
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            CheckHR () {
                this.loading.page = true
                let formdata = {
                  users_type: 'HR-MGR',
                  flag: 'mobileapp',
                  users_company: this.$functions.UsersCompany(),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.form.hr_user = feedback[0].users_id
                            this.loading.page = false
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.loading.page = false
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.loading.page = false
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                    this.loading.page = false
                })
            },
            Confirm (flag) {
                if (flag === 'approve') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Setuju'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                } else if (flag === 'reject') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Tolak'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                if (this.confirm.notes === '') {
                    this.SnackBar(true, 'success', 'Mohon isi catatan', 3000)
                    setTimeout(function(){
                    document.getElementById('confirmnotes').focus()
                    }, 500)
                } else {
                    this.confirm.ok = 'Loading...'
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  overtime_id: this.form.overtime_id,
                  overtime_notes: this.confirm.notes,
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteOvertime'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            let flag = ''
                            if (this.url_type === 'approve') {
                                flag = 'Setuju'
                                this.form.overtime_status = 'L'
                                this.form.overtime_statusdesc = 'UnApprove HR'
                            } else if (this.url_type === 'approve') {
                                flag = 'Tolak'
                                this.form.overtime_status = 'J'
                                this.form.overtime_statusdesc = 'Reject'
                            } else if (this.url_type === 'hr') {
                                flag = 'Setuju HR'
                                this.form.overtime_status = 'A'
                                this.form.overtime_statusdesc = 'Approve'
                            }
                            this.SnackBar(true, 'success', flag+' Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>