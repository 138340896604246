<template>
    <div>
    <app-toolbarcustom app_title="Form Cuti dan Ijin Sakit" app_menu="employeeleave"></app-toolbarcustom>
        <template>
            <v-stepper v-model="steppers" class="mt-15 elevation-0">
              <v-stepper-header>
                <v-stepper-step
                  :complete="steppers > 1"
                  step="1"
                >
                  Form
                </v-stepper-step>
          
                <v-divider></v-divider>
          
                <v-stepper-step
                  :complete="steppers > 2"
                  step="2"
                >
                  Summary
                </v-stepper-step>
                
              </v-stepper-header>
          
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card
                    class="mb-12 elevation-0"
                  >
                  <v-form enctype="multipart/form-data" ref="form_leave_data" lazy-validation>
                  <v-row>
                    <!-- ID -->
                    <v-col cols="12" sm="12" md="12" class="mt-2">
                        <app-cb
                        cb_type           ="leavecategory"
                        cb_url            ="apiListLeaveCategory"
                        cb_url_parameter  ="leavecategory_actived=Y"
                        cb_title          ="Kategori"
                        cb_id             ="leave_categoryid"
                        cb_desc           ="leave_categoryiddesc"
                        cb_rules          ="Mohon isi Kategori"
                        cb_desc_readonly  ="readonly"
                        cb_items_id       ="leavecategory_id"
                        cb_items_desc     ="leavecategory_desc"
                        :cb_value_id      ="form.leave_category"
                        :cb_value_desc    ="form.leave_categorydesc"
                        @clicked          ="GetLeaveCategory"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" class="mt-n6">
                        <v-dialog
                        ref                 ="dialog_leave_sdate"
                        v-model             ="modal.leave_sdate"
                        :return-value.sync  ="form.leave_sdate"
                        width               ="290px"
                        persistent
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value              ="leave_sdate_value"
                            label               ="Tanggal Mulai"
                            prepend-icon  ="event"
                            background-color    ="date"
                            v-on                ="on"
                            v-mask              ="'####-##-##'"
                            placeholder         ="YYYY-MM-DD"
                            id                  ="find_leave_sdate"
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.leave_sdate" :min="form.leave_category==='CS'?$functions.PastDate(5):$functions.PastDate(0)" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.leave_sdate = false">Cancel</v-btn>
                        <v-btn
                            text
                            color   ="primary"
                            @click  ="GetData('leave_sdate',form.leave_sdate)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" class="mt-n6">
                        <v-dialog
                        ref                 ="dialog_leave_edate"
                        v-model             ="modal.leave_edate"
                        :return-value.sync  ="form.leave_edate"
                        width               ="290px"
                        persistent
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value              ="leave_edate_value"
                            label               ="Tanggal Selesai"
                            prepend-icon  ="event"
                            background-color    ="date"
                            v-on                ="on"
                            v-mask              ="'####-##-##'"
                            placeholder         ="YYYY-MM-DD"
                            id                  ="find_leave_edate"
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.leave_edate" :min="form.leave_sdate" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.leave_edate = false">Cancel</v-btn>
                        <v-btn
                            text
                            color   ="primary"
                            @click  ="GetData('leave_edate',form.leave_edate)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                        <v-text-field 
                            v-model="form.leave_duration"
                            :readonly="true"
                            :prepend-icon="loading.page?'hourglass_top':'event'"
                            placeholder="Lama Cuti/Ijin"
                            label="Lama Cuti/Ijin *"
                            required
                            outlined
                            >
                            </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                            <v-text-field 
                                v-model="form.leave_remarks"
                                :rules="form.leave_remarks_rules"
                                prepend-icon="notes"
                                placeholder="Berikan Alasan Cuti/Sakit"
                                label="Alasan Cuti/Sakit *"
                                required
                                solo-inverted
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row> 
                    <v-alert
                    border="left"
                    colored-border
                    color="secondary"
                    elevation="2"
                    v-if="form.leave_category === 'CT'"
                    >
                    Sisa Cuti Tahunan: {{form.usersleave_remaining}} hari, Exp: {{$functions.FormatDateIndoWithDayName(form.usersleave_expdate)}}
                    </v-alert>
                    </v-form>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="NextStep(1)"
                    block
                  >
                    Lanjut
                  </v-btn>
          
                </v-stepper-content>
          
                <v-stepper-content step="2">
                  <v-card
                    class="mb-12"
                    outlined
                  >
                  <v-card-title class="body-2 font-weight-bold">Ringkasan Cuti/Ijin Sakit</v-card-title>

                    <v-card-text class="caption">
                            <v-row align="center">
                                <v-col cols="6">Kategori</v-col><v-col cols="6" class="font-weight-bold">{{ form.leave_category }} - {{ form.leave_categorydesc }}</v-col>
                                <v-col cols="6" class="mt-n5">Tanggal Mulai</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.leave_sdate) }}</v-col>
                                <v-col cols="6" class="mt-n5">Tanggal Selesai</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ $functions.FormatDateIndoWithDayName(form.leave_edate) }}</v-col>
                                <v-col cols="6" class="mt-n5">Lama Cuti/Ijin</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.leave_duration }} Hari</v-col>
                                <v-col cols="6" class="mt-n5">Alasan</v-col><v-col cols="6" class="mt-n5 font-weight-bold">{{ form.leave_remarks }}</v-col>
                            </v-row>
                    </v-card-text>
                 </v-card>
          
                  <v-btn
                    color="primary"
                    @click="Confirm('add')"
                    block
                    class="mb-2"
                  >
                    Proses
                  </v-btn>
          
                  <v-btn color="secondary" @click="steppers = 1" block>
                    Sebelumnya
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </template>
          <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="Loading">{{confirm.ok}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <app-snackbar 
            :dialog="snackbar.dialog"
            :color="snackbar.color"
            :text="snackbar.text"
            :timeout="snackbar.timeout"
            @close="snackbar.dialog = false"
          ></app-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            steppers: 1,
            url_type: 'add' ,
            loading: {
                page: false
            },
            form: {
                leave_category: '',
                leave_categorydesc: '',
                leave_sdate: new Date().toISOString().substr(0, 10),
                leave_sdate_rules: [v => !!v || 'Mohon isi Tanggal Mulai'],
                leave_edate: new Date().toISOString().substr(0, 10),
                leave_edate_rules: [v => !!v || 'Mohon isi Tanggal Selesai'],
                leave_timein: '',
                leave_timein_rules: [v => !!v || 'Mohon isi Jam Mulai'],
                leave_timeout: '',
                leave_timeout_rules: [v => !!v || 'Mohon isi Jam Selesai'],
                leave_remarks: '',
                leave_remarks_rules: [v => !!v || 'Mohon masukkan alasan lembur'],
                leave_vehicle: 'P',
                usersleave_remaining: 0,
                usersleave_expdate: '',
                usersleave_year: 0,
                leave_duration: 1,
            },
            modal: {
                leave_sdate: false,
                leave_edate: false,
            },
            confirm: {
                dialog: false,
                title: '',
                text: '',
                ok: 'OK',
                close: 'Tutup',
            },
            snackbar: {
                dialog: false,
                timeout: 3000,
                text: '',
                color: 'error'
            }
        }),
         // Check If Back Button Press, Modal Close
         beforeRouteLeave (to, from, next) {
            if (this.modal.leave_sdate || this.modal.leave_edate) {
                this.modal.leave_sdate = false
                this.modal.leave_edate = false
                next(false)
            } else {
                next()
            }
        },
        computed: {
            leave_sdate_value () {
                return this.form.leave_sdate ? this.$moment(this.form.leave_sdate).format('LL') : ''
            },
            leave_edate_value () {
                return this.form.leave_edate ? this.$moment(this.form.leave_edate).format('LL') : ''
            }
        },
        methods: {
            GoTo (url) {
               this.$router.push(url)
            },
            Confirm (flag) {
                if (flag === 'add') {
                    this.url_type = flag
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Proses'
                    setTimeout(function(){
                    document.getElementById('dialog_confirm_true').focus()
                    }, 500)
                }
            },
            Loading () {
                this.confirm.ok = 'Loading...'
                if (this.url_type === 'add' || this.url_type === 'edit') {
                    this.Write()
                }
            },
            Write () {
                let formdata = {
                  url_type: this.url_type,
                  leave_category: this.form.leave_category,
                  leave_sdate: this.form.leave_sdate,
                  leave_edate: this.form.leave_edate,
                  leave_remarks: this.form.leave_remarks,
                  leave_duration: String(this.form.leave_duration),
                  leave_year: String(this.form.usersleave_year),
                  leave_usersid: this.$functions.UsersID(),
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiWriteLeave'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.SnackBar(true, 'success', 'Proses Sukses', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                            let encodeurl = this.$functions.EncodeUrl(feedback[0].feedback_id)
                            this.$router.push({name: 'employeeleavedetail',params:{id:encodeurl}})
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            GetData (flag, value) {
                switch (flag) {
                	case 'leave_sdate':
                		this.$refs.dialog_leave_sdate.save(value)
                        this.form.leave_sdate = this.$moment(value).format('YYYY-MM-DD')
                        this.CheckLeave()
                    break
                    case 'leave_edate':
                		this.$refs.dialog_leave_edate.save(value)
                        this.form.leave_edate = this.$moment(value).format('YYYY-MM-DD')
                        this.CheckLeave()
                    break
                }
            },
            NextStep(id) {
                if (id === 1) {
                    if (this.form.leave_category === '') {
                        this.snackbar.dialog = true
                        this.snackbar.text = 'Mohon pilih kategori terlebih dahulu'
                    } else {
                        let today = this.$functions.Today()
                        if (this.form.usersleave_expdate < today && this.form.leave_category === 'CT') {
                            this.SnackBar(true, 'error', 'Cuti Tahunan Anda sudah Expired', 3000)
                        } else {
                            if ((this.form.leave_duration > this.form.usersleave_remaining) && this.form.leave_category === 'CT') {
                                this.SnackBar(true, 'error', 'Lama cuti tidak boleh lebih besar dari sisa cuti', 3000)
                            } else {
                                if (this.$refs.form_leave_data.validate()) {
                                    this.steppers = 2
                                }
                            }
                        }
                    }
                }
            },
            CheckRemaining (category) {
                let formdata = {
                  usersleave_usersid: this.$functions.UsersID(),
                  usersleave_category: category,
                  flag: 'mobileapp',
                }
                let param 	   = this.$functions.ParamPOST(formdata)
                this.$axios.post(this.$functions.UrlPOST('apiListUsersLeave'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                           this.form.usersleave_remaining = feedback[0].usersleave_remaining
                           this.form.usersleave_expdate = feedback[0].usersleave_expdate
                           this.form.usersleave_year = feedback[0].usersleave_year
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', e, 3000)
                })
            },
            CheckLeave () {
                if (this.form.leave_sdate <= this.form.leave_edate) {
                    this.loading.page = true
                    let formdata = {
                    start_date: this.form.leave_sdate,
                    end_date: this.form.leave_edate,
                    category: 'workdays',
                    flag: 'mobileapp',
                    }
                    let param 	   = this.$functions.ParamPOST(formdata)
                    this.$axios.post(this.$functions.UrlPOST('apiCalculateWorkDays'),param,{
                        headers: {
                            'Content-Type': 'text/plain; charset=ISO-8859-1'
                        }
                    })
                    .then(response => {
                        let feedback = response.data
                        if (feedback.length > 0) {
                            if (feedback[0].feedback === 'Y') {
                            this.form.leave_duration = feedback[0].feedback_days
                            } else {
                                this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            }
                        } else {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        }
                        this.loading.page = false
                    })
                    .catch(e => {
                        this.SnackBar(true, 'error', e, 3000)
                        this.loading.page = false
                    })
                } else {
                    this.form.leave_edate = this.form.leave_sdate
                    this.form.leave_duration = 1
                }
            },
            GetLeaveCategory (value) {
                if (value !== undefined) {    
                    let splits  = this.$functions.Split(value, '|')
                    this.form.leave_category = splits[0]
                    this.form.leave_categorydesc = splits[1]
                    if (splits[0] === 'CT') {
                        this.CheckRemaining(splits[0])
                    }
                }
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout
                }
                if (color === 'error') {
                    this.confirm.dialog 	 = false
                    this.confirm.ok 	 	 = 'OK'
                }
            }
        }
    }
</script>