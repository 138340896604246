<template>
    <div>
    <app-toolbarcustom app_title="Kepegawaian" app_menu="home"></app-toolbarcustom>
    <v-card flat>
        <v-list two-line>
            <v-subheader></v-subheader>
            <template v-for="(item, index) in posts">
                <v-list-item
                  :key="item.icon"
                  @click="GoTo(item.url)"
                >
                  <v-list-item-avatar v-if="item.icon === 'notifications'">
                    <v-btn
                    class="title"
                    fab
                    dark
                    small
                    color="red"
                  >
                   {{ notification.count }}
                  </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{item.text}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                v-if="index + 1 < posts.length" :key="index"
              ></v-divider>
           </template>
        </v-list>
    </v-card>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            posts: [
                {icon: 'notifications', text: 'Pending Proses', description: 'Pending Proses', url:'/employee/pending'},
                {icon: 'fingerprint', text: 'Absensi', description: 'Data Absensi', url:'/employee/absence'},
                {icon: 'event', text: 'Cuti dan Ijin Sakit', description: 'Cuti dan Ijin Sakit', url:'/employee/leave'},
                {icon: 'timer', text: 'Lembur', description: 'Lembur', url:'/employee/overtime'},
                {icon: 'account_tree', text: 'Struktur Organisasi', description: 'Struktur Organisasi', url:'/employee/organization'},
                {icon: 'group', text: 'Karyawan', description: 'Data Karyawan', url:'/employee/list'},
                {icon: 'monitor_heart', text: 'Kesehatan', description: 'Reimburse Kesehatan', url:'/employee/medical'},
                {icon: 'family_restroom', text: 'Keluarga', description: 'Data Keluarga', url:'/employee/family'},
                {icon: 'directions_car', text: 'Transportasi', description: 'Reimburse Transportasi', url:'/employee/transportation'},
                {icon: 'redeem', text: 'Entertainment', description: 'Entertainment', url:'/employee/entertainment'},
            ],
            snackbar: {
              dialog: false,
              color: "primary",
              text: "",
              timeout: 3000,
            },
            notification: {
              count: 0
            }
        }),
        created() {
          this.Pending()
        },
        methods: {
            GoTo (url) {
               this.$router.push(url)
            },
            Pending () {
              let formdata   = {
                  flag: 'mobileapp'
              }
              let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiCountPendingEmployee'),param,{
              headers: {
                  'Content-Type': 'text/plain; charset=ISO-8859-1'
              }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback === 'Y') {
                        this.notification.count = feedback[0].pending_count
                      } else {
                          this.SnackBar(true,'error',feedback[0].feedback,3000)
                      }
                  }
              })
              .catch(e => {
                  this.SnackBar(true,'error',e,3000)
              })
          },
          SnackBar(dialog, color, text, timeout) {
            this.snackbar = {
                dialog: dialog,
                color: color,
                text: text,
                timeout: timeout,
            }
            this.confirm.dialog 	= false
            this.confirm.ok 		="Ok"
          },
        }
    }
</script>