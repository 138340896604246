<template>
    <div>
    <app-toolbarcustom app_title="Keluarga" app_menu="employee"></app-toolbarcustom>
    <v-card flat>
        <v-list two-line>
            <v-subheader></v-subheader>
            <template v-for="(item, index) in posts">
                <v-list-item
                  :key="item.icon"
                  @click="GoTo(item.url)"
                >
                  <v-list-item-avatar>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{item.text}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                v-if="index + 1 < posts.length"
                :key="index"
              ></v-divider>
           </template>
        </v-list>
    </v-card>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            posts: [
                {icon: 'assignment', text: 'Form', description: 'Form', url:'/employee/family/form'},
                {icon: 'list', text: 'List', description: 'List', url:'/employee/family/list'},
            ]
        }),
        methods: {
            GoTo (url) {
               this.$router.push(url)
            }
        }
    }
</script>